import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store';

/** ... */
export type ViewName = 'evaluation' | 'results' | 'completed' | 'error';

/** ... */
export type ViewState = ViewName | null;

/**
 * ...
 */
export const viewSlice = createSlice({
  name: 'view',
  initialState: null as ViewState,
  reducers: {
    /** ... */
    setView: (_, { payload }: PayloadAction<ViewName | null>) => {
      return payload;
    },
  },
});

// region Actions

/**
 * Set current view.
 *
 * @param name Name of the view.
 */
export function setView(name: ViewName | null) {
  return viewSlice.actions.setView(name);
}

// endregion Actions

// region Selectors

/** Select current view. */
export const selectCurrentView = (state: RootState) => state.view;

// endregion Selectors
