import { Evaluation } from '@features/evaluation/evaluationTypes';

export class EvaluationConditionValidator {
  private passedChecks = 0;

  constructor(private readonly evaluation: Evaluation) {}

  /**
   * ...
   */
  static exec(
    evaluation: Evaluation,
    conditions: DataGatherer.Condition[],
    modifier?: string | null
  ) {
    const instance = new EvaluationConditionValidator(evaluation);
    const pass = instance.performValidation(conditions);

    if (!modifier) return pass;

    const [modType, modValue] = modifier.split(':');

    if (modType === 'atLeast') {
      return instance.passedChecks >= parseInt(modValue);
    }

    if (modType === 'lessThan') {
      return instance.passedChecks < parseInt(modValue);
    }

    throw new Error(`Invalid value for condition modifier ${modifier}`);
  }

  /**
   * Helper function to take in array of conditions and evaluate as a whole.
   *
   * @param conditions ...
   * @param operator ...
   * @return ...
   */
  private performValidation(
    conditions: DataGatherer.Condition[],
    operator?: DataGatherer.Condition.Operator | null
  ): boolean {
    const results: boolean[] = [];

    let validationFailed = true;

    for (const condition of conditions) {
      // If the current condition has sub-condittions, determine how to process
      // them based upon whether ot not the condition also has a modifier.

      if ('conditions' in condition) {
        if ('conditionModifier' in condition === false) {
          validationFailed = !this.performValidation(
            condition.conditions ?? [],
            condition.operator
          );
        } else if (!this.performSubValidation(condition)) {
          validationFailed = true;
        }

        // The current condition's validation is contigent upon its
        // sub-conditions, do skip to the next condition.
        continue;
      }

      // Peform a validation given the current condition.
      const result = performConditionValidation(this.evaluation, condition);

      // If the result was positive, increment the number of passed checks.
      if (result) this.passedChecks++;

      //
      if (!operator && !result) {
        validationFailed = true;
      } else {
        results.push(result);
      }
    }

    if (operator === '&&' && results.includes(false)) return false;

    if (operator === '||' && !results.includes(true)) return false;

    if (results.includes(true) && this.passedChecks === conditions.length) {
      validationFailed = false;
    }

    return validationFailed ? false : true;
  }

  /**
   * ...
   *
   * @param conditions ...
   * @return ...
   */
  private performSubValidation(condition: DataGatherer.Condition) {
    return EvaluationConditionValidator.exec(
      this.evaluation,
      condition.conditions ?? []
    );
  }
}

/**
 * Helper function to evaluation a single condition object.
 *
 * @param evaluation ...
 * @param condition ...
 */
function performConditionValidation(
  evaluation: Evaluation,
  condition: DataGatherer.Condition
) {
  const { variable, operator, comparator } = condition;

  if (!variable) {
    throw new Error('');
  }

  if (!comparator) {
    throw new Error('');
  }

  const value = evaluation.models[variable.split('>')[1]]?.value;

  const test =
    typeof value === 'number' && typeof comparator === 'string'
      ? parseInt(comparator)
      : comparator;

  if (operator === '=') return value === test;
  if (operator === '!=') return value !== test;

  if (typeof value !== 'number' || typeof test !== 'number') return false;

  if (operator === '>') return value > test;
  if (operator === '<') return value < test;
  if (operator === '>=') return value >= test;
  if (operator === '<=') return value <= test;

  return false;
}

/**
 * Run a validation on an active assessment based on the provided conditions.
 *
 * @param assessment The assessment instance.
 * @param conditions List of consition(s) to use for validation.
 * @param modifier An optional string expression of the format "type:value"
 * that can curtail the final result of the validation.
 * @return ...
 */
export const checkConditions = EvaluationConditionValidator.exec;
