import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faCheck,
  faCircleNotch,
} from '@fortawesome/pro-solid-svg-icons';

import { delay } from '@tools/delay';
import { isNullish } from '@tools/type-gaurds';

import { JsonView } from './JsonView';

import './ResultsPanel.scoped.scss';

/** ... */
export const ResultsPanel: React.FC<ResultsPanel.Props> = ({
  title,
  ...props
}) => {
  return (
    <div className="result-panel">
      <div className="result-panel-header">{title}</div>
      <div className="result-panel-body">
        <PanelBodyContent {...props} />
      </div>
    </div>
  );
};

export namespace ResultsPanel {
  /** ... */
  export interface Props {
    title: string;
    value: unknown;
    placeholder?: string;
    loading?: boolean;
  }
}

/** ... */
const PanelBodyContent: React.FC<PanelBodyContent.Props> = ({
  value,
  placeholder,
  loading,
}) => {
  // ...
  const [canCopy, setCanCopy] = useState(true);
  // ...
  const [isCopying, setIsCopying] = useState(false);
  // ...
  const [copySuccess, setCopySuccess] = useState(false);

  // ...
  if (loading) return <Spinner animation="border" />;

  // ...
  if (isNullish(value)) return <p>{placeholder ?? 'No content to display.'}</p>;

  // ...
  const source = JSON.stringify(value, null, 2);

  // ...
  const copyToClipboard = async () => {
    if (!canCopy) return;

    // ...
    setCanCopy(false);

    // ...
    const promise = navigator.clipboard
      .writeText(source)
      .then(() => true)
      .catch(() => false);

    setIsCopying(true);

    const success = await Promise.race([promise, delay(2000)]);

    setIsCopying(false);

    if (!success) return;

    setCopySuccess(true);

    await delay(2000);

    setCopySuccess(false);
  };

  const classNames = ['copy-btn'];

  if (copySuccess) classNames.push('success');

  return (
    <>
      <Button
        className={classNames.join(' ')}
        title="Copy"
        onClick={copyToClipboard}
        style={{ pointerEvents: canCopy ? 'all' : 'none' }}
      >
        <CSSTransition
          in={isCopying || copySuccess}
          timeout={copySuccess ? 0 : 200}
          unmountOnExit
        >
          <FontAwesomeIcon icon={isCopying ? faCircleNotch : faCheck} />
        </CSSTransition>
        <CSSTransition
          in={!isCopying && !copySuccess}
          timeout={copySuccess ? 200 : 0}
          unmountOnExit
          onEntered={() => setTimeout(() => setCanCopy(true), 500)}
        >
          <FontAwesomeIcon icon={faClone} />
        </CSSTransition>
      </Button>

      <div className="body-content">
        <JsonView source={source}></JsonView>
      </div>
    </>
  );
};

namespace PanelBodyContent {
  /** ... */
  export interface Props {
    value: unknown;
    placeholder?: string;
    loading?: boolean;
  }
}
