import React from 'react';
import { Modal } from 'react-bootstrap';

/**
 * ...
 */
export const TestModal: React.FC = () => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Blank Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>A blank modal.</p>
      </Modal.Body>
    </>
  );
};
