import { observeElement } from '@src/observe-element';
import { EventManager } from '@src/event-manager';
import { isObject, isString } from '@tools/type-gaurds';

import { HostedPage } from './hosted-page';

declare global {
  interface Window {
    dataGatherer: DataGatherer;
  }
}

/**
 * ...
 */
interface SendHostedPageRequestOptions {
  requestType: string;
  requestData?: unknown;
  responseSuccessType: string;
  responseErrorType: string;
}

/** Configuration options for initializing the Data Gatherer applet. */
export type Configuration = DataGatherer.Config;
/** ... */
export type Output = DataGatherer.Output;

export * from '@src/events';

/**
 * ...
 */
class DataGathererSDK extends EventManager {
  /** ... */
  #hostedPage: HostedPage | null = null;

  /** ... */
  get mounted() {
    return !!this.#hostedPage;
  }

  constructor() {
    super();

    document.addEventListener('load', () => {
      observeElement(document.body, this.#onDocumentBodyChanged);
    });
  }

  /**
   * ...
   *
   * @return ...
   */
  static createInstance() {
    if (window.dataGatherer) {
      // If the data-gatherer has already been initalized in some way, and this is
      // a production enviorment, throw an error.
      if (!IS_DEV) {
        throw new Error(
          'an instance of the data-gatherer has already been initialized.'
        );
      }

      try {
        window.dataGatherer.teardown();
      } catch {
        // ...
      }
    }

    // ...
    const instance = new DataGathererSDK();

    // ...
    const create = instance.create.bind(instance);
    const teardown = instance.teardown.bind(instance);
    const on = instance.on.bind(instance);
    const once = instance.once.bind(instance);
    const removeAllListeners = instance.removeAllListeners.bind(instance);

    // ...
    window.dataGatherer = Object.defineProperties(
      {},
      {
        mounted: { enumerable: true, get: () => instance.mounted },
        create: { enumerable: true, value: create },
        teardown: { enumerable: true, value: teardown },
        on: { enumerable: true, value: on },
        once: { enumerable: true, value: once },
        removeAllListeners: { enumerable: true, value: removeAllListeners },
      }
    ) as DataGatherer;

    return window.dataGatherer;
  }

  /**
   * ...
   *
   * @param config ...
   * @return ...
   */
  async create(options: DataGatherer.Config) {
    console.log(options);

    // ...
    if (this.#hostedPage) {
      // eslint-disable-next-line no-console
      return console.warn(
        'the data-gatherer applet has already been activated. Call "teardown" to deactivate the data-gatherer before trying to reactivate it.'
      );
    }

    if (!options ?? typeof options !== 'object') {
      throw new Error('invalid options for "activate" were passed.');
    }

    window.addEventListener('message', this.#onMessage);

    // ...
    this.#hostedPage = await HostedPage.spawn(options.node);

    // ...
    await this.#sendHostedPageRequest({
      requestType: 'activateDataGatherer',
      requestData: options,
      responseSuccessType: 'dataGathererActivationCompleted',
      responseErrorType: 'dataGathererActivationError',
    });
  }

  /**
   * ...
   */
  teardown() {
    // ...
    if (!this.#hostedPage) {
      // eslint-disable-next-line no-console
      return console.warn(
        'the data-gatherer applet is not currently active. Call "dataGather.activate" before attempting to deactivate.'
      );
    }

    window.removeEventListener('message', this.#onMessage);

    // ...
    this.#hostedPage.remove();
    this.#hostedPage = null;
  }

  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  async #sendHostedPageRequest(options: SendHostedPageRequestOptions) {
    return await new Promise<unknown>((resolve, reject) => {
      if (!this.#hostedPage) {
        throw new Error(
          '[data-gatherer] a host page client has not been created.'
        );
      }

      const onMessage = ({ data }: MessageEvent<unknown>) => {
        // ...
        if (!isHostedPageEventMessage(data)) return;

        if (data.type === options.responseSuccessType) {
          resolve(data.data);
        } else if (data.type === options.responseErrorType) {
          reject(data.data);
        } else {
          return;
        }

        window.removeEventListener('message', onMessage);
      };

      window.addEventListener('message', onMessage);

      this.#hostedPage.sendMessage(options.requestType, options.requestData);
    });
  }

  /**
   * ...
   */
  #onMessage = ({ data }: MessageEvent<unknown>) => {
    // console.log('sdk - message', data);

    if (isActivationCompletedEventMessage(data)) {
      this.processEventListeners('activationComplete');
    } else if (isActivationErrorEventMessage(data)) {
      this.processEventListeners('activationError', data.data);
    } else if (isEvaluationDraftExportedEventMessage(data)) {
      this.processEventListeners('evaluationDraftExported', data.data);
    } else if (isEvaluationCompletedEventMessage(data)) {
      this.processEventListeners('evaluationCompleted', data.data);
    }
  };

  /**
   * ...
   */
  #onDocumentBodyChanged = () => {
    if (
      this.#hostedPage &&
      !document.getElementById(this.#hostedPage.instanceId)
    ) {
      this.teardown();
    }
  };
}

/** ... */
export type DataGatherer = {
  [P in keyof DataGathererSDK]: DataGathererSDK[P];
};

/** Data Gather instance. */
export const dataGatherer = DataGathererSDK.createInstance();

export default dataGatherer;

// region Helper Functions

/**
 * ...
 */
interface HostedPageEventMessage {
  type: string;
  data?: unknown;
}

/**
 * ...
 */
interface ActivationCompletedEventMessage extends HostedPageEventMessage {
  type: 'dataGathererActivationCompleted';
}

/**
 * ...
 */
interface ActivationErrorEventMessage extends HostedPageEventMessage {
  type: 'dataGathererActivationError';
  data: Error;
}

/**
 * ...
 */
interface EvaluationCompletedEventMessage extends HostedPageEventMessage {
  type: 'dataGathererEvaluationComplete';
  data: DataGatherer.Output;
}

/**
 * ...
 */
interface EvaluationDraftExportedEventMessage extends HostedPageEventMessage {
  type: 'dataGathererEvaluationDraftExported';
  data: DataGatherer.Output;
}

// /**
//  * ...
//  */
// interface DeactivationCompletedEventMessage extends HostedPageEventMessage {
//   type: 'dataGathererDeactivationCompleted';
// }

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isHostedPageEventMessage(
  value: unknown
): value is HostedPageEventMessage {
  return isObject(value) && isString(value.type);
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isActivationCompletedEventMessage(
  value: unknown
): value is ActivationCompletedEventMessage {
  return (
    isHostedPageEventMessage(value) &&
    value.type === 'dataGathererActivationCompleted'
  );
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isActivationErrorEventMessage(
  value: unknown
): value is ActivationErrorEventMessage {
  return (
    isHostedPageEventMessage(value) &&
    value.type === 'dataGathererActivationError'
  );
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isEvaluationDraftExportedEventMessage(
  value: unknown
): value is EvaluationDraftExportedEventMessage {
  return (
    isHostedPageEventMessage(value) &&
    value.type === 'dataGathererEvaluationDraftExported'
  );
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isEvaluationCompletedEventMessage(
  value: unknown
): value is EvaluationCompletedEventMessage {
  return (
    isHostedPageEventMessage(value) &&
    value.type === 'dataGathererEvaluationComplete'
  );
}

// /**
//  * ...
//  *
//  * @param value ...
//  * @return ...
//  */
// function isDeactivationCompletedEventMessage(
//   value: unknown
// ): value is DeactivationCompletedEventMessage {
//   return (
//     isHostedPageEventMessage(value) &&
//     value.type === 'dataGathererDeactivationCompleted'
//   );
// }

// endregion Helper Functions
