import React from 'react';
import { Form } from 'react-bootstrap';

import { Evaluation } from './evaluationTypes';

import { DateInput } from './DateInput';

/**
 * ...
 */
export const TextInput: React.FC<TextInput.Props> = ({
  onUpdated,
  ...props
}) => (
  <Form.Control {...props} onChange={({ target }) => onUpdated(target.value)} />
);

export namespace TextInput {
  /** ... */
  export interface Props {
    value: string | number;
    isValid?: boolean;
    isInvalid?: boolean;
    placeholder?: string;
    onUpdated: (value: string) => void;
  }
}

/**
 * ...
 */
export const CheckInput: React.FC<CheckInput.Props> = ({
  answers,
  value,
  onUpdated,
}) => {
  const checkboxs = (answers ?? []).map((a, i) => (
    <div className="py-2" key={i}>
      <Form.Check
        className={value === a.value ? 'checked' : ''}
        inline
        value={a.value}
        checked={value === a.value}
        label={a.label}
        type="radio"
        onChange={() => onUpdated(a.value)}
      />
    </div>
  ));

  return <>{checkboxs}</>;
};

export namespace CheckInput {
  /** ... */
  export interface Props {
    value: string | number;
    answers: Evaluation.Field['answers'];
    onUpdated: (value: string | number) => void;
  }
}

/**
 * ...
 */
export const EvaluationInput = {
  Text: TextInput,
  Check: CheckInput,
  Date: DateInput,
};
