import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { setActionState } from '@features/activity/activitySlice';
import { createAlert } from '@features/alerts/alertsSlice';
import { setError, clearError } from '@features/error/errorSlice';
import { checkConditions } from '@utils/check-conditions';
import { RootState, AppThunk } from '@store';
import { delay } from '@tools/delay';
import { ensureError } from '@tools/ensure-error';
import { isObject } from '@tools/type-gaurds';
import { buildOutput } from '@utils/evaluation-request';
import { createLogger } from '@utils/logger';

import * as api from './evaluationApi';
import { EvaluationBuilder } from './evaluationBuilder';
import { Evaluation } from './evaluationTypes';

/** ... */
const DEFAULT_API_VERSION = '2020-04';
/** ... */
const logger = createLogger('evaluation-slice');

/**
 * ...
 */
export type EvaluationState = Evaluation | null;

/** ... */
type SetAllInputValuesPayloadAction = PayloadAction<Evaluation['models']>;
/** ... */
type SetInputValuesPayloadAction = PayloadAction<InputValueItem[]>;
/** ... */
type SetInputValuePayloadAction = PayloadAction<InputValueItem>;

/**
 * ...
 */
export interface SetupEvaluationOptions {
  apiVersion: string;
  subscriptionKey: string;
  toolMeasureId: string;
}

/**
 * ...
 */
export interface InputValueItem {
  fieldId: string;
  value: string | number | null;
}

/**
 * ...
 */
class EvaluationSliceError extends Error {
  constructor(reason: string);
  constructor(tags: string[], reason: string);
  constructor(...args: [string] | [string[], string]) {
    let tags: string[];
    let reason: string;

    if (Array.isArray(args[0])) {
      tags = args[0];
      reason = args[1] as string;
    } else {
      tags = [];
      reason = args[0];
    }

    // ...
    const tagsLabel = ['evaluation-slice', ...tags].join(':');

    super(`[${tagsLabel}] ${reason}`);
  }
}

/**
 * ...
 */
class EvaluationNotInitializedError extends EvaluationSliceError {
  constructor(tags?: string[]) {
    super(
      tags ?? [],
      'tried to reference evaluation data before initializing it.'
    );
  }
}

/** ... */
export const setupEvaluation = createAsyncThunk<
  DataGatherer.Tool,
  void,
  { state: RootState }
>('evaluation/fetchToolData', async (_, thunkApi) => {
  const { config } = thunkApi.getState();

  if (!config) {
    return thunkApi.rejectWithValue({
      message: 'Data Gatherer has not been configured.',
    });
  }

  let data: DataGatherer.Tool | null = null;
  let error: Error | null = null;

  const options: api.GetToolDataOptions = {
    apiVersion: config.apiVersion ?? DEFAULT_API_VERSION,
    subscriptionKey: config.janusCredentials.subscriptionKey,
    toolMeasureId: config.toolMeasureId,
  };

  thunkApi.dispatch(setActionState('initializing', true));

  try {
    [data] = await Promise.all([api.getToolData(options), delay(1000)]);
  } catch (err) {
    error = ensureError(err);
  }

  await delay(1000);

  thunkApi.dispatch(setActionState('initializing', false));

  if (error || !data) {
    // ...
    console.error(error);

    // ...
    thunkApi.dispatch(
      setError(
        'Something went wrong.',
        'The data gatherer was unable to retrieve the required information from the server.'
      )
    );

    return thunkApi.rejectWithValue({ ...error });
  }

  // ...
  thunkApi.dispatch(clearError());

  return data;
});

/** ... */
export const buildEvaluationDraft = createAsyncThunk<
  DataGatherer.Output,
  void,
  { state: RootState }
>('evaluation/buildDraft', async (_, thunkApi) => {
  const { evaluation } = thunkApi.getState();

  if (!evaluation) {
    return thunkApi.rejectWithValue('An evaluation has not been initialized.');
  }

  // ...
  const options = {
    measureId: evaluation.tool.measureId,
    evaluatorId: evaluation.tool.evaluatorId,
    retentionPolicy: 'DEFAULT' as const,
    directives: {},
    forms: evaluation.forms,
    fields: evaluation.fields,
    models: evaluation.models,
  };

  let output: DataGatherer.Output | null = null;
  let error: Error | null = null;

  try {
    output = buildOutput(options);
  } catch (err) {
    console.error(err);
    error = ensureError(err);
  }

  thunkApi.dispatch(setActionState('processing', true));

  // Slight pause to help communicate that the evaluation is being processed.
  await delay(1000);

  thunkApi.dispatch(setActionState('processing', false));

  if (error) {
    logger.error(error.message);

    return thunkApi.rejectWithValue(
      `There was an issue while processing the evaluation: ${error.message}`
    );
  }

  return output as DataGatherer.Output;
});

/** ... */
export const buildEvaluationOutput = createAsyncThunk<
  DataGatherer.Output,
  void,
  { state: RootState }
>('evaluation/buildOutput', async (_, thunkApi) => {
  const { evaluation } = thunkApi.getState();

  if (!evaluation) {
    return thunkApi.rejectWithValue('An evaluation has not been initialized.');
  }

  // ...
  const options = {
    measureId: evaluation.tool.measureId,
    evaluatorId: evaluation.tool.evaluatorId,
    retentionPolicy: 'DEFAULT' as const,
    directives: {},
    forms: evaluation.forms,
    fields: evaluation.fields,
    models: evaluation.models,
  };

  let output: DataGatherer.Output | null = null;
  let error: Error | null = null;

  try {
    output = buildOutput(options);
  } catch (err) {
    error = ensureError(err);
  }

  thunkApi.dispatch(setActionState('processing', true));

  // Slight pause to help communicate that the evaluation is being processed.
  await delay(1000);

  thunkApi.dispatch(setActionState('processing', false));

  if (error) {
    logger.error(error.message);

    return thunkApi.rejectWithValue(
      `There was an issue while processing the evaluation: ${error.message}`
    );
  }

  return output as DataGatherer.Output;
});

/**
 * ...
 */
export const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState: null as EvaluationState,
  reducers: {
    /** ... */
    setAllInputValues: (state, { payload }: SetAllInputValuesPayloadAction) => {
      if (!state) {
        throw new EvaluationNotInitializedError(['setAllInputValues']);
      }

      // ...
      const models: Record<string, Evaluation.Model> = {};

      for (const key in state.models) {
        const model = payload[key];

        // Perhaps this is a little redundant, but we want to be extra-sure
        // a valid set of evaluation data is going to be created.
        if (!isObject(model)) {
          throw new EvaluationSliceError(
            ['setAllInputValues'],
            'failed to set evaluation inputs; the provided data was malformed.'
          );
        }

        models[key] = {
          value: model.value ?? null,
          isDirty: model.isDirty ?? false,
        };
      }

      state.models = models;
    },
    /** ... */
    setInputValues: (state, { payload }: SetInputValuesPayloadAction) => {
      if (!state) {
        throw new EvaluationNotInitializedError(['setInputValue']);
      }

      // ...
      const models: Record<string, Evaluation.Model> = { ...state.models };

      for (const { fieldId, value } of payload) {
        if (fieldId in models === false) {
          throw new EvaluationSliceError(['setInputValues'], '...');
        }

        models[fieldId].value = value;
      }

      state.models = models;
    },
    /** Set the value of an evaluation input (question). */
    setInputValue: (state, { payload }: SetInputValuePayloadAction) => {
      if (!state) {
        throw new EvaluationNotInitializedError(['setInputValue']);
      }

      const { fieldId, value } = payload;

      if (fieldId in state.models === false) {
        throw new EvaluationSliceError(['setInputValue'], '...');
      }

      state.models[fieldId].value = value;
    },
    /** ... */
    resetInputValues: (state) => {
      if (!state) {
        throw new EvaluationNotInitializedError(['resetInputValues']);
      }

      for (const key in state.models) {
        state.models[key] = { value: null, isDirty: false };
      }
    },
    /** ... */
    clearOutput: (state) => {
      if (!state) {
        throw new EvaluationNotInitializedError(['clearOutput']);
      }

      state.output = null;
    },
    /** ... */
    clearEvaluation: () => {
      return null;
    },
    /** ... */
    fillInputValues: (state) => {
      if (!state) {
        throw new EvaluationNotInitializedError(['fillInputValues']);
      }

      // ...
      const models: Evaluation['models'] = {};

      for (const field of Object.values(state.fields)) {
        if (field.type === 'heading') continue;

        // ...
        const fields = [field].concat(Object.values(field.subFields ?? []));

        for (const { id, type, answers } of fields) {
          // ...
          const value =
            type === 'text'
              ? 'Text.'
              : type === 'number'
              ? 1
              : type === 'date'
              ? new Date().toISOString()
              : type === 'dropdown' && answers
              ? answers[0].value
              : null;

          models[id] = { value, isDirty: true };
        }
      }

      state.models = models;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setupEvaluation.fulfilled, (_, { payload }) => {
      return { ...createEvaluation(payload) };
    });

    builder.addCase(buildEvaluationOutput.fulfilled, (state, { payload }) => {
      if (state) state.output = payload;
    });

    builder.addCase(buildEvaluationOutput.rejected, (state) => {
      if (state) state.output = null;
    });
  },
});

// region Actions

/**
 * Set all evaluation input values.
 *
 * @param data Evaluation input data.
 */
export function setAllInputValues(data: Evaluation['models']) {
  return evaluationSlice.actions.setAllInputValues(data);
}

/**
 * Set evaluation input values.
 *
 * @param dataItems Evaluation input data.
 */
export function setInputValues(dataItems: InputValueItem[]) {
  return evaluationSlice.actions.setInputValues(dataItems);
}

/**
 * Set the value of an evaluation input (question).
 *
 * @param fieldId ID of the field who's value is to be set.
 * @param value The value to set.
 */
export function setInputValue(fieldId: string, value: string | number | null) {
  return evaluationSlice.actions.setInputValue({ fieldId, value });
}

/**
 * Set the value of an evaluation input (question).
 *
 * @param fieldId ID of the field who's value is to be set.
 * @param value The value to set.
 */
export function updateField(
  fieldId: string,
  value: string | number | null
): AppThunk {
  return (dispatch, getState) => {
    const evaluation = getState().evaluation;

    if (!evaluation) {
      throw new Error('');
    }

    const field = getField(evaluation.fields, fieldId);

    if (field.type === 'heading') {
      throw new Error('');
    }

    // ...
    dispatch(setInputValue(fieldId, value));

    // evaluation = getState().evaluation;

    // if (!evaluation) {
    //   throw new Error('');
    // }

    if (!field.answers) return;

    // If the field has an `answers` array, execute the relevant operations.

    const answer = field.answers.find((a) => a.value === value);

    // Process any auto answers.
    for (const autoAnswer of answer?.autoAnswer ?? []) {
      let showMessage = false;

      // check conditions
      let conditionCheckPassed = false;
      if (autoAnswer.conditions?.length) {
        conditionCheckPassed = autoAnswer.conditionModifier
          ? checkConditions(
              evaluation,
              autoAnswer.conditions,
              autoAnswer.conditionModifier
            )
          : checkConditions(evaluation, autoAnswer.conditions);

        if (!conditionCheckPassed) continue;
      }

      const model = getModel(evaluation.models, fieldId);

      for (const a of autoAnswer.answer) {
        // Get values from answer "path".
        const [, fieldId, parsedValue] = a.split('>');
        // Reference the associated evaluation form model.

        // If the model's value is already equal to the auto answer's value,
        // don't do anything.
        const targetQuestionModel = getModel(evaluation.models, fieldId);
        if (targetQuestionModel.value === parsedValue) continue;

        // Set the model's value to that dictated by the autoAnswer's value.
        dispatch(setInputValue(fieldId, parsedValue));

        // Since a value was changed, ensure the autoAnswer's message will be
        // displayed.
        showMessage = true;
      }

      // If the autoAnswer has a message and `showMessage` is `true`, display
      // the message via an alert.
      if (autoAnswer.message && showMessage) {
        dispatch(createAlert({ type: 'success', text: autoAnswer.message }));
      }
    }

    // Process any warning checks.
    for (const warningCheck of answer?.warningCheck ?? []) {
      const showWarning = warningCheck.conditionModifier
        ? checkConditions(
            evaluation,
            warningCheck.conditions,
            warningCheck.conditionModifier
          )
        : checkConditions(evaluation, warningCheck.conditions);

      // If the warning check has a message and `showWarning` is `true`, display
      // the warning via an alert.
      if (warningCheck.warning && showWarning) {
        dispatch(createAlert({ type: 'info', text: warningCheck.warning }));
      }
    }
  };
}

/**
 * ...
 */
export function resetInputValues() {
  return evaluationSlice.actions.resetInputValues();
}

/**
 * ...
 */
export function clearOutput() {
  return evaluationSlice.actions.clearOutput();
}

/**
 * Clear all evaluation data.
 */
export function clearEvaluation() {
  return evaluationSlice.actions.clearEvaluation();
}

/**
 * Fill all evaluation inputs with values. This is intended for development
 * and testing purposes only.
 */
export function fillInputValues() {
  return evaluationSlice.actions.fillInputValues();
}

// endregion Actions

// region Selectors

/**
 * ...
 *
 * @param cb ...
 * @return ...
 */
function makeStateSelector<P>(cb: (state: Evaluation) => P) {
  return ({ evaluation }: RootState) => {
    if (evaluation) return cb(evaluation);

    // ...
    throw new EvaluationSliceError(['selectReferences'], '...');
  };
}

/** ... */
const selectItemId = (_state: RootState, itemId: string) => itemId;

/** ... */
export const selectEvaluation = ({ evaluation }: RootState) => evaluation;
/** ... */
// export const selectTool = makeStateSelector((state) => state.tool);
export const selectTool = ({ evaluation }: RootState) =>
  evaluation?.tool ?? null;
/** ... */
export const selectReferences = makeStateSelector((state) => state.references);
/** ... */
export const selectForms = makeStateSelector((state) => state.forms);
/** ... */
export const selectFields = makeStateSelector((state) => state.fields);
/** ... */
export const selectModels = makeStateSelector((state) => state.models);
/** ... */
export const selectOutput = makeStateSelector((state) => state.output);
/** ... */
export const selectMeasureId = makeStateSelector(({ tool }) => tool.measureId);

/**
 * ...
 */
export const makeSelectForm = () => {
  return createSelector(selectForms, selectItemId, (forms, formId) => {
    return getForm(forms, formId);
  });
};

/**
 * ...
 */
export const makeSelectModel = () => {
  return createSelector(selectModels, selectItemId, (models, modelId) => {
    return getModel(models, modelId);
  });
};

/**
 * ...
 */
export const makeSelectReference = () => {
  return createSelector(
    selectReferences,
    (_state: RootState, referenceId: string | undefined) => referenceId,
    (references, id) => {
      return references && id ? references[id] : null;
    }
  );
};

// endregion Selectors

// region Helper Functions

/**
 * ...
 *
 * @param values ...
 * @return ...
 */
function createDataMap<T extends { id: string }>(values: T[]) {
  return Object.fromEntries(values.map((value) => [value.id, value]));
}

/**
 * Get an evaluation form by ID.
 *
 * @param forms Evaluation forms.
 * @param formId Target form ID.
 * @return The form.
 */
function getForm(forms: Evaluation['forms'], formId: string) {
  const form = forms[formId];

  if (!form) {
    throw new Error(`A form with the ID "${formId}" could not be found.`);
  }

  return form;
}

/**
 * Get an evaluation field by ID.
 *
 * @param fields Evaluation fields.
 * @param fieldId Target field ID.
 * @return The field.
 */
function getField(fields: Evaluation['fields'], fieldId: string) {
  const field = fields[fieldId];

  if (!field) {
    throw new Error(`A field with the ID "${fieldId}" could not be found.`);
  }

  return field;
}

/**
 * Get an evaluation field model by ID.
 *
 * @param models Evaluation field model.
 * @param modelId Target model ID.
 * @return The model.
 */
function getModel(models: Evaluation['models'], modelId: string) {
  const model = models[modelId];

  if (!model) {
    throw new Error(`A model with the ID "${modelId}" could not be found.`);
  }

  return model;
}

/**
 * Create a new instance of an evaluation state object from provided tool data.
 *
 * @param data Tool data.
 * @return An `Evaluation` state data object.
 */
function createEvaluation(data: DataGatherer.Tool) {
  return EvaluationBuilder.build(data);
}

// endregion Helper Functions
