import React from 'react';
import { Form } from 'react-bootstrap';
import { find } from 'lodash';

import { selectHiddenFieldIds } from '@features/config/configSlice';
import { useAppSelector } from '@hooks';

import { EvaluationQuestion } from './EvaluationQuestion';
import { selectFields, selectModels } from './evaluationSlice';
import { Evaluation } from './evaluationTypes';
import { SectionHeading } from './SectionHeading';

import './EvaluationForm.scoped.scss';

/**
 * `Form` component properties.
 */
interface EvaluationFormProps {
  form: Evaluation.Form;
  validations: Evaluation.Validations;
}

/**
 * ...
 */
export const EvaluationForm: React.FC<EvaluationFormProps> = ({
  form,
  validations,
}) => {
  // ...
  const fields = useAppSelector(selectFields);
  // ...
  const models = useAppSelector(selectModels);
  // ...
  const hiddenFieldIds = useAppSelector(selectHiddenFieldIds) ?? [];

  // ...
  const fieldGroups = form.fieldGroups.map((fg) => {
    // ...
    const filteredFields = fg.fields.filter(
      (fieldId) => !hiddenFieldIds.includes(fieldId)
    );

    // ...
    const questions = filteredFields.map((fieldId) => {
      // Possible form omit max validity logic...

      const field = find(fields, { id: fieldId });

      if (!field) {
        throw new Error(
          `[EvaluationForm] failed to generate question field -- a field corresponding to field ID "${fieldId}" could not be found.`
        );
      }

      if (field.type === 'heading') {
        return <SectionHeading key={field.id} label={field.label} />;
      }

      const model = models[fieldId];

      if (!model) {
        throw new Error(
          `[EvaluationForm] failed to generate question field -- a model corresponding to field ID "${fieldId}" could not be found.`
        );
      }

      const formVals = validations.forms[field.formId];
      const fieldVals = formVals.fields[field.id];

      const props = { field, formVals, fieldVals };

      return <EvaluationQuestion key={fieldId} {...props} />;
    });

    return (
      <fieldset key={fg.id}>
        {fg.heading && <SectionHeading label={fg.heading} />}
        {questions}
      </fieldset>
    );
  });

  return (
    <div className="evaluation-form">
      <Form>
        <div className="evaluation-questions">{fieldGroups}</div>
      </Form>
    </div>
  );
};
