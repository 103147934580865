import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store';

/**
 * ...
 */
export interface TestState {
  someVal: number;
  questions: Record<string, Question>;
}

export interface Question {
  value: boolean | null;
}

export interface SetQuestionOptions {
  id: string;
  value?: Question['value'];
}

export interface UpdateQuestionOptions {
  id: string;
  value: Question['value'];
}

const initialState: TestState = {
  someVal: 1,
  questions: {
    a: { value: true },
    b: { value: true },
    c: { value: true },
    d: { value: true },
    e: { value: true }
  }
};

/**
 * ...
 */
export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    /** ... */
    setSomeVal: (state, { payload }: PayloadAction<number>) => {
      state.someVal = payload;
    },
    /** ... */
    setQuestion: (state, { payload }: PayloadAction<SetQuestionOptions>) => {
      state.questions[payload.id] = { value: payload.value ?? null };
    },
    /** ... */
    setQuestionValue: (
      state,
      { payload }: PayloadAction<UpdateQuestionOptions>
    ) => {
      if (payload.id in state.questions === false) {
        throw new Error('');
      }

      state.questions[payload.id].value = payload.value;
    }
  }
});

// region Actions

/**
 * ...
 *
 * @param options ...
 */
export function setSomeVal(value: number) {
  return testSlice.actions.setSomeVal(value);
}

/**
 * ...
 *
 * @param options ...
 */
export function setQuestion(options: SetQuestionOptions) {
  return testSlice.actions.setQuestion(options);
}

/**
 * ...
 *
 * @param options ...
 */
export function updateQuestion(options: UpdateQuestionOptions) {
  return testSlice.actions.setQuestionValue(options);
}

// endregion Actions

// region Selectors

// ...
const selectQuestions = (state: RootState) => state.test.questions;
// ...
const selectQuestionId = (_: RootState, id: string) => id;

// ...
export const selectAreAllValuesValid = createSelector(
  [selectQuestions],
  (q) => {
    console.log('Running: 1');

    return Object.values(q).every(({ value }) => typeof value === 'boolean');
  }
);

// ...
export const selectIsOnlyOneQuestionFalse = createSelector(
  [selectQuestions],
  (q) => {
    console.log('Running: 2');

    const count = Object.values(q).filter(({ value }) => value === false)
      .length;

    console.log({ count }, count <= 1);

    return count <= 1;
  }
);

// ...
export const selectIsFormValid = createSelector(
  [selectAreAllValuesValid, selectIsOnlyOneQuestionFalse],
  (...args) => {
    console.log('Running: 4');

    return args.every((arg) => arg === true);
  }
);

// ...
export const selectQuestion = createSelector(
  [selectQuestions, selectQuestionId],
  (qs, id) => {
    console.log('Running: 3');

    return qs[id];
  }
);

// //
// const r1 = selectAreAllValuesValid(state);
// console.log(r1);
// //
// const r2 = selectAreAllValuesValid(state);
// console.log(r2);
//
// state.questions = { ...state.questions };
// state.questions.a.value = null;
//
// state.someVal = 2;
//
// //
// const r3 = selectAreAllValuesValid(state);
// console.log(r3);
//
// //
// const r4 = selectQuestion(state, 'a');
// console.log(r4);
// //
// const r5 = selectQuestion(state, 'a');
// console.log(r5);
// //
// const r6 = selectQuestion(state, 'b');
// console.log(r6);

// const secondResult = selectIsOnlyOneQuestionFalse(state);
// console.log(secondResult);

// const state = {
//   a: { first: 5 },
//   b: 10
// };
//
// const selectA = (state) => state.a;
// const selectB = (state) => state.b;
//
// const selectA1 = createSelector([selectA], (a) => a.first);
//
// const selectResult = createSelector([selectA1, selectB], (a1, b) => {
//   console.log('Output selector running');
//   return a1 + b;
// });
//
// const result = selectResult(state);
// // Log: "Output selector running"
// console.log(result);
// // 15
//
// const secondResult = selectResult(state);
// // No log output
// console.log(secondResult);
// 15

// endregion Selectors
