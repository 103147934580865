import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { devSubscriptionKey } from '@config/dev';
import { ensureError } from '@tools/ensure-error';

import { ResultsPanel } from './ResultsPanel';

import './ResultsSection.scoped.scss';

/** ... */
const REQUEST_URL = 'https://services.mhs.com/Janus/2020-04/Evaluate';
/** ... */
const REQUEST_HEADERS = { 'Ocp-Apim-Subscription-Key': devSubscriptionKey };

/**
 * ...
 */
export const ResultsSection: React.FC<ResultsSection.Props> = ({
  active,
  payload,
  onClose,
}) => {
  // ...
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<unknown>(null);

  /** ... */
  const fetchResults = async (payload: DataGatherer.Output) => {
    // ...
    const options = {
      method: 'PUT',
      headers: REQUEST_HEADERS,
      body: JSON.stringify(payload.evaluatePayload),
    };

    let results: unknown = null;
    let error: Error | null = null;

    setLoading(true);

    // ...
    try {
      results = await fetch(REQUEST_URL, options).then((res) => res.json());
    } catch (err) {
      error = ensureError(err);
    }

    setLoading(false);

    // ...
    if (error) {
      // return alert(
      //   `An evaluation payload was received from the data-gaterer, but the call to evaluate using it failed for the following reason: ${error.message}`
      // );

      return;
    }

    // ...
    console.log('Evaluation results:', results);

    setResults(results);
  };

  useEffect(() => {
    if (payload) void fetchResults(payload);
  }, [payload]);

  // ...
  const timeout = active ? 0 : 250;

  return (
    <CSSTransition in={active} timeout={timeout} unmountOnExit>
      <div className="results-section">
        <div className="results-section-wrapper">
          <Button className="close-btn" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>

          <CSSTransition in={active} timeout={timeout} appear>
            <div className="result-panels-container">
              <ResultsPanel
                title="Evaluation Payload"
                value={payload}
                placeholder="Evaluation payload generated by the Data Gatherer will be displayed here."
              />
              <ResultsPanel
                title="Evaluation Results"
                value={results}
                placeholder="No results to display."
                loading={loading}
              />
            </div>
          </CSSTransition>
        </div>
      </div>
    </CSSTransition>
  );
};

export namespace ResultsSection {
  /** ... */
  export interface Props {
    active: boolean;
    payload: Output | null;
    onClose: () => void;
  }
}
