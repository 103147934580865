import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store';

/**
 * App `theme` state.
 */
export interface ThemeState {
  primaryColor: string | null;
  secondaryColor: string | null;
  primaryTextColor: string | null;
  logoUrl: string | null;
}

/**
 * Set theme colors action options.
 */
export interface SetColorsOptions {
  primaryColor?: string;
  secondaryColor?: string;
  primaryTextColor?: string;
}

/**
 * Set theme logo action options.
 */
export interface SetLogoOptions {
  logoUrl: string;
}

/** ... */
const initialState: ThemeState = {
  primaryColor: null,
  secondaryColor: null,
  primaryTextColor: null,
  logoUrl: null,
};

/**
 * ...
 */
export const themeSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {
    setColors: (state, { payload }: PayloadAction<SetColorsOptions>) => {
      state.primaryColor = payload.primaryColor ?? null;
      state.secondaryColor = payload.secondaryColor ?? null;
      state.primaryTextColor = payload.primaryTextColor ?? null;
    },
    setLogo: (state, { payload }: PayloadAction<SetLogoOptions>) => {
      state.logoUrl = payload.logoUrl ?? null;
    },
    clear: (state) => {
      state.primaryColor = null;
      state.secondaryColor = null;
      state.primaryTextColor = null;
      state.logoUrl = null;
    },
  },
});

// region Actions

/**
 * Set theme colors.
 *
 * @param options Theme color options.
 */
export function setColors(options: SetColorsOptions) {
  return themeSlice.actions.setColors(options);
}

/**
 * Set theme logo.
 *
 * @param options Theme logo options.
 */
export function setLogo(options: SetLogoOptions) {
  return themeSlice.actions.setLogo(options);
}

/**
 * Clear all theme data.
 */
export function clear() {
  return themeSlice.actions.clear();
}

// endregion Actions

// region Selectors

/** ... */
export const selectTheme = (state: RootState) => state.theme;

// endregion Selectors
