import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

import { Overlay } from '@components/Overlay';
import { runSetup } from '@features/setup/setupSlice';
import { useAppSelector, useAppDispatch } from '@hooks';

import './ErrorView.scoped.scss';

/**
 * ...
 */
export const ErrorView: React.FC = () => {
  // ...
  const error = useAppSelector(({ error }) => error);
  // ...
  const dispatch = useAppDispatch();

  if (!error) return null;

  // ...
  const retry = () => dispatch(runSetup());

  return (
    <Overlay background="none">
      <div className="error-view-wrapper">
        <div className="h2 mb-5">
          <FontAwesomeIcon className="me-3" icon={faTimesCircle} />
          <span>{error.title}</span>
        </div>
        {/* ... */}
        <p className="mb-4">{error.message}</p>
        {/* ... */}
        <Button variant="secondary" onClick={retry}>
          Retry
        </Button>
      </div>
    </Overlay>
  );
};
