import React from 'react';

import { useAppSelector } from '@hooks';

import { CompletedView } from './CompletedView';
import { EvaluationView } from './EvaluationView';
import { ErrorView } from './ErrorView';

import './ViewContainer.scoped.scss';

/**
 * ...
 *
 * @param view ...
 * @return ...
 */
function getCurrentView(view: string | null) {
  if (view === 'evaluation') return <EvaluationView />;
  if (view === 'completed') return <CompletedView />;
  if (view === 'error') return <ErrorView />;

  return null;
}

/**
 * Container component that displays within it the current view.
 */
export const ViewContainer: React.FC = () => {
  // `ViewState` data.
  const view = useAppSelector(({ view }) => view);

  // Render the appropriate view component.
  const currentView = getCurrentView(view);

  return <div className="view-container">{currentView}</div>;
};
