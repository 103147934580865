import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './AlertPanel.scoped.scss';

/**
 * ...
 */
export const AlertPanel: React.FunctionComponent<AlertPanel.Props> = (
  props
) => {
  // ...
  const buttons = (
    [props.button, props.button1, props.button2].filter(
      (button) => button
    ) as AlertPanel.Button[]
  ).map(({ text, onClick }, i) => (
    <button key={i} onClick={onClick}>
      {text}
    </button>
  ));

  return (
    <CSSTransition appear in={props.active} timeout={300} unmountOnExit>
      <div className="alert-panel">
        <div className="alert-panel-inner">
          <div className="mb-2">{props.text}</div>
          <div className="alert-panel-buttons">{buttons}</div>
        </div>
      </div>
    </CSSTransition>
  );
};

namespace AlertPanel {
  /** ... */
  export interface Button {
    text: string;
    onClick: GenericFunction;
  }

  /** ... */
  export interface Props {
    active: boolean;
    text: string;
    button1?: Button;
    button2?: Button;
  }
}
