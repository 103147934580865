import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';

import { TestPage } from './pages/TestPage';
import { HostedTestPage } from './pages/HostedTestPage';
import { DataGathererPage } from './pages/DataGathererPage';

import './styles.scss';

/** ... */
const TAB_CONFIGS = [
  { key: 'native-view', text: 'Native', route: '/' },
  { key: 'hosted-view', text: 'Hosted', route: '/hosted-test' },
];

/** ... */
const node = document.getElementById('app');

if (node) {
  const tabs = TAB_CONFIGS.map(({ key, text, route }) => (
    <li key={key}>
      <NavLink
        to={route}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        {text}
      </NavLink>
    </li>
  ));

  ReactDOM.render(
    <Router>
      <nav>
        <ul>{tabs}</ul>
      </nav>

      <Routes>
        <Route path="/" element={<TestPage />} />
        <Route path="hosted-test" element={<HostedTestPage />} />
        <Route path="data-gatherer" element={<DataGathererPage />} />
      </Routes>
    </Router>,
    node
  );
}
