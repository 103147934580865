/** ... */
export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD';
/** ... */
export type RequestOptions = Omit<RequestInit, 'method' | 'body'>;

/** ... */
interface MakeRequestOptions {
  url: string;
  method: Method;
  data?: unknown;
  options?: RequestOptions;
}

/**
 * ...
 */
class Http {
  /**
   * ...
   *
   * @param url ...
   * @param options ...
   * @return ...
   */
  async get(url: string, options?: RequestOptions) {
    return await this.makeRequest({ method: 'GET', url, options });
  }

  /**
   * ...
   *
   * @param url ...
   * @param data ...
   * @param options ...
   * @return ...
   */
  async post(url: string, data: unknown, options?: RequestOptions) {
    return await this.makeRequest({ method: 'POST', url, data, options });
  }

  /**
   * ...
   *
   * @param url ...
   * @param data ...
   * @param options ...
   * @return ...
   */
  async put(url: string, data: unknown, options?: RequestOptions) {
    return await this.makeRequest({ method: 'PUT', url, data, options });
  }

  /**
   * ...
   *
   * @param url ...
   * @param options ...
   * @return ...
   */
  async del(url: string, options?: RequestOptions) {
    return await this.makeRequest({ method: 'DELETE', url, options });
  }

  /**
   * ...
   */
  private async makeRequest({
    url,
    method,
    data,
    options,
  }: MakeRequestOptions) {
    // ...
    const reqOptions: RequestInit = { method, ...(options ?? {}) };

    // ...
    if (data) {
      reqOptions.body = JSON.stringify(data);
    }

    // ...
    const res = await fetch(url, reqOptions);

    try {
      return (await res.json()) as Promise<unknown>;
    } catch {
      return undefined;
    }
  }
}

/** ... */
export const http = new Http();
