import { delay } from '@tools/delay';

import { data as LSIR } from './lsi-r';
import { data as LSIRSV } from './lsi-r-sv';
import { data as YLSCMISRV } from './ylscmi-srv';
import { data as LSCMI } from './lscmi';
import { data as YLSCMI } from './ylscmi';
import { data as SIMPLE_TOOL } from './simple-tool';
import { data as TEST_TOOL } from './test-tool';

/**
 * ...
 */
function copyData<T>(data: T) {
  return JSON.parse(JSON.stringify(data)) as T;
}

/**
 * ...
 */
export const DATA_KEYS = Object.fromEntries(
  [
    { label: 'LSI-R', data: LSIR },
    { label: 'LSI-R:SV', data: LSIRSV },
    { label: 'YLS/CMI:SRV', data: YLSCMISRV },
    { label: 'LS/CMI', data: LSCMI },
    { label: 'YLS/CMI 2.0', data: YLSCMI },
    { label: 'Test Tool', data: TEST_TOOL },
    { label: 'Simple Tool', data: SIMPLE_TOOL },
  ].map((item) => [item.data.measureId, item])
);

/**
 * ...
 *
 * @param toolMeasureId ...
 * @return ...
 */
export function getMockData(toolMeasureId: string) {
  // ...
  const item = DATA_KEYS[toolMeasureId];

  if (item) return copyData(item.data);

  throw new Error(
    `[getMockData] data type by the name of "${toolMeasureId}" does not exist.`
  );
}

/**
 * ...
 *
 * @param toolMeasureId ...
 * @param ms ...
 * @return ...
 */
getMockData.async = async (toolMeasureId: string, ms = 500) => {
  // ...
  await delay(ms);

  return getMockData(toolMeasureId);
};
