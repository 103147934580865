import React from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { find } from 'lodash';

import { selectCustomFormLabels } from '@features/config/configSlice';
import { useAppSelector } from '@hooks';

import { Evaluation } from './evaluationTypes';

import './EvaluationTab.scoped.scss';

/**
 * Form `Tab` component properties.
 */
interface Props {
  form: Evaluation.Form;
  validations: Evaluation.Validations;
}

/**
 * ...
 */
export const EvaluationTab: React.FC<Props> = ({ form, validations }) => {
  // ...
  const customLabels = useAppSelector(selectCustomFormLabels);

  const { invalid, dirty } = validations.forms[form.id];

  // ...
  const formLabel =
    find(customLabels ?? [], { formId: form.id })?.label ?? form.name;

  // ...
  const statusIcon = !dirty ? null : invalid ? (
    <FontAwesomeIcon icon={faExclamation} />
  ) : (
    <FontAwesomeIcon icon={faCheck} />
  );

  return (
    <Nav.Item className="evaluation-tab">
      <Nav.Link className="d-inline-flex align-items-center" eventKey={form.id}>
        <span className="evaluation-tab-validity-indicator">{statusIcon}</span>
        <span className="evaluation-tab-text">{formLabel}</span>
      </Nav.Link>
    </Nav.Item>
  );
};
