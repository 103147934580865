import React from 'react';
import { hot } from 'react-hot-loader/root';
import { dataGatherer } from '@mhs/data-gatherer';
import { isObject } from '@tools/type-gaurds';

import './DataGathererPage.scss';

/** `DataGathererPage` component properties interface. */
type Props = Record<string, never>;

const _DataGathererPage = class DataGathererPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.activateDataGatherer = this.activateDataGatherer.bind(this);
    this.deactivateDataGatherer = this.deactivateDataGatherer.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onEvaluationComplete = this.onEvaluationComplete.bind(this);

    document.body.style.background = 'transparent';
  }

  componentDidMount() {
    dataGatherer.on('activationCompleted', this.onActivationCompleted);
    dataGatherer.on('activationError', this.onActivationError);
    dataGatherer.on('evaluationCompleted', this.onEvaluationComplete);
    dataGatherer.on('evaluationDraftExported', this.onEvaluationDraftExported);

    window.onmessage = this.onMessage;
  }

  /**
   * ...
   */
  async activateDataGatherer(config: DataGatherer.Config) {
    await dataGatherer.activate(config);
  }

  /**
   * ...
   */
  async deactivateDataGatherer() {
    try {
      await dataGatherer.deactivate();
    } catch (err) {
      // ...
    }

    this.sendMessageToParentPage('dataGathererDeactivationCompleted');
  }

  /**
   * ...
   *
   * @param message ...
   * @param data ...
   */
  private sendMessageToParentPage(type: string, data?: unknown) {
    // console.log('hosted-page - sendMessageToParentPage', type);

    window.top?.postMessage({ type, data: data ?? null }, '*');
  }

  /**
   * ...
   */
  private onMessage = ({ data }: MessageEvent<unknown>) => {
    if (!isObject(data)) return;

    if (isActivationRequestEvent(data)) {
      void this.activateDataGatherer(data.data);
    } else if (isDeactivationRequestEvent(data)) {
      void this.deactivateDataGatherer();
    }
  };

  /**
   * ...
   */
  private onActivationCompleted = () => {
    this.sendMessageToParentPage('dataGathererActivationCompleted');
  };

  /**
   * ...
   */
  private onActivationError = (err: Error) => {
    this.sendMessageToParentPage('dataGathererActivationError', err);
  };

  /**
   * ...
   */
  private onEvaluationComplete = (payload: DataGatherer.Output) => {
    this.sendMessageToParentPage('dataGathererEvaluationComplete', payload);
  };

  /**
   * ...
   */
  private onEvaluationDraftExported = (draft: DataGatherer.Output) => {
    this.sendMessageToParentPage('dataGathererEvaluationDraftExported', draft);
  };

  render() {
    return <div id="data-gatherer" className="hosted-page"></div>;
  }
};

/** DataGathererPage view component. */
export const DataGathererPage = hot(_DataGathererPage);

// region Helper Functions

/**
 * ...
 */
interface ActivationRequestEvent {
  type: 'activateDataGatherer';
  data: DataGatherer.Config;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isActivationRequestEvent(
  value: unknown
): value is ActivationRequestEvent {
  return (
    isObject(value) &&
    value['type'] === 'activateDataGatherer' &&
    isObject(value['data'])
  );
}

/**
 * ...
 */
interface DeactivationRequestEvent {
  type: 'deactivateDataGatherer';
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isDeactivationRequestEvent(
  value: unknown
): value is DeactivationRequestEvent {
  return isObject(value) && value['type'] === 'deactivateDataGatherer';
}

// endregion Helper Functions
