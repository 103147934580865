/** ... */
const evaluationForm: DataGatherer.Form = {
  type: 'evaluation',
  id: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  interpretAs: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  sourceId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  name: 'LSI-R',
  rules: [
    {
      type: 'Omit',
      amount: 5,
      omitValue: '-1',
      ruleText: 'You may omit a maximum of 5 items.',
    },
  ],
  fields: [
    {
      type: 'field-group',
      heading: 'Criminal History',
      fields: [
        {
          id: 'SSHGwtIN',
          referenceId: '',
          label: 'Sources of Information 1',
          sequenceNumber: 19,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '5F0lnNHq',
          referenceId: '',
          label: 'Notes 1',
          sequenceNumber: 20,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'l9sw4igy',
          referenceId: '1.1.1',
          label: 'Any prior adult convictions?',
          sequenceNumber: 1,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          subFields: [
            {
              id: 'emCo0bPh',
              referenceId: '',
              label: 'Number of prior convictions',
              sequenceNumber: 16,
              required: false,
              model: { type: '', payloadLocation: '' },
              type: 'text',
              payloadType: 'unscoredValues',
              hideSequenceNumber: true,
            },
          ],
        },
        {
          id: 'qbSDyABn',
          referenceId: '1.1.2',
          label: 'Two or more prior adult convictions?',
          sequenceNumber: 2,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>l9sw4igy>1'],
                  message:
                    'If item {2} is answered "{Yes}", then item {1} must also be answered "{Yes}." We have already made this adjustment for you. Please see the LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '1RSOzktg',
          referenceId: '1.1.3',
          label: 'Three or more prior adult convictions?',
          sequenceNumber: 3,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: [
                    'cb9f6923-ba70-4d26-896b-6ed627ee3217>qbSDyABn>1',
                    'cb9f6923-ba70-4d26-896b-6ed627ee3217>l9sw4igy>1',
                  ],
                  message:
                    'If item {3} is answered "{Yes}", then items {1} and {2} must also be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'zNvqaNtQ',
          referenceId: '1.1.4',
          label: 'Three or more present offenses?',
          sequenceNumber: 4,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          subFields: [
            {
              id: 'Pt2QeKI5',
              referenceId: '',
              label: 'Number of present offences',
              sequenceNumber: 17,
              required: false,
              model: { type: '', payloadLocation: '' },
              type: 'text',
              payloadType: 'unscoredValues',
              hideSequenceNumber: true,
            },
          ],
        },
        {
          id: '7tf16Fc9',
          referenceId: '1.1.5',
          label: 'Arrested under age 16?',
          sequenceNumber: 5,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'EztQ6Gta',
          referenceId: '1.1.6',
          label: 'Ever incarcerated upon conviction?',
          sequenceNumber: 6,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '12QBb9kc',
          referenceId: '1.1.7',
          label: 'Escape history from a correctional facility?',
          sequenceNumber: 7,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '9C3puLFh',
          referenceId: '1.1.8',
          label: 'Ever punished for institutional misconduct?',
          sequenceNumber: 8,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          subFields: [
            {
              id: 'EaWQy6xu',
              referenceId: '',
              label: 'Number of times punished for institutional misconduct',
              sequenceNumber: 18,
              required: false,
              model: { type: '', payloadLocation: '' },
              type: 'text',
              payloadType: 'unscoredValues',
              hideSequenceNumber: true,
            },
          ],
        },
        {
          id: 'DAUiZ7mJ',
          referenceId: '1.1.9',
          label:
            'Charge laid or probation/parole suspended during prior community supervision?',
          sequenceNumber: 9,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '5TxaVmCp',
          referenceId: '1.1.10',
          label: 'Official record of assault/violence?',
          sequenceNumber: 10,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Education/Employment',
      fields: [
        {
          id: 'B3cMrF6x',
          referenceId: '',
          label: 'Sources of Information 2',
          sequenceNumber: 21,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'GgKul4Cj',
          referenceId: '',
          label: 'Notes 2',
          sequenceNumber: 22,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'gLwj8oFx',
          referenceId: '1.2.11',
          label: 'Currently unemployed?',
          sequenceNumber: 11,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: [
                    'cb9f6923-ba70-4d26-896b-6ed627ee3217>th7fCMY2>0',
                    'cb9f6923-ba70-4d26-896b-6ed627ee3217>bL36s22f>0',
                    'cb9f6923-ba70-4d26-896b-6ed627ee3217>CMihq8o1>0',
                  ],
                  message:
                    'If item {11} is answered "{Yes}", then items {18}, {19} and {20} must be answered "{0}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '66rGmHSb',
          referenceId: '1.2.12',
          label: 'Frequently unemployed?',
          sequenceNumber: 12,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'mPDQGVB3',
          referenceId: '1.2.13',
          label: 'Never employed for a full year?',
          sequenceNumber: 13,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'JcjKRwKn',
          referenceId: '1.2.14',
          label: 'Ever fired?',
          sequenceNumber: 14,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'zzsRbHPo',
          referenceId: '1.2.15',
          label: 'Less than regular grade 10?',
          sequenceNumber: 15,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>NaZf7SLQ>1'],
                  message:
                    'If item {15} is answered "{Yes}", then item {16} must also be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'NaZf7SLQ',
          referenceId: '1.2.16',
          label: 'Less than regular grade 12?',
          sequenceNumber: 16,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'zgEhAA5I',
          referenceId: '1.2.17',
          label: 'Suspended or expelled at least once?',
          sequenceNumber: 17,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'th7fCMY2',
          referenceId: '1.2.18',
          label: 'Participation/performance',
          sequenceNumber: 18,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'bL36s22f',
          referenceId: '1.2.19',
          label: 'Peer interactions',
          sequenceNumber: 19,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'CMihq8o1',
          referenceId: '1.2.20',
          label: 'Authority interactions',
          sequenceNumber: 20,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Financial',
      fields: [
        {
          id: '7s7wyakw',
          referenceId: '',
          label: 'Sources of Information 3',
          sequenceNumber: 23,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'uqjRfCyO',
          referenceId: '',
          label: 'Notes 3',
          sequenceNumber: 24,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'JsguIuLs',
          referenceId: '1.3.21',
          label: 'Problems',
          sequenceNumber: 21,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'wgR2W9uH',
          referenceId: '1.3.22',
          label: 'Reliance upon social assistance',
          sequenceNumber: 22,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Family/Marital',
      fields: [
        {
          id: 'zXOohakN',
          referenceId: '',
          label: 'Sources of Information 4',
          sequenceNumber: 25,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '4IIq4b9y',
          referenceId: '',
          label: 'Notes 4',
          sequenceNumber: 26,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '4bOlQJDD',
          referenceId: '1.4.23',
          label: 'Dissatisfaction with marital or equivalent situation',
          sequenceNumber: 23,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'u0xV4o36',
          referenceId: '1.4.24',
          label: 'Non-rewarding, parental',
          sequenceNumber: 24,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'bz2Sn9r0',
          referenceId: '1.4.25',
          label: 'Non-rewarding, other relatives',
          sequenceNumber: 25,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'C72XHETt',
          referenceId: '1.4.26',
          label: 'Criminal-Family/Spouse',
          sequenceNumber: 26,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Accommodation',
      fields: [
        {
          id: 'YI0ps7YU',
          referenceId: '',
          label: 'Sources of Information 5',
          sequenceNumber: 27,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '5Uiy1jdU',
          referenceId: '',
          label: 'Notes 5',
          sequenceNumber: 28,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'EbPBcZDA',
          referenceId: '1.5.27',
          label: 'Unsatisfactory',
          sequenceNumber: 27,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '1lkiVfSi',
          referenceId: '1.5.28',
          label: '3 or more address changes last year',
          sequenceNumber: 28,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'dz6X4bk5',
          referenceId: '1.5.29',
          label: 'High crime neighborhood',
          sequenceNumber: 29,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Leisure/Recreation',
      fields: [
        {
          id: 'Q02fgkGI',
          referenceId: '',
          label: 'Sources of Information 6',
          sequenceNumber: 29,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'HAI1GOAX',
          referenceId: '',
          label: 'Notes 6',
          sequenceNumber: 30,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '22z3vqwN',
          referenceId: '1.6.30',
          label: 'Absence of recent participation in an organized activity',
          sequenceNumber: 30,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'QfOJnVym',
          referenceId: '1.6.31',
          label: 'Could make better use of time',
          sequenceNumber: 31,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Companions',
      fields: [
        {
          id: 'mcFrvDWu',
          referenceId: '',
          label: 'Sources of Information 7',
          sequenceNumber: 31,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'L7Zwz2fg',
          referenceId: '',
          label: 'Notes 7',
          sequenceNumber: 32,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'BnCMKAIy',
          referenceId: '1.7.32',
          label: 'A social isolate',
          sequenceNumber: 32,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'hfaL4msR',
          referenceId: '1.7.33',
          label: 'Some criminal acquaintances',
          sequenceNumber: 33,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'B3lFbTxn',
          referenceId: '1.7.34',
          label: 'Some criminal friends',
          sequenceNumber: 34,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '3rKD7DLl',
          referenceId: '1.7.35',
          label: 'Few anti-criminal acquaintances',
          sequenceNumber: 35,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'M4kwllzd',
          referenceId: '1.7.36',
          label: 'Few anti-criminal friends',
          sequenceNumber: 36,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Alcohol/Drug Problem',
      fields: [
        {
          id: '5wvHNAU2',
          referenceId: '',
          label: 'Sources of Information 8',
          sequenceNumber: 35,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'kfBfENK5',
          referenceId: '',
          label: 'Notes 8',
          sequenceNumber: 36,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '6Y8K5jca',
          referenceId: '1.8.37',
          label: 'Alcohol problem, ever',
          sequenceNumber: 37,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'FRz3mLrs',
          referenceId: '1.8.38',
          label: 'Drug problem, ever',
          sequenceNumber: 38,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'AMqE0VKV',
          referenceId: '1.8.39',
          label: 'Alcohol problem, currently',
          sequenceNumber: 39,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: '0',
              value: '0',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>6Y8K5jca>1'],
                  message:
                    'If item {39} is answered "{0}" or "{1}", then item {37} must be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            {
              label: '1',
              value: '1',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>6Y8K5jca>1'],
                  message:
                    'If item {39} is answered "{0}" or "{1}", then item {37} must be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'Adf0f2S4',
          referenceId: '1.8.40',
          label: 'Drug problem, currently',
          sequenceNumber: 40,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: '0',
              value: '0',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>FRz3mLrs>1'],
                  message:
                    'If item {40} is answered "{0}" or "{1}", then item {38} must be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            {
              label: '1',
              value: '1',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>FRz3mLrs>1'],
                  message:
                    'If item {40} is answered "{0}" or "{1}", then item {38} must be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
          subFields: [
            {
              id: 'OwsROqnM',
              referenceId: '',
              label: 'Type of drug associated with current drug problem',
              sequenceNumber: 33,
              required: false,
              model: { type: '', payloadLocation: '' },
              type: 'text',
              payloadType: 'unscoredValues',
              hideSequenceNumber: true,
            },
          ],
        },
        {
          id: 'zcAnwGMf',
          referenceId: '1.8.41',
          label: 'Law violations',
          sequenceNumber: 41,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              warningCheck: [
                {
                  conditions: [
                    {
                      operator: '&&',
                      conditions: [
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '1',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '1',
                        },
                      ],
                    },
                  ],
                  conditionModifier: null,
                  warning:
                    'If at least one of items {41}, {42}, {43}, {44} or {45} is answered "{Yes}", then consider answering item {39} and/or {40} as "{0}" or "{1}".',
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'ALDOdzrn',
          referenceId: '1.8.42',
          label: 'Marital/Family',
          sequenceNumber: 42,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              warningCheck: [
                {
                  conditions: [
                    {
                      operator: '&&',
                      conditions: [
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '1',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '1',
                        },
                      ],
                    },
                  ],
                  conditionModifier: null,
                  warning:
                    'If at least one of items {41}, {42}, {43}, {44} or {45} is answered "{Yes}", then consider answering item {39} and/or {40} as "{0}" or "{1}".',
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '17ppEOJ2',
          referenceId: '1.8.43',
          label: 'School/Work',
          sequenceNumber: 43,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              warningCheck: [
                {
                  conditions: [
                    {
                      operator: '&&',
                      conditions: [
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '1',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '1',
                        },
                      ],
                    },
                  ],
                  conditionModifier: null,
                  warning:
                    'If at least one of items {41}, {42}, {43}, {44} or {45} is answered "{Yes}", then consider answering item {39} and/or {40} as "{0}" or "{1}".',
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'V7WA30op',
          referenceId: '1.8.44',
          label: 'Medical',
          sequenceNumber: 44,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              warningCheck: [
                {
                  conditions: [
                    {
                      operator: '&&',
                      conditions: [
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '1',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '1',
                        },
                      ],
                    },
                  ],
                  conditionModifier: null,
                  warning:
                    'If at least one of items {41}, {42}, {43}, {44} or {45} is answered "{Yes}", then consider answering item {39} and/or {40} as "{0}" or "{1}".',
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '9OGrmIbM',
          referenceId: '1.8.45',
          label: 'Other indicators',
          sequenceNumber: 45,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              warningCheck: [
                {
                  conditions: [
                    {
                      operator: '&&',
                      conditions: [
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>AMqE0VKV',
                          comparator: '1',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '0',
                        },
                        {
                          operator: '!=',
                          variable:
                            'cb9f6923-ba70-4d26-896b-6ed627ee3217>Adf0f2S4',
                          comparator: '1',
                        },
                      ],
                    },
                  ],
                  conditionModifier: null,
                  warning:
                    'If at least one of items {41}, {42}, {43}, {44} or {45} is answered "{Yes}", then consider answering item {39} and/or {40} as "{0}" or "{1}".',
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          subFields: [
            {
              id: 'jhJaG6RL',
              referenceId: '',
              label: 'Other indicators of drug problem',
              sequenceNumber: 34,
              required: false,
              model: { type: '', payloadLocation: '' },
              type: 'text',
              payloadType: 'unscoredValues',
              hideSequenceNumber: true,
            },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Emotional/Personal',
      fields: [
        {
          id: 'EoGMv6zP',
          referenceId: '',
          label: 'Sources of Information 9',
          sequenceNumber: 38,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '9IeKPmxV',
          referenceId: '',
          label: 'Notes 9',
          sequenceNumber: 39,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'YyqLwTWf',
          referenceId: '1.9.46',
          label: 'Moderate interference',
          sequenceNumber: 46,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '5JzTIynC',
          referenceId: '1.9.47',
          label: 'Severe interference, active psychosis',
          sequenceNumber: 47,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: ['cb9f6923-ba70-4d26-896b-6ed627ee3217>YyqLwTWf>1'],
                  message:
                    'If item {47} is answered "Yes", then item {46} must also be answered "{Yes}." We have already made this adjustment for you. Please see LSI-R scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'faHimskU',
          referenceId: '1.9.48',
          label: 'Mental health treatment, past',
          sequenceNumber: 48,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'os1rTP0Q',
          referenceId: '1.9.49',
          label: 'Mental health treatment, present',
          sequenceNumber: 49,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '5mfz4Wuf',
          referenceId: '1.9.50',
          label: 'Psychological assessment indicated',
          sequenceNumber: 50,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          subFields: [
            {
              id: 'WQvMCnoJ',
              referenceId: '',
              label: 'Area of psychological assessment indicated',
              sequenceNumber: 37,
              required: false,
              model: { type: '', payloadLocation: '' },
              type: 'text',
              payloadType: 'unscoredValues',
              hideSequenceNumber: true,
            },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Attitudes/Orientation',
      fields: [
        {
          id: 'dI0kKlMO',
          referenceId: '',
          label: 'Sources of Information 10',
          sequenceNumber: 40,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'a5dAG1PI',
          referenceId: '',
          label: 'Notes 10',
          sequenceNumber: 41,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'cWkukeev',
          referenceId: '1.10.51',
          label: 'Supportive of crime',
          sequenceNumber: 51,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'dxlslR61',
          referenceId: '1.10.52',
          label: 'Unfavorable toward convention',
          sequenceNumber: 52,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: 'lXTg7cvm',
          referenceId: '1.10.53',
          label: 'Poor, toward sentence',
          sequenceNumber: 53,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
        {
          id: '6DTYDoBH',
          referenceId: '1.10.54',
          label: 'Poor, toward supervision',
          sequenceNumber: 54,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
        },
      ],
    },
  ],
};

/** ... */
const generalInformationForm: DataGatherer.Form = {
  id: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  interpretAs: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  sourceId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  name: 'General Information',
  type: 'unknown',
  fields: [
    {
      id: 'HHN0d5nu',
      referenceId: '',
      label: 'Offender Name',
      sequenceNumber: 1,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'RDCLhqqN',
      referenceId: '',
      label: 'Offender Age',
      sequenceNumber: 2,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'yCrQpDIB',
      referenceId: '',
      label: 'Offender Gender',
      sequenceNumber: 3,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'unscoredValues',
      answers: [
        { label: 'Male', value: 0 },
        { label: 'Female', value: 1 },
      ],
      hideSequenceNumber: true,
    },
    {
      id: 'tVQIjRrD',
      referenceId: '',
      label: 'Offender Social Security Number',
      sequenceNumber: 4,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'yJzSe2SF',
      referenceId: '',
      label: 'Offender ID Number',
      sequenceNumber: 5,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'thb5KV0f',
      referenceId: '',
      label: 'Referral Source',
      sequenceNumber: 6,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'Q9PBcyUT',
      referenceId: '',
      label: 'Reason for Referral',
      sequenceNumber: 7,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'OHBqEt1J',
      referenceId: '',
      label: 'Present Offences',
      sequenceNumber: 8,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'fYFmiQ4T',
      referenceId: '',
      label: 'Disposition',
      sequenceNumber: 9,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'M3gMphRw',
      referenceId: '',
      label: "Rater's Name",
      sequenceNumber: 10,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'NRNczH0y',
      referenceId: '',
      label: 'Purpose of Report',
      sequenceNumber: 11,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'a8myPtAv',
      referenceId: '',
      label: 'Context',
      sequenceNumber: 12,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: '4J0bjSEy',
      referenceId: '',
      label: 'Other Client Issues',
      sequenceNumber: 13,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'wIMzo7LN',
      referenceId: '',
      label: 'Date of Assessment',
      sequenceNumber: 14,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'rQXuuBcP',
      referenceId: '',
      label: 'Setting',
      sequenceNumber: 15,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'unscoredValues',
      answers: [
        { label: 'Community', value: 0 },
        { label: 'Institutional', value: 1 },
      ],
      hideSequenceNumber: true,
    },
  ],
};

/** ... */
const references: DataGatherer.Tool.Reference[] = [
  {
    id: '1.1.1',
    title: 'Reference Text',
    content: 'Record the number of prior convictions in the space provided.',
  },
  {
    id: '1.1.2',
    title: 'Reference Text',
    content:
      'Any number of offenses dealt with at the same time or when one sentence equals one conviction.',
  },
  {
    id: '1.1.3',
    title: 'Reference Text',
    content:
      'If this item is answered "Yes," then items 1 and 2 must also be answered "Yes.”',
  },
  {
    id: '1.1.4',
    title: 'Reference Text',
    content:
      'Does the client have three or more charges for which he/she has been convicted and is presently serving a sentence in an institution or in the community? There is no accounting for outstanding charges on the LSI-R. Record the number of present offenses in the space provided.',
  },
  {
    id: '1.1.5',
    title: 'Reference Text',
    content:
      'Mark this item yes if the client was arrested before the age of 16.',
  },
  {
    id: '1.1.6',
    title: 'Reference Text',
    content:
      'This includes incarcerations as an adult and as a young offender/juvenile delinquent. This also includes incarceration for unpaid fines.',
  },
  {
    id: '1.1.7',
    title: 'Reference Text',
    content:
      'Has the client had any history of escape or attempted escape from a youth or adult correctional facility, including institutional and residential facilities?',
  },
  {
    id: '1.1.8',
    title: 'Reference Text',
    content:
      'Record the number of official institutional misconducts, excluding those for which no punishment was adjudicated, in the space provided.',
  },
  {
    id: '1.1.9',
    title: 'Reference Text',
    content:
      'Community supervision includes probation, bail, community service order, parole, and mandatory supervision.',
  },
  {
    id: '1.1.10',
    title: 'Reference Text',
    content:
      'This includes any prior or current assaultive/violent offenses or violent misconducts. It refers to both adult and young offender/juvenile delinquent records. An assaultive/violent offense is one in which there was harm, potential harm, or threat of harm; an element of coercion or force, be it physical or psychological; and/or the presence of a weapon. In a case where there is an official record but not a conviction for an assaultive/violent offense, consider whether the act could be seen as serious assaultive/violent behavior.',
  },
  {
    id: '1.2.11',
    title: 'Reference Text',
    content:
      'This subcomponent is relatively straightforward when interviewing probationers and parolees. However, extra care is needed when administering the LSI-R to inmates who are, strictly speaking, unemployed. When administering the LSI-R to an incarcerated offender, it is helpful to view his/her incarceration as a type of "leave without pay." If he/she is serving a relatively brief sentence (under two years) consider first whether he/she was working before incarceration and, if so, will he/she be able to return to this job? If the answer is no, then the offender is assessed as unemployed. If an offender, whether incarcerated or not, is being paid to participate in a training program and there is a work component, then he/she is assessed as employed. If item 11 is answered "Yes," then items 18, 19, and 20 are each rated as "0."',
  },
  {
    id: '1.2.12',
    title: 'Reference Text',
    content:
      'Has the client been employed less than 50% of the last twelve months? If currently incarcerated, has the offender been employed less than 50% of the the twelve months prior to incarceration?',
  },
  {
    id: '1.2.13',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the client has never been employed in the community for a continuous twelve months.',
  },
  {
    id: '1.2.14',
    title: 'Reference Text',
    content: 'Mark yes if the client has ever been fired from a job.',
  },
  {
    id: '1.2.15',
    title: 'Reference Text',
    content:
      'In school or when in school: Item 15 refers to achievement in education through a regular academic or technical high school program. Upgrading, equivalency, and correspondence programs are not considered as regular high school programs. However, if the client is presently attending an alternative program of education, do assess his/her reward ratings for school in items 18,19, 20. Less than regular grade 10 means that the client has not achieved a grade 10 education during attendance at an academic or technical high school. If this item is answered "Yes," then item 16 must also be answered "Yes."',
  },
  {
    id: '1.2.16',
    title: 'Reference Text',
    content:
      'In school or when in school: Item 16 refers to achievement in education through a regular academic or technical high school program. Upgrading, equivalency, and correspondence programs are not considered as regular high school programs. However, if the client is presently attending an alternative program of education, do assess his/her reward ratings for school in items 18,19, 20. Less than regular grade 12 means that the client has not achieved a grade 12 education during attendance at an academic or technical high school.',
  },
  {
    id: '1.2.17',
    title: 'Reference Text',
    content:
      'Mark yes if the client has been suspended or expelled at least once.',
  },
  {
    id: '1.2.18',
    title: 'Reference Text',
    content:
      'If the person is not working, or not attending school, the rating on each of the indices is "0."\n\nIf the client, prior to incarceration, was not in school and was not employed at the time of his/her incarceration, then rate "0" for items 18, 19, and 20. Rate the student client on the education scales. Rate the working client on the employment scales.\n\nIf the client is both working and attending school, then first rate Items 18, 19, and 20 for education and rate Items 18,19, 20 for employment. Use the higher of the two ratings for each item as the final ratings for items 18, 19, 20.\n\nFor Participation/Performance in Education, Highest Level (3): At this level, the client has a high interest in school-related activities. The client does homework, readings, and assignments promptly and reliably and has average to above average grades (C or higher). He/she has regular attendance at school.\n\nLowest (0): At this level, the client has as active dislike for schoolwork and activities. Homework is usually late or not completed, and the person has a history of truancy and skipping classes. His/her grades are below average (D or F).\n\nFor Participation/Performance in Employment, Highest Level (3): At this level, the client expresses strong interest in his or her job and expresses pride in his or her abilities and performance. The client has received positive strokes from his or her boss (for performance). The client\'s attendance at work is reliable, and he or she is willing to work overtime. The client wants to stay in the same line of work.\n\nLowest (0): At this level, the client hates his or her job, citing reasons such as it is boring, dangerous, unpleasant, or that he or she cannot perform well. The job is a means of earning a living only, and it is not even satisfactory on those terms. The client has unreliable attendance and is often late. He or she wants to change jobs and may quit even if another job is not available.',
  },
  {
    id: '1.2.19',
    title: 'Reference Text',
    content:
      'If the client is not working, or not attending school, the rating on each of the indices is "0."\n\nIf the client, prior to incarceration, was not in school and was not employed at the time of his/her incarceration, then rate "0" for items 18, 19, and 20. Rate the student client on the education scales. Rate the working client on the employment scales. If the client is both working and attending school, then first rate Items 18, 19, and 20 for education and rate Items 18,19, 20 for employment. Use the higher of the two ratings for each item as the final ratings for items 18, 19, 20.\n\nReward Rating for Education: Highest (3): At this level, the client gets along with fellow students, talking and eating lunch with them. They spend time outside of school together, and his/her best friends are at the same school. Lowest (0): At this level, the client has experienced significant and continuous problems with his/her fellow students or has had virtually no contact with them. He/she remains isolated and gets into repeated fights and arguments. The client\'s best friends are not in school.\n\nFor Participation/Performance in Employment, Highest Level (3): At this level, the client gets along with coworkers. They talk together and take breaks and lunches together. Outside of work, they pursue leisure activities together. The client helps coworkers on the job and is cooperative and friendly. Lowest (0): At this level, the client has continuous problems with coworkers and gets into fights and arguments. The client tends to remain isolated.',
  },
  {
    id: '1.2.20',
    title: 'Reference Text',
    content:
      'If client is a homemaker or pensioner, then OMIT this item.\n\nIf the client is not working, or not attending school, the rating on each of the indices is "0." If the client, prior to incarceration, was not in school and was not employed at the time of his/her incarceration, then rate "0" for items 18, 19, and 20. Rate the student client on the education scales. Rate the working client on the employment scales. If the client is both working and attending school, then first rate Items 18, 19, and 20 for education and rate Items 18,19, 20 for employment. Use the higher of the two ratings for each item as the final ratings for items 18, 19, 20.\n\nReward rating for Education, Highest Level (3): At this level, the client talks to teachers in and out of class and has sought (or would seek) the opinion of the teacher regarding a personal accomplishment or interpersonal problem. The client respects his/her teacher and values the teacher\'s opinion. Lowest Level (0): At this level, the client avoids and hates his/her teacher. The client heckles in class and gets into arguments. The client receives frequent detentions and is often sent to the principal\'s office for displays of disrespect.\n\nReward rating for Employment, Highest Level (3): At this level, the client has respect, if not a liking for, his or her supervisor. The client talks with his or her boss, even regarding matters unassociated with work and would approach his or her boss with problems. Lowest Level (0): At this level, the client has ongoing and significant problems and conflicts with his or her boss. He or she will not follow orders and argues with the boss. The client thinks others are treated better by the boss.',
  },
  {
    id: '1.3.21',
    title: 'Reference Text',
    content:
      'Reward Rating for Financial Situation:\n\n"3": Long-standing pattern of effective management;\n"2": No current difficulties;\n"1": Situational or minor difficulties or need to explore the situation further;\n"0": Severe difficulties or no visible means of support.\n\nThe dictionary definition of "difficulty" is "something that cannot easily be done; an obstacle or a hindrance; trouble." Therefore, debts (e.g., mortgage, student loan) are not necessarily difficulties, if they are manageable. As a general consideration, is the person\'s financial situation a current stressor, regardless of the source of income (including criminal activities)?',
  },
  {
    id: '1.3.22',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender is receiving General Welfare Assistance or Family Benefits Allowance. As well, Workers\' Compensation, Unemployment Insurance and Disability Pension are considered here to be indicative of need as the offender\'s present income is fixed. If the offender is receiving one of these forms of assistance, this item is answered "Yes."',
  },
  {
    id: '1.4.23',
    title: 'Reference Text',
    content:
      'In general, this area is dynamic and is assessed on current family/marital interactions. There may well be historical issues from family/marital relationships that are present needs. Such needs may be noted in the emotional/personal area. Dissatisfaction with marital or equivalent situation.\n\nHighest Level (3): At this level, the offender has a highly rewarding, caring relationship, or satisfaction with being single and unattached. Lowest Level (0): At this level, the offender has an unpleasant, uncaring, hostile, and/or indifferent relationship that may involve intimate violence. Alternatively, he/she may be unsatisfied with being single.',
  },
  {
    id: '1.4.24',
    title: 'Reference Text',
    content:
      'Reward Rating for Interaction with Parents: "3" indicates a highly rewarding, satisfying relationship. "0" indicates a punishing, unpleasant, uncaring, hostile, indifferent relationship; or that both parents are deceased; or that one parent is deceased or absent from home, and there is a negative relationship with the one seen most often.\n\nHighest Level (3): At this level, the offender sees or writes his/her parents often and hugs or kisses when greeting them. The relationship is liking/loving, open, and warm. The offender talks about important matters with his/her parents and cares about what they think, expect, and feel.\n\nLowest Level (0): At this level, the offender argues with his/her parents when they are together and rarely sees or writes them. The relationship ranges from dislike to hatred, and the offender does not care what his/her parents think, feel, or expect.',
  },
  {
    id: '1.4.25',
    title: 'Reference Text',
    content:
      'Reward Rating for Interaction with Other Relatives: Record one of "3," "2," "1," or "0."\n\nA "3" indicates a highly rewarding relationship and "0" indicates no positive relationship with others such as siblings, grandparents, aunts, uncles, cousins, or in-laws who are regularly seen or contacted. The levels are similar to those in item 24.',
  },
  {
    id: '1.4.26',
    title: 'Reference Text',
    content:
      'Check if parent, sibling, spouse, or close relative has a criminal record.',
  },
  {
    id: '1.5.27',
    title: 'Reference Text',
    content:
      'If the offender has no fixed address, a rating of "0" should be applied.\n\nReward Rating for Accommodation: Highest Level (3): At this level, the offender is very satisfied with his/her accommodation. The offender takes pride in his/her house, yard, and neighborhood and helps with its upkeep. Lowest Level (0): At this level, the offender is unhappy and dissatisfied with his/her accommodation situation. He/she takes no pride and makes no attempt to improve the residence. The offender expresses a desire to move, and others that live there would like for him/her to move.',
  },
  {
    id: '1.5.28',
    title: 'Reference Text',
    content:
      'Record number of address changes within the last twelve months or in the year prior to incarceration. Do not include a period of incarceration as an address change.',
  },
  {
    id: '1.5.29',
    title: 'Reference Text',
    content:
      'Answer "Yes" if offender resides in a high crime neighborhood, or if the neighborhood has a high proportion of offenders. Example questions may include: Do the police visit your neighborhood often? Are there people in the area who are dealing drugs, doing break and enters, or fencing stolen property? Consider if the area is criminally active or opportune.',
  },
  {
    id: '1.6.30',
    title: 'Reference Text',
    content:
      'When in the community: Absence of recent participation in an organized activity. Answer "Yes" if the offender has not participated or been involved in a formal organization during the last twelve months or in the year prior to incarceration (e.g., union, service club, sports club or team, church). Concerning the offender who is participating in an "Anonymous" group, (e.g., AA, NA, CA), consider the motivation or incentives for attending and the degree of involvement. Is the offender participating in the group\'s social activities (e.g., dances, weekend BBQs)? Is the offender receiving social rewards from participation?',
  },
  {
    id: '1.6.31',
    title: 'Reference Text',
    content:
      "Reward Rating for Leisure/Recreation:\n\nHighest Level (3) : At this level, the offender is involved in highly rewarding and satisfying activities (e.g., stamp collecting, weight lifting, martial arts, car repairs).\n\nLowest Level (0): At this level, the offender has no leisure activities and is bored during most of his/her idle time. At the risk of making a value judgment, discover in what ways an offender's activities are rewarding for him/her. How is his/her use of time possibly impacting on, or relating to, other need areas (e.g., education/employment, alcohol/drugs)?",
  },
  {
    id: '1.7.32',
    title: 'Reference Text',
    content:
      'When in the community: A social isolate.\n\nAnswer "Yes" if the offender lacks a significant, intimate, and mutual relationship with a nonrelative. The offender may express difficulty in meeting others or keeping friends, or he/she may be unable to get along with others. The offender may present himself/herself as shy, withdrawn, and with limited social skills. The offender may demonstrate a chronic inability to form a bond or to interact with someone as a friend. The offender may express extreme independence or disregard for the feelings and expectations of others. The offender engages in solitary activities, but not by his/her choice.',
  },
  {
    id: '1.7.33',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender associates with (or did associate with prior to incarceration) individuals who are not close friends, but have criminal records or are involved in criminal activities. If item 34 is answered "Yes," then this item must also be answered "Yes."',
  },
  {
    id: '1.7.34',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender has friends (or did prior to incarceration) who have criminal records or are involved in criminal activity. Friends are associates with whom one spends leisure time, whose opinions are valued, who provides help when in difficulty, etc.',
  },
  {
    id: '1.7.35',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender has few acquaintances who are not involved in criminal activity or who do not have criminal records. If this item is answered "Yes," then item 36 must also be answered "Yes."',
  },
  {
    id: '1.7.36',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender has few friends who are not involved in criminal activities or who do not have criminal records.',
  },
  {
    id: '1.8.37',
    title: 'Reference Text',
    content:
      'Alcohol/Drug Problem excludes nicotine and caffeine. If item 39 is answered "0" or "1" then this item must be answered "Yes." Some offenders will report heavy alcohol consumption but also claim it is no problem. Assessment of alcohol "problem" depends upon the interviewer\'s assessment and not the offender\'s evaluation.',
  },
  {
    id: '1.8.38',
    title: 'Reference Text',
    content:
      'If item 40 is answered "Yes," then this item must be answered "Yes."',
  },
  {
    id: '1.8.39',
    title: 'Reference Text',
    content:
      'Assign a rating of "0" or "1" for offender\'s alcohol abuse or if offender perceives himself/herself as having a problem with alcohol. "Currently" refers to the past year. When assessing an incarcerated offender, consider his/her drinking behavior during the year prior to incarceration. Consider also any drinking behavior while incarcerated.\n\nAlcohol Abuse Rating: Highest Level (3) At this level, there is no evidence of current problems. The offender is an abstainer or controlled recreational user. Lowest Level (0) At this level, at least one of items 41 through 45 is applicable.',
  },
  {
    id: '1.8.40',
    title: 'Reference Text',
    content:
      'Drug problem, currently. Assign a rating of "0" or "1" for offender\'s drug abuse or if offender perceives himself/herself as having a problem with drugs. Specify the problem drug(s) in the space provided. "Currently" refers to the past year. When assessing an incarcerated offender, consider his/her drug-taking during the year prior to incarceration. Consider also any drug-taking behavior while incarcerated.\n\nDrug Abuse Rating: Highest Level (3) At this level, there is no evidence of current problems. The offender is an abstainer or controlled recreational user. Lowest Level (0): At this level, at least one of items 41 through 45 is applicable.',
  },
  {
    id: '1.8.41',
    title: 'Reference Text',
    content:
      'Items 41 to 45 are evaluated based on current use. If the offender has stopped alcohol and/or drug abuse for more than a year, then these items are not covered (Answer "No").\n\nFor item 41, answer "Yes" if the use (not possession only) of drugs or alcohol contributed, is contributing, or could contribute to violation of the law based on the offender\'s official records or your knowledge of the offender\'s situation. Examples: trafficking in drugs to support a habit, break and enters, and thefts of property to fence for money for drugs, parole violations.',
  },
  {
    id: '1.8.42',
    title: 'Reference Text',
    content:
      'Items 41 to 45 are evaluated based on current use. If the offender has stopped alcohol and/or drug abuse for more than a year, then these items are not covered (Answer "No").\n\nFor this item, answer "Yes" if the use of alcohol or drugs has contributed to problems with marital or family situation or if significant others have complaints about offender\'s drug use or drinking.',
  },
  {
    id: '1.8.43',
    title: 'Reference Text',
    content:
      'Items 41 to 45 are evaluated based on current use. If the offender has stopped alcohol and/or drug abuse for more than a year, these items are not covered (Answer "No").\n\nAnswer "Yes" to this item if the use of drugs or alcohol has contributed to problems with school or employment. Examples: hangover prevented going to school or work, asked to leave school because of drug use, loss of employment because of intoxication.',
  },
  {
    id: '1.8.44',
    title: 'Reference Text',
    content:
      'Items 41 to 45 are evaluated based on current use. If the offender has stopped alcohol and/or drug abuse for more than a year, these items are not covered (Answer "No").\n\nAnswer "Yes" to this item if the use of drugs or alcohol has contributed to physical complaints or if a medical doctor has warned the offender about drug/alcohol use.',
  },
  {
    id: '1.8.45',
    title: 'Reference Text',
    content:
      'Items 41 to 45 are evaluated based on current use. If the offender has stopped alcohol and/or drug abuse for more than a year, these items are not covered (Answer "No").\n\nAnswer "Yes" to this item if there is, for example, frequent use and a high quantity of consumption, drinking to unconsciousness, difficulty reducing intake, financial difficulties due to use, use of substances to avoid hangover or withdrawal, DTs, use of the substance first thing in the morning, sneaking drinks or "fixes," blackouts.',
  },
  {
    id: '1.9.46',
    title: 'Reference Text',
    content:
      '"Interference" refers to an individual\'s ability to respond to life\'s stressors, and to the quality of that person\'s functioning in the real world. Is his/her ability and functioning affected by psychological or psychiatric problems? Assess offender\'s level of adaptive functioning with regard to the past year.\n\nThe scoring of this item is left to the discretion of the interviewer. However, if item 47 is answered "Yes," this item must also be answered "Yes." Examples of moderate interference or emotional distress are signs of mild anxiety (insomnia, worrying) and signs of mild depression (quiet, underassertive). Consider also the offender whose emotional and cognitive functioning seems stabilized through mental health intervention.',
  },
  {
    id: '1.9.47',
    title: 'Reference Text',
    content:
      'This item should be answered "Yes" based on any indicator(s) of offender\'s mental health problems. The intent of the item is to detect active psychosis in an offender.\n\nSevere emotional and cognitive interference may also be detected by observing the following types of indicators during the interviews: excessive sweating, extreme passivity or aggression, verbal abusiveness, odd or strange verbalizations, very slow or very fast speech, rambling conversation, report of auditory and/or visual hallucinations, delusional thinking.',
  },
  {
    id: '1.9.48',
    title: 'Reference Text',
    content:
      'Mark yes if the client has had a mental-health treatment in the past.',
  },
  {
    id: '1.9.49',
    title: 'Reference Text',
    content: 'Mental-health treatment, present?',
  },
  {
    id: '1.9.50',
    title: 'Reference Text',
    content:
      'If the offender has been assessed within the past year and the interviewer has knowledge of the problems that the assessment indicated were present, then answer "Yes" for this item and note what assessment indicated.\n\nIf the offender has never been assessed or if it is unknown whether the offender has ever been assessed but there are indicators of problems with the following, answer "Yes" for this item.\n\nNote the problems that the offender\'s behaviors indicate; for example: intellectual functioning; academic/vocational potential; academic/vocational interests; excessive fears, negative attitudes toward self, depression, tension; hostility, anger, potential for assaultive behavior, overassertion/aggression; impulse control, self-management skills; interpersonal skills, underassertive; contact with reality, severe withdrawal, overactivity, possibility of delusion/hallucination; disregard rules and feelings of others; criminal acts that don\'t make sense, appear irrational; other (specify)',
  },
  {
    id: '1.10.51',
    title: 'Reference Text',
    content:
      'This need area is concerned with what and how a person thinks about himself/herself, others, and the world. Are his/her attitudes, values, beliefs, and thinking procriminal and antisocial or anticriminal and prosocial?\n\nHighest Level (3): At this level, the offender places an emphasis on the negative consequences of law violation for offender, the victim, and the community. He/she accepts responsibility for his/her own actions and the consequences of those actions. The offender rejects, or at least places more realistic limits on, rationalizations or justifications for law violations. He/she identifies with the victim of the crime and expresses the risk involved in associating with criminal others or accepting their values.\n\nLowest Level (0): At this level, the offender places an emphasis on the usefulness of criminal activity. His/her motives are more readily served by crime than by non-crime. The offender expresses acceptance of the common rationalizations or justifications for law violations (e.g., "It wasn’t my fault." "No one was hurt." "The victim had it coming to him." "The cops were always on me for something."). He/she denies or minimizes responsibility for his/her actions and their consequences. The offender expresses outright rejection of, or fails to empathize or be sensitive to, the feelings, wishes, and expectations of others, including the victims of criminal behavior. He/she accepts criminal others and their values and activities, and expresses hostility toward the criminal justice system.',
  },
  {
    id: '1.10.52',
    title: 'Reference Text',
    content:
      'Here, "Convention" means an alternative to crime and criminal lifestyle.\n\nHighest Level (3): At this level, the person is supportive of society\'s institutions (e.g., government, business, labor, service systems, home and family, school and work, spiritual institutions, recreational and social organizations) and their underlying values (e.g., order, peace, justice, material satisfaction, love and affection, security, interpersonal satisfactions, monetary, and other satisfaction of achievements). He/she has positive attitudes and feelings toward conventional/noncriminal others (e.g., peers, parents, siblings, spouse, teachers, employers) and values interactions with these others. The person indicates positive functions of conventional activities (e.g., working, studying, playing sports, controlled drinking) and places an emphasis on rewards of such activities. He/she has a sense of accomplishment and feelings of self-worth based on achievement unconventional pursuits and activities.\n\nLowest Level (0): At this level, the offender has a generalized disregard for conventional/non-crime alternatives. He/she is non-supportive, hostile and rejects the underlying values of society. The offender has weak ties to conventional settings such as home/family and school/work and is negative, hostile, and rejecting of noncriminal others ("I don’t care what they think."). He/she invalidates or rejects noncriminal activities and their rewards and has a tolerance for deviance in general.',
  },
  {
    id: '1.10.53',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender denies the fairness/appropriateness of his/her sentence.',
  },
  {
    id: '1.10.54',
    title: 'Reference Text',
    content:
      'Answer "Yes" if the offender objects to his/her classification or placement and/or if there is an unwillingness to comply or cooperate, or to seek assistance for significant problems.',
  },
];

/** ... */
export const data: DataGatherer.Tool = {
  id: 'LSI-R',
  measureId: 'ca161b12-e6a0-4086-882d-a7f1dce51aa5',
  evaluatorId: 'f493205d-f422-453f-81f9-6ba225334bd3',
  version: '0.0.1',
  name: 'LSI-R',
  references,
  forms: [generalInformationForm, evaluationForm],
};
