import React from 'react';

import { EvaluationDisplay } from '@features/evaluation/EvaluationDisplay';

import './EvaluationView.scoped.scss';

/**
 * ...
 */
export const EvaluationView: React.FC = () => {
  return <EvaluationDisplay />;
};
