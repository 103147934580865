import React from 'react';

import './SectionHeading.scoped.scss';

/**
 * `Form` component properties.
 */
interface SectionHeadingProps {
  label: string;
}

/**
 * ...
 */
export const SectionHeading: React.FC<SectionHeadingProps> = ({ label }) => {
  return (
    <div className="evaluation-section-heading">
      <legend>{label}</legend>
    </div>
  );
};
