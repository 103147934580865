/**
 * ...
 */
export class Logger {
  constructor(private tag?: string) {}

  /**
   * ...
   *
   * @param message ...
   */
  info(message: string, ...args: unknown[]) {
    console.log(this.composeMessage(message), ...args);
  }

  /**
   * ...
   *
   * @param message ...
   */
  warn(message: string, ...args: unknown[]) {
    console.warn(this.composeMessage(message), ...args);
  }

  /**
   * ...
   *
   * @param message ...
   */
  error(message: string, ...args: unknown[]) {
    console.error(this.composeMessage(message), ...args);
  }

  /**
   * Create a new logger who's tag is a child of this logger.
   * example: ("[parent:child]")
   *
   * @param tag Tag of the child, to be combined with its parent.
   * @return The child `Logger` instance.
   */
  createChild(tag: string) {
    return new Logger(`${this.tag}:${tag}`);
  }

  /**
   * ...
   *
   * @param message ...
   * @return ...
   */
  private composeMessage(message: string) {
    return (this.tag ? `[${this.tag}] ` : '') + message;
  }
}
