/** ... */
const evaluationForm: DataGatherer.Form = {
  type: 'evaluation',
  id: '92c2954d-7263-4879-a673-e1f688190054',
  interpretAs: '92c2954d-7263-4879-a673-e1f688190054',
  sourceId: '92c2954d-7263-4879-a673-e1f688190054',
  name: 'YLS/CMI:SRV',
  rules: [
    {
      type: 'Omit',
      amount: 2,
      omitValue: '-1',
      ruleText: 'You may omit a maximum of 2 items.',
    },
  ],
  fields: [
    {
      id: 'ya4aP8bj',
      referenceId: '',
      label: 'General Notes for Evaluation',
      sequenceNumber: 13,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'X2yq6eQC',
      referenceId: '',
      label: 'Additional Comments',
      sequenceNumber: 15,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'a2whtQxw',
      referenceId: '1',
      label: 'History of conduct disorder',
      sequenceNumber: 1,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: 'gy5j23eZ',
      referenceId: '',
      label: 'Question 1 Comment',
      sequenceNumber: 16,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'u6d7yHtZ',
      referenceId: '2',
      label: 'Current school or employment problems',
      sequenceNumber: 2,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: 'awGqNJhc',
      referenceId: '',
      label: 'Question 2 Comment',
      sequenceNumber: 17,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'su3Qc3eG',
      referenceId: '3',
      label: 'Some criminal friends',
      sequenceNumber: 3,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: 'AKAyW2gS',
      referenceId: '',
      label: 'Question 3 Comment',
      sequenceNumber: 18,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'J6YjmrYJ',
      referenceId: '4',
      label: 'Alcohol/drug problems',
      sequenceNumber: 4,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: 'c5FhJgHC',
      referenceId: '',
      label: 'Question 4 Comment',
      sequenceNumber: 19,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'CnL78DxM',
      referenceId: '5',
      label: 'Leisure/recreation',
      sequenceNumber: 5,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: '2byuyDJ3',
      referenceId: '',
      label: 'Question 5 Comment',
      sequenceNumber: 20,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'RD7A7QGE',
      referenceId: '6',
      label: 'Personality/behaviour',
      sequenceNumber: 6,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: 'e3GBr82n',
      referenceId: '',
      label: 'Question 6 Comment',
      sequenceNumber: 21,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'Lw36JP7F',
      referenceId: '7',
      label: 'Family circumstances/parenting',
      sequenceNumber: 7,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: '3', value: '3' },
        { label: '2', value: '2' },
        { label: '1', value: '1' },
        { label: '0', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: '9FN292Ut',
      referenceId: '',
      label: 'Question 7 Comment',
      sequenceNumber: 22,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'WfW47Dvz',
      referenceId: '8',
      label: 'Attitudes/orientation',
      sequenceNumber: 8,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: '3', value: '3' },
        { label: '2', value: '2' },
        { label: '1', value: '1' },
        { label: '0', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: 'VbQpGXKb',
      referenceId: '',
      label: 'Question 8 Comment',
      sequenceNumber: 23,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
  ],
};

/** ... */
const generalInformationForm: DataGatherer.Form = {
  id: 'ed315b5f-f6d0-40db-97c5-44a8888f69c0',
  interpretAs: 'ed315b5f-f6d0-40db-97c5-44a8888f69c0',
  sourceId: 'ed315b5f-f6d0-40db-97c5-44a8888f69c0',
  name: 'General Information',
  type: 'unknown',
  fields: [
    {
      id: 'qYAvDM3u',
      referenceId: '',
      label: 'Name of Assessed',
      sequenceNumber: 1,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'AAygEecg',
      referenceId: '',
      label: "Interviewer's Name",
      sequenceNumber: 2,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'aVPjJn36',
      referenceId: '',
      label: 'Date of Birth',
      sequenceNumber: 3,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'date',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'aFAJYRHj',
      referenceId: '',
      label: 'Gender',
      sequenceNumber: 4,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'cGcWxcr5',
      referenceId: '',
      label: 'Date of Test Administration',
      sequenceNumber: 5,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'date',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: '2uRPH29p',
      referenceId: '',
      label: 'Identifying Number',
      sequenceNumber: 6,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: '7G47NBct',
      referenceId: '',
      label: 'Referral Source',
      sequenceNumber: 7,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'uqDDUFKc',
      referenceId: '',
      label: 'Reason for Referral',
      sequenceNumber: 8,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'H2CeJstz',
      referenceId: '',
      label: 'Disposition',
      sequenceNumber: 9,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'syXd7UZh',
      referenceId: '',
      label: 'Present Offences',
      sequenceNumber: 10,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'y5pNvLag',
      referenceId: '',
      label: 'Race/Ethnicity',
      sequenceNumber: 11,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'dUNx6Hwr',
      referenceId: '',
      label: 'Current Location',
      sequenceNumber: 12,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'unscoredValues',
      hideSequenceNumber: true,
    },
  ],
};

/** ... */
const references: DataGatherer.Tool.Reference[] = [
  {
    id: '1',
    title: 'Reference Text',
    content:
      'Select "Yes" if there are two or more prior cautions/adjudications or other evidence of earlier conduct problems.',
  },
  {
    id: '2',
    title: 'Reference Text',
    content:
      'Select "Yes" if the youth is exhibiting serious behavioral or achievement problems in school; is suspended or expelled; also select "Yes" if out-of-school and not employed or seeking employment without good reason.',
  },
  {
    id: '3',
    title: 'Reference Text',
    content:
      'Select "Yes" if the youth has some friends who have a history of delinquency or are currently exhibiting conduct problems or antisocial attitudes.',
  },
  {
    id: '4',
    title: 'Reference Text',
    content:
      'Select "Yes" if the youth is frequently or chronically using drugs and/or alcohol; drugs/alcohol affecting schoolwork, social life, etc.',
  },
  {
    id: '5',
    title: 'Reference Text',
    content:
      'Select "Yes" if the youth is not engaged in organized or otherwise positive leisure time activities; few interests; poor use of time.',
  },
  {
    id: '6',
    title: 'Reference Text',
    content:
      'Select "Yes" if the youth exhibits serious personality or behavioral problems (e.g., physical/verbal aggression; short attention span; hyperactive; poor controls).',
  },
  {
    id: '7',
    title: 'Reference Text',
    content:
      '0 indicates a positive and supportive family/parenting situation; 3 indicates a very poor situation (e.g., abuse, frequent conflicts, inadequate supervision).',
  },
  {
    id: '8',
    title: 'Reference Text',
    content:
      '0 indicates that the youth exhibits a positive and prosocial attitude/value system; 3 indicates an extreme antisocial and procriminal attitude/value system.',
  },
];

/** ... */
export const data: DataGatherer.Tool = {
  id: 'YLS/CMI:SRV',
  measureId: 'e9600645-6280-4602-941d-52a645bb3f79',
  evaluatorId: '733b00dd-e6f5-4328-a355-e22f369a4642',
  version: '0.0.1',
  name: 'YLS/CMI:SRV',
  references,
  forms: [generalInformationForm, evaluationForm],
};
