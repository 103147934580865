/** ... */
let instanceId = 1;

/**
 * ...
 */
export class HostedPage {
  readonly instanceId: string;
  readonly replacedEl: HTMLElement;
  readonly wrapperEl: HTMLDivElement;
  readonly iFrameEl: HTMLIFrameElement;

  private constructor(node: string | HTMLElement) {
    // ...
    this.instanceId = `hosted-data-gatherer-instance-${instanceId++}`;

    let el: HTMLElement | null = null;

    if (node instanceof HTMLElement) {
      el = node;
    } else if (typeof node === 'string') {
      el = document.getElementById(node) ?? null;
    } else {
      throw new Error(
        '[HostedPage] invalid value for "node" in options was passed.'
      );
    }

    if (!el) {
      throw new Error(
        '[HostedPage] the specified node could not be found. Make sure the node exists on the page before attempting to activate.'
      );
    }

    this.replacedEl = el;

    this.wrapperEl = document.createElement('div');

    // ...
    this.wrapperEl.setAttribute('id', this.instanceId);

    // ...
    this.wrapperEl.style.width = '100%';
    this.wrapperEl.style.minHeight = '70vh';
    this.wrapperEl.style.display = 'flex';
    this.wrapperEl.style.flexDirection = 'column';
    this.wrapperEl.style.alignItems = 'center';
    this.wrapperEl.style.margin = '40px 0';

    this.iFrameEl = document.createElement('iframe');
    this.iFrameEl.src = HOSTED_PAGE_ENDPOINT;

    this.iFrameEl.style.width = '100%';
    this.iFrameEl.style.display = 'flex';
    this.iFrameEl.style.flexGrow = '1';
    this.iFrameEl.style.margin = '-70px';
  }

  /**
   * ...
   *
   * @param type ...
   * @param data ...
   * @return ...
   */
  sendMessage(type: string, data?: unknown) {
    this.iFrameEl.contentWindow?.postMessage({ type, data: data ?? null }, '*');
  }

  /**
   * ...
   */
  remove() {
    this.wrapperEl.replaceWith(this.replacedEl);
    this.wrapperEl.remove();
  }

  /**
   * ...
   */
  private async mount() {
    return await new Promise<HostedPage>((resolve, reject) => {
      this.iFrameEl.onload = () => resolve(this);
      this.iFrameEl.onerror = (err) => reject(err);

      this.wrapperEl.appendChild(this.iFrameEl);
      this.replacedEl.replaceWith(this.wrapperEl);
    });
  }

  /**
   * ...
   */
  static async spawn(node: string | HTMLElement) {
    return new HostedPage(node).mount();
  }
}
