import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { hot } from 'react-hot-loader';

import { BusyStateCurtain } from '@components/BusyStateCurtain';
import { Header } from '@components/Header';
import { AlertsRoot } from '@features/alerts/AlertsRoot';
import { setConfig } from '@features/config/configSlice';
import { ModalRoot } from '@features/modals/ModalRoot';
import { runSetup } from '@features/setup/setupSlice';
import { setSize } from '@features/size/sizeSlice';
import { ViewContainer } from '@features/view/ViewContainer';
import * as events from '@src/events';
import { RootState } from '@store';
import { resetStore } from '@store/rootActions';
// import { createLogger } from '@utils/logger';

// import { TestDisplay } from '@components/TestDisplay';
// import { EvaluationTest } from '@components/EvaluationTest';

import './App.scoped.scss';

// const logger = createLogger('App');

/** State to props mapper. */
const mapState = (state: RootState) => ({
  isFullscreen: state.settings.fullscreen,
  setupComplete: state.setup.complete,
  activity: state.activity,
  evaluationReady: !!state.evaluation,
  output: state.evaluation?.output ?? null,
  error: state.error,
});

/** Dispatch to props mapper. */
const mapDispatch = { setConfig, runSetup, setSize, resetStore };

/** ... */
const connector = connect(mapState, mapDispatch);

/** ... */
type MappedProps = ConnectedProps<typeof connector>;

/** ... */
type Props = MappedProps & App.Props;

/**
 * ...
 */
const _App = class App extends React.Component<Props> {
  private el: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.el = React.createRef<HTMLDivElement>();

    this.updateSize = this.updateSize.bind(this);
  }

  componentDidMount() {
    // logger.info('componentDidMount');

    // ...
    this.updateSize();
    window.addEventListener('resize', this.updateSize);

    // ...
    this.props.setConfig(this.props.config);

    // ...
    void this.props.runSetup();

    // Register listener for custom "evaluation draft created" event so
    // evaluation draft can be properly exported from app root.
    this.el.current?.addEventListener('evaluationdraftcreated', (event) => {
      event.stopPropagation();

      this.props.onEvaluationDraftExported(event.detail);
    });
  }

  componentDidUpdate(prevProps: Props) {
    // logger.info('componentDidUpdate');

    // Emit "Initialization Error" event.
    if (!prevProps.error && this.props.error) {
      this.props.onInitializationError(new Error(this.props.error.message));
    }

    // Emit "Initialization Completed" event.
    if (!prevProps.setupComplete && this.props.setupComplete) {
      this.props.onInitializationCompleted();
    }

    // Emit "Evaluation Completed" event.
    if (!prevProps.output && this.props.output) {
      this.props.onEvaluationCompleted(this.props.output);
    }
  }

  componentWillUnmount() {
    // logger.info('componentWillUnmount');

    // ...
    window.removeEventListener('resize', this.updateSize);

    // ...
    this.props.resetStore();
    // setTimeout(() => this.props.resetStore());
  }

  /**
   * ...
   */
  private updateSize() {
    if (!this.el.current) return;

    const { width, height } = this.el.current.getBoundingClientRect();

    this.props.setSize(width, height);
  }

  render() {
    const { config, isFullscreen } = this.props;

    const classNames = ['dg-root'];

    if (isFullscreen) {
      classNames.push('fullscreen');
    }

    return (
      <div
        id="mhs-data-gatherer"
        className={classNames.join(' ')}
        data-appearance={config.appearance ?? 'light'}
        ref={this.el}
      >
        {/* Applet Header */}
        {!config.hideHeader && <Header />}
        {/* Applet Content Container */}
        <div className="dg-main">
          {/* Main Content */}
          {/* <ViewContainer /> */}
          <ViewContainer />
          {/* this.props.evaluationReady && <EvaluationTest /> */}
          {/** ... */}
          <BusyStateCurtain />
          {/** ... */}
          <ModalRoot />
          {/** ... */}
          <AlertsRoot />
        </div>
        {/** ... */}
        {/* <TestDisplay /> */}
      </div>
    );
  }
};

/** ... */
export const App = connector(hot(module)(_App));

export namespace App {
  /** Component properties. */
  export interface Props {
    config: DataGatherer.Config;
    onInitializationCompleted: events.ActivationCompletedListener;
    onInitializationError: events.ActivationErrorListener;
    onEvaluationCompleted: events.EvaluationCompletedListener;
    onEvaluationDraftExported: events.EvaluationDraftExportedListener;
  }
}
