import React, { useState } from 'react';
import {
  Container,
  Form,
  FloatingLabel,
  FormControlProps,
  FormCheckProps,
  Row,
  Col,
} from 'react-bootstrap';

import './Field.scss';

/**
 * ...
 */
interface FieldProps {
  label: string;
  description?: string;
  value: string | undefined;
  onChange: (value: string) => void;
  readonly?: boolean;
  required?: boolean;
}

/**
 * ...
 *
 * @param {[type]} props  [description]
 * @return {[type]} [description]
 */
const createFieldConfig = (props: TextField.Props) => {
  const config: FormControlProps = {
    type: 'text',
    value: props.value,
    onChange: ({ target }) => props.onChange(target.value),
    readOnly: !!props.readonly,
    spellCheck: false,
    isInvalid: !props.required ? false : !props.value,
  };

  // if (props.description) {
  //   fieldConfig.des
  // }

  return config;
};

/**
 * ...
 */
export const TextField: React.FC<TextField.Props> = (props) => {
  // ...
  const fieldConfig = createFieldConfig(props);

  // ...
  const label = props.label + (props.required ? '*' : '');

  return (
    <Form.Group className="mb-3">
      <FloatingLabel label={label}>
        <Form.Control {...fieldConfig} placeholder={label} />
        {fieldConfig.isInvalid && (
          <Form.Control.Feedback type="invalid">
            This is a required value. Omitting it may result in errors.
          </Form.Control.Feedback>
        )}
      </FloatingLabel>
      {props.description && <Form.Text>{props.description}</Form.Text>}
    </Form.Group>
  );
};

export namespace TextField {
  /** ... */
  export type Props = FieldProps;
}

/**
 * ...
 */
export const DateField: React.FC<DateField.Props> = (props) => {
  const fieldConfig: FormControlProps = {
    type: 'date',
    value: props.value,
    onChange: ({ target }) => props.onChange(target.value),
  };

  // if (props.description) {
  //   fieldConfig.des
  // }

  return (
    <Form.Group className="mb-3">
      <FloatingLabel label={props.label}>
        <Form.Control {...fieldConfig} />
      </FloatingLabel>
    </Form.Group>
  );
};

export namespace DateField {
  /** ... */
  export type Props = FieldProps;
}

/**
 * ...
 */
export const SelectField: React.FC<SelectField.Props> = (props) => {
  // ...
  const options = props.options.map(({ id, label, value }) => (
    <option key={id} value={value}>
      {label}
    </option>
  ));

  return (
    <Form.Group className="mb-3">
      <FloatingLabel label={props.label}>
        <Form.Select
          value={props.value}
          onChange={({ currentTarget }) => props.onChange(currentTarget.value)}
        >
          {options}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  );
};

export namespace SelectField {
  /** ... */
  export interface Option {
    id: string;
    label: string;
    value: string;
  }

  /** ... */
  export interface Props {
    label: string;
    value: string | undefined;
    options: Option[];
    onChange: (value: string) => void;
  }
}

/**
 * ...
 */
export const ColorField: React.FC<ColorField.Props> = (props) => {
  return (
    <Form.Group className="mb-3">
      <FloatingLabel label={props.label}>
        <Form.Control
          type="color"
          defaultValue="transparent"
          value={props.value}
          onChange={({ target }) => props.onChange(target.value)}
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export namespace ColorField {
  /** ... */
  export interface Props {
    label: string;
    value: string | undefined;
    onChange: (value: string) => void;
  }
}

/**
 * ...
 */
export const ToggleField: React.FC<ToggleField.Props> = (props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Check
        type="checkbox"
        label={props.label}
        checked={props.value}
        onChange={({ target }) => props.onChange(target.checked)}
      />
    </Form.Group>
  );
};

export namespace ToggleField {
  /** ... */
  export interface Props {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
  }
}

/**
 * ...
 */
export const Toggle: React.FC<Toggle.Props> = (props) => {
  const config: FormCheckProps = {
    type: 'switch',
    checked: props.value,
    onChange: ({ target }) => props.onChange(target.checked),
  };

  return <Form.Check {...config} />;
};

export namespace Toggle {
  /** ... */
  export interface Props {
    value: boolean;
    onChange: (value: boolean) => void;
  }
}

/**
 * ...
 */
export const Field = {
  Text: TextField,
  Date: DateField,
  Select: SelectField,
  Color: ColorField,
  Toggle: ToggleField,
};

/**
 * ...
 */
export const FieldGroup: React.FC<FieldGroup.Props> = (props) => {
  // ...
  const disableable = typeof props.disabled === 'boolean';

  return (
    <div className="form-section">
      <Container>
        <Row className="mb-3">
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6 className="m-0">{props.label}</h6>
              </div>
              {disableable && (
                <Toggle value={!props.disabled} onChange={props.onToggle} />
              )}
            </div>
          </Col>
          {disableable && (
            <Col xs={12}>
              <small>{props.description}</small>
            </Col>
          )}
        </Row>
        <Row style={{ opacity: !props.disabled ? 1 : 0.25 }}>
          <Col xs={12}>{props.children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export namespace FieldGroup {
  /** ... */
  export interface Props {
    /** ... */
    label: string;
    /** ... */
    description?: string;
    /** ... */
    disabled?: boolean;
    /** ... */
    onToggle?: (enabled: boolean) => void;
  }
}
