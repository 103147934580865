import { uniqueId } from 'lodash';

import { Evaluation } from './evaluationTypes';

/**
 * Utility class for helping build out an evaluation.
 */
export class EvaluationBuilder {
  #tool: Evaluation['tool'];
  #forms: Evaluation['forms'] = {};
  #fields: Evaluation['fields'] = {};
  #references: Evaluation['references'] = {};
  #models: Evaluation['models'] = {};
  #validations: Evaluation['validations'] = {
    invalid: false,
    dirty: false,
    forms: {},
  };
  /** ... */
  #currentForm!: Evaluation.Form;
  /** ... */
  #currentFormValidations!: Evaluation.Validations.Form;
  /** ... */
  #currentFieldGroup: Evaluation.FieldGroup | null = null;

  /**
   * An evaluation instance created from the provided data.
   */
  private get evaluation() {
    return {
      tool: this.#tool,
      forms: this.#forms,
      fields: this.#fields,
      references: this.#references,
      models: this.#models,
      validations: this.#validations,
      output: null,
    } as Evaluation;
  }

  private constructor(data: DataGatherer.Tool) {
    const { references, forms, ...tool } = data;

    this.#tool = tool;
    this.#references = createDataMap(references);

    for (const form of forms) {
      this.addForm(form);
    }
  }

  /**
   * Build an `Evaluation` instance from `DataGatherer.Tool` data.
   *
   * @param data Tool data that will be used to construct the evaluation.
   * @return An evaluation instance.
   */
  static build(data: DataGatherer.Tool) {
    return new EvaluationBuilder(data).evaluation;
  }

  /**
   * Add a form to the evaluation.
   */
  private addForm({ fields, ...data }: DataGatherer.Form) {
    // console.log('addForm', form.id);

    // ...
    const form = { ...data, fields: [], fieldGroups: [] } as Evaluation.Form;
    // ...
    this.#forms[form.id] = this.#currentForm = form;

    // ...
    const validations = {
      invalid: false,
      dirty: false,
      omitRule: null,
      isWithinOmitThreshold: true,
      areFormFieldsValid: false,
      fields: {},
    } as Evaluation.Validations.Form;

    // ...
    this.#validations.forms[form.id] = this.#currentFormValidations =
      validations;

    // ...

    for (const item of fields) {
      if (item.type === 'field-group') {
        this.addFieldGroup(item);

        continue;
      }

      if (!this.#currentFieldGroup) {
        this.addFieldGroup();
      }

      if (item.type === 'heading') {
        this.addHeading(item);
      } else {
        this.addField(item);
      }
    }

    this.#currentFieldGroup = null;
  }

  /**
   * Add a heading item to the current form.
   */
  private addHeading(data: DataGatherer.Heading) {
    // console.log('add heading', data.label);

    // ...
    const heading = { id: uniqueId(), ...data };
    // ...
    this.#currentForm.fields.push(heading.id);
    // ...
    this.#fields[heading.id] = heading;
  }

  /**
   * Add a field to the current form.
   */
  private addField(
    { subFields, ...data }: DataGatherer.Field,
    parent?: Evaluation.Field
  ) {
    // console.log('add field', data.id);

    // ...
    const field = { formId: this.#currentForm.id, ...data } as Evaluation.Field;

    // ...
    this.#fields[field.id] = field;
    // Add an entry for the field within the evaluation's model data.
    this.#models[field.id] = { value: null, isDirty: false };
    // ...
    this.#currentForm.fields.push(field.id);
    // ...
    this.#currentFormValidations.fields[field.id] = {
      invalid: false,
      dirty: false,
      ommited: false,
    };

    // ...

    if (parent) {
      parent.subFields![field.id] = field;
    } else {
      this.#currentFieldGroup!.fields.push(field.id);
    }

    if (!subFields) return;

    field.subFields = {};

    for (const item of subFields) {
      this.addField(item, field);
    }
  }

  /**
   * Add a field group to the current form.
   */
  private addFieldGroup(data?: DataGatherer.FieldGroup) {
    // ...
    const fieldGroup = { id: uniqueId(), fields: [] } as Evaluation.FieldGroup;
    // ...
    this.#currentForm.fieldGroups.push(fieldGroup);
    // ...
    this.#currentFieldGroup = fieldGroup;

    if (!data) return;

    fieldGroup.heading = data.heading;

    for (const item of data.fields) {
      this.addField(item);
    }

    this.#currentFieldGroup = null;
  }
}

// region Helper Functions

/**
 * ...
 *
 * @param values ...
 * @return ...
 */
function createDataMap<T extends { id: string }>(values: T[]) {
  return Object.fromEntries(values.map((value) => [value.id, value]));
}

// endregion Helper Functions
