import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import { MhsLogo } from '@components/MhsLogo';
import { selectTool } from '@features/evaluation/evaluationSlice';
import { selectTheme } from '@features/theme/themeSlice';
import { useAppSelector } from '@hooks';

import { ClientInfoButton } from './ClientInfoButton';
import { DevDropdownButton } from './DevDropdownButton';
import { FullscreenModeButton } from './FullscreenModeButton';
import { Title } from './Title';

import './Header.scss';
import './Header.scoped.scss';

/**
 * data-gatherer Header component.
 */
export const Header: React.FC = () => {
  // Reference tool data.
  const tool = useAppSelector(selectTool);
  // Reference theme data.
  const theme = useAppSelector(selectTheme);

  // If a custom primary color was provided in the data-gatherer config, use
  // that as the header background color instead of the default.
  const headerColor = theme.primaryColor ?? undefined;
  // ...
  const headerTextColor = theme.primaryTextColor ?? undefined;
  // ...
  const logoColor = headerTextColor ?? 'white';

  // If a custom logo URL was provided in the data-gatherer config, use that
  // instead of the default MHS logo.
  const logo = theme.logoUrl ? (
    <img className="custom-header-logo" src={theme.logoUrl} />
  ) : (
    <MhsLogo color={logoColor} />
  );

  return (
    <div
      className="dg-header"
      style={{ background: headerColor, color: headerTextColor }}
    >
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col className="d-flex justify-content-start">{logo}</Col>
          <Col className="d-flex justify-content-center" xs={6}>
            <Title toolName={tool?.name} />
          </Col>
          <Col className="d-flex justify-content-end">
            {/* Dropdown button for displaying client info (if provided). */}
            <ClientInfoButton />
            {/* Button for toggling fullscreen mode. */}
            {!IS_HOSTED_PAGE_CONTEXT && <FullscreenModeButton />}
            {/* Dropdown button for displaying developer tools. */}
            {IS_DEV && <DevDropdownButton />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
