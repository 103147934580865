import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Configuration } from '@mhs/data-gatherer';

// import { JsonViewer } from '@dev-app/components/JsonViewer';
import { toolSelection } from '@dev-app/utils/tool-selection';

import { Field, FieldGroup } from './Field';
import { ListEditor } from './ListEditor';

import './ConfigEditorModal.scss';

type RenderFunction = React.ForwardRefRenderFunction<
  ConfigEditorModal.Ref,
  ConfigEditorModal.Props
>;

/**
 * ...
 */
const _ConfigEditorModal: RenderFunction = (props, ref) => {
  const { config, onConfigUpdated } = props;

  // ...
  const [show, setShow] = useState(false);

  // ...
  useImperativeHandle(ref, () => ({
    show: () => setShow(true),
  }));

  // ...
  const [toolMeasureId, setToolName] = useState(config.toolMeasureId);
  // ...
  const [subscriptionKey, setSubscriptionKey] = useState(
    config.janusCredentials.subscriptionKey
  );

  // ...
  const [clientId, setClientId] = useState(config.clientDetails?.id);
  // ...
  const [clientFirstName, setClientFirstName] = useState(
    config.clientDetails?.fName
  );
  // ...
  const [clientLastName, setClientLastName] = useState(
    config.clientDetails?.lName
  );
  // ...
  const [clientDob, setClientDob] = useState(config.clientDetails?.dob);
  // ...
  const [clientEthnicity, setClientEthnicity] = useState(
    config.clientDetails?.ethnicity
  );

  // ...
  const [primaryColor, setPrimaryColor] = useState(
    config.styleOptions?.primaryColor
  );
  // ...
  const [secondaryColor, setSecondaryColor] = useState(
    config.styleOptions?.secondaryColor
  );
  // ...
  const [primaryTextColor, setPrimaryTextColor] = useState(
    config.styleOptions?.primaryTextColor
  );

  // ...
  const [logoUrl, setLogoUrl] = useState(config.logoUrl);

  // ...
  const [formData, setFormData] = useState(config.formData ?? []);

  // ...
  const [hiddenForms, setHiddenForms] = useState(config.hiddenForms ?? []);
  // ...
  const [hiddenFields, setHiddenFields] = useState(config.hiddenFields ?? []);

  // ...
  const [customFormLabels, setCustomFormLabels] = useState(
    config.customFormLabels ?? []
  );

  // ...
  const [hideHeader, setHideHeader] = useState(config.hideHeader);

  // ...
  const [includeClientDetails, setIncludeClientDetails] = useState(true);
  // ...
  const [includeStyleOptions, setIncludeStyleOptions] = useState(true);
  // ...
  const [includeFormData, setIncludeFormData] = useState(true);
  // ...
  const [includeHiddenForms, setIncludeHiddenForms] = useState(true);
  // ...
  const [includeHiddenFields, setIncludeHiddenFields] = useState(true);
  // ...
  const [includeCustomFormLabels, setIncludeCustomFormLabels] = useState(true);

  // ...
  const buildConfig = () => {
    // ...
    const updatedConfig = { ...config };

    // ...
    updatedConfig.toolMeasureId = toolMeasureId;

    if (includeClientDetails) {
      // ...
      const clientDetails = { ...(config.clientDetails ?? {}) };
      clientDetails.id = clientId;

      if (clientFirstName) clientDetails.fName = clientFirstName;
      if (clientLastName) clientDetails.lName = clientLastName;
      if (clientDob) clientDetails.dob = clientDob;
      if (clientEthnicity) clientDetails.ethnicity = clientEthnicity;

      updatedConfig.clientDetails = clientDetails;
    } else {
      delete updatedConfig.clientDetails;
    }

    if (includeStyleOptions) {
      // ...
      const styleOptions = { ...(config.styleOptions ?? {}) };

      if (primaryColor) styleOptions.primaryColor = primaryColor;
      if (secondaryColor) styleOptions.secondaryColor = secondaryColor;
      if (primaryTextColor) styleOptions.primaryTextColor = primaryTextColor;

      updatedConfig.styleOptions = styleOptions;
    } else {
      delete updatedConfig.styleOptions;
    }

    updatedConfig.logoUrl = logoUrl || config.logoUrl;

    if (includeFormData) {
      updatedConfig.formData = formData || config.formData;
    } else {
      delete updatedConfig.formData;
    }

    if (includeHiddenForms) {
      updatedConfig.hiddenForms = hiddenForms || config.hiddenForms;
    } else {
      delete updatedConfig.hiddenForms;
    }

    if (includeHiddenFields) {
      updatedConfig.hiddenFields = hiddenFields || config.hiddenFields;
    } else {
      delete updatedConfig.hiddenFields;
    }

    if (includeCustomFormLabels) {
      updatedConfig.customFormLabels =
        customFormLabels || config.customFormLabels;
    } else {
      delete updatedConfig.customFormLabels;
    }

    updatedConfig.hideHeader = !!hideHeader;

    return updatedConfig;
  };

  // ...
  const updateConfig = () => {
    const updatedConfig = buildConfig();

    console.log(updatedConfig);

    onConfigUpdated(updatedConfig);

    setShow(false);
  };

  // ...
  const resetConfig = () => {
    setToolName(config.toolMeasureId);
    setClientId(config.clientDetails?.id);

    updateConfig();
  };

  const fieldConfigs = {
    toolMeasureId: {
      label: 'Tool Name',
      value: toolMeasureId,
      options: toolSelection.options,
      onChange: setToolName,
      required: true,
    },
    subscriptionKey: {
      label: 'Subscription Key',
      value: subscriptionKey,
      onChange: setSubscriptionKey,
      required: true,
    },
    clientId: {
      label: 'ID',
      value: clientId,
      onChange: setClientId,
    },
    clientFirstName: {
      label: 'First Name',
      value: clientFirstName,
      onChange: setClientFirstName,
    },
    clientLastName: {
      label: 'Last Name',
      value: clientLastName,
      onChange: setClientLastName,
    },
    clientDob: {
      label: 'Date of Birth',
      value: clientDob,
      onChange: setClientDob,
    },
    clientEthnicity: {
      label: 'Ethnicity',
      value: clientEthnicity,
      onChange: setClientEthnicity,
    },
    primaryColor: {
      label: 'Primary Color',
      value: primaryColor,
      onChange: setPrimaryColor,
    },
    primaryTextColor: {
      label: 'Primary Text Color',
      value: primaryTextColor,
      onChange: setPrimaryTextColor,
    },
    secondaryColor: {
      label: 'Secondary Color',
      value: secondaryColor,
      onChange: setSecondaryColor,
    },
    logoUrl: {
      label: 'Logo URL',
      value: logoUrl,
      onChange: setLogoUrl,
    },
    hideHeader: {
      label: 'Hide Header',
      value: hideHeader,
      onChange: setHideHeader,
    },
  };

  // ...
  const editorForm = (
    <div className="editor-form">
      {/** ...  */}
      <FieldGroup label="Tool Measure ID" description="...">
        <Field.Select {...fieldConfigs.toolMeasureId} />
      </FieldGroup>
      {/** ...  */}
      {/* <FieldGroup label="JANUS Credentials" description="...">
        <Field.Text {...fieldConfigs.subscriptionKey} />
      </FieldGroup> */}
      {/** ...  */}
      <FieldGroup
        label="Client Details"
        description="..."
        disabled={!includeClientDetails}
        onToggle={setIncludeClientDetails}
      >
        <Field.Text {...fieldConfigs.clientId} />
        <Field.Text {...fieldConfigs.clientFirstName} />
        <Field.Text {...fieldConfigs.clientLastName} />
        <Field.Text {...fieldConfigs.clientDob} />
        <Field.Text {...fieldConfigs.clientEthnicity} />
      </FieldGroup>
      {/** ...  */}
      <FieldGroup
        label="Style & Theme Options"
        description="..."
        disabled={!includeStyleOptions}
        onToggle={setIncludeStyleOptions}
      >
        <Field.Text {...fieldConfigs.primaryColor} />
        <Field.Text {...fieldConfigs.primaryTextColor} />
        <Field.Text {...fieldConfigs.secondaryColor} />
        <Field.Text {...fieldConfigs.logoUrl} />
      </FieldGroup>
      {/** ...  */}
      <FieldGroup
        label="Form Data"
        description="Add pre-set values to evaluation fields."
        disabled={!includeFormData}
        onToggle={setIncludeFormData}
      >
        <ListEditor
          list={formData}
          onChange={setFormData}
          props={[
            { key: 'fieldId', label: 'Field ID' },
            { key: 'value', label: 'Value' },
          ]}
        />
      </FieldGroup>
      {/** ...  */}
      <FieldGroup
        label="Hidden Forms"
        description="List of form IDs who's corresponding forms should be hidden."
        disabled={!includeHiddenForms}
        onToggle={setIncludeHiddenForms}
      >
        <ListEditor list={hiddenForms} onChange={setHiddenForms} />
      </FieldGroup>
      {/** ...  */}
      <FieldGroup
        label="Hidden Fields"
        description="List of field IDs who's corresponding fields should be hidden."
        disabled={!includeHiddenFields}
        onToggle={setIncludeHiddenFields}
      >
        <ListEditor list={hiddenFields} onChange={setHiddenFields} />
      </FieldGroup>
      {/** ...  */}
      <FieldGroup
        label="Custom Form Labels"
        description="List of custom labels that will be applied to to corresponding form."
        disabled={!includeCustomFormLabels}
        onToggle={setIncludeCustomFormLabels}
      >
        <ListEditor
          list={customFormLabels}
          onChange={setCustomFormLabels}
          props={[
            { key: 'formId', label: 'Form ID' },
            { key: 'label', label: 'Label' },
          ]}
        />
      </FieldGroup>
      {/** ...  */}
      <FieldGroup label="Display Options" description="...">
        <Field.Toggle {...fieldConfigs.hideHeader} />
      </FieldGroup>
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-config-editor"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Configuration Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>{editorForm}</Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </div>
        <div>
          {/* <Button className="me-2" variant="danger" onClick={resetConfig}>
            Reset
          </Button> */}
          <Button variant="primary" onClick={updateConfig}>
            Apply
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

/**
 * ...
 */
export const ConfigEditorModal = forwardRef(_ConfigEditorModal);

export namespace ConfigEditorModal {
  /** ... */
  export type OnConfigUpdatedCallback = (config: Configuration) => void;

  /** ... */
  export interface Ref {
    show: () => void;
  }

  /** ... */
  export interface Props {
    /** ... */
    config: Configuration;
    /** ... */
    onConfigUpdated: OnConfigUpdatedCallback;
  }
}
