import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store';

/**
 * ...
 */
export type AppConfig = DataGatherer.Config;

/**
 * ...
 */
export type ConfigState = AppConfig | null;

/**
 * ...
 */
export const configSlice = createSlice({
  name: 'config',
  initialState: null as ConfigState,
  reducers: {
    /** ... */
    setConfig: (_, { payload }: PayloadAction<DataGatherer.Config>) => {
      return { ...payload };
    },
    /** ... */
    clearConfig: () => {
      return null;
    },
  },
});

// region Actions

/**
 * Set config.
 *
 * @param options ...
 */
export function setConfig(options: DataGatherer.Config) {
  return configSlice.actions.setConfig(options);
}

/**
 * Clear config.
 */
export function clearConfig() {
  return configSlice.actions.clearConfig();
}

// endregion Actions

// region Selectors

/** ... */
export const selectClientDetails = (state: RootState) => {
  return state.config?.clientDetails;
};

/** ... */
export const selectCustomFormLabels = (state: RootState) => {
  return state.config?.customFormLabels;
};

/** ... */
export const selectHiddenFieldIds = (state: RootState) => {
  return state.config?.hiddenFields;
};

// endregion Selectors
