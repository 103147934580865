import { getMockData } from '@tools/get-mock-data';
import { isObject } from '@tools/type-gaurds';
import { generateThumbprint } from '@utils/generate-thumbprint';
import { http } from '@utils/http';

import { Evaluation } from './evaluationTypes';

/** ... */
const OBSERVATION_VALUES_DELIMITER = '|';

/**
 * ...
 */
interface BaseRequestOptions {
  apiVersion: string;
  subscriptionKey: string;
}

/**
 * ...
 */
interface GeneralResponse {
  statusCode: number;
  message: string;
}

/**
 * Options for `getToolData` API call.
 */
export type AuthenticateOptions = BaseRequestOptions;

/**
 * Options for `getToolData` API call.
 */
export interface GetToolDataOptions extends BaseRequestOptions {
  toolMeasureId: string;
  toolVersion?: string;
}

/**
 * Options for `submitEvaluation` API call.
 */
export type SubmitEvaluationOptions = BaseRequestOptions &
  Evaluation.Submission;

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function authenticate(options: AuthenticateOptions) {
  // ...
  const url = composeApiEndpoint(options.apiVersion, 'Authenticate');

  // ...
  const headers = composeHeaders(options.subscriptionKey);

  let response: unknown = null;

  try {
    response = await http.get(url, { headers });
  } catch {
    return false;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const statusCode = (response as any)?.ResponseDetails?.Details?.[0]?.Code;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  return (
    typeof statusCode === 'number' && (statusCode <= 200 || statusCode >= 300)
  );
}

/**
 * Get tool data for constructing evaluation. If a valid `toolVersion` is
 * specified, the data returned will corespond to that tool version.
 *
 * @param options ...
 * @return A `Promise` that will resolve to the requested `Tool` data.
 */
export async function getToolData(options: GetToolDataOptions) {
  // TEMP: Test data...
  return await getLocalToolData(options);

  // ...
  const url = composeApiEndpoint(options.apiVersion, 'GetToolData');

  // ...
  const headers = composeHeaders(options.subscriptionKey);

  // ...
  const res = await http.post(url, {}, { headers });

  return processApiResponse(res);
}

/**
 * Submit completed evaluation data for processing and results.
 *
 * @param options ...
 * @return A `Promise` that will resolve once the submission is complete.
 */
export async function submitEvaluation(options: SubmitEvaluationOptions) {
  // ...
  const url = composeApiEndpoint(options.apiVersion, 'Evaluate');
  // ...
  const headers = composeHeaders(options.subscriptionKey);

  // Assemble the final payload.
  const data: DataGatherer.EvaluationRequest = {
    MeasureID: options.measureId,
    EvaluatorID: options.evaluatorId,
    RetentionPolicy: options.retentionPolicy,
    RunAs: {
      Identifier: options.runAs?.identifier ?? '',
      Name: options.runAs?.name ?? '',
      JobCode: options.runAs?.jobCode ?? '',
      ComputeProvider: options.runAs?.computeProvider ?? '',
      StorageProvider: options.runAs?.storageProvider ?? '',
    },
    Directives: {
      KeyValuePairsArray: mapDirectiveEntries(options.directives),
    },
    SourceData: mapSourceData(options.sourceData),
  };

  // ...
  const res = (await http.put(url, data, {
    headers,
  })) as DataGatherer.EvaluationResponse;

  const results: Evaluation.Results = {
    measureId: res.MeasureID,
    evaluatorId: res.EvaluatorID,
    responseDetails: {
      tenantId: res.ResponseDetails.TenantID,
      transactionId: res.ResponseDetails.TransactionID,
      transactionDts: res.ResponseDetails.TransactionDTS,
      details: res.ResponseDetails.Details.map((d) => ({
        code: d.Code,
        description: d.Description,
        referenceLocation: d.ReferenceLocation,
        reference: d.Reference,
      })),
    },
    scales: res.Scales.map((o) => ({
      name: o.Name,
      id: o.ID,
      delimiter: o.Delimiter,
      outcomeItems: o.OutcomeItems,
      observationItems: o.ObservationItems,
      thumbprint: o.Thumbprint,
    })),
  };

  // ...
  const issue = results.responseDetails.details.find(
    ({ code }) => code !== 200
  );

  if (issue) {
    throw new Error(
      `There was a problem with the evaluation: ${issue.description}`
    );
  }

  console.log(results);

  return results;
}

// region Helper Functions

function processApiResponse(res: unknown) {
  // ...
  if (!isObject(res)) {
    throw new Error('Unexpected response.');
  }

  // ...
  if (isGeneralResponse(res)) {
    throw new Error(res.message);
  }

  return res;
}

/**
 * ...
 *
 * @param subscriptionKey ...
 * @param toolMeasureId ...
 * @return ...
 */
async function getLocalToolData(options: GetToolDataOptions) {
  // ...
  const isAuthinticated = await authenticate(options);

  if (!isAuthinticated) {
    throw new Error('Not authinticated.');
  }

  // ...
  return await getMockData.async(options.toolMeasureId);
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isGeneralResponse(value: unknown): value is GeneralResponse {
  return (
    isObject(value) &&
    typeof value.statusCode === 'number' &&
    typeof value.message === 'string'
  );
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function composeApiEndpoint(version: string, route: string) {
  return `${API_DOMAIN}/${version}/${route}`;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function composeHeaders(subscriptionKey: string) {
  const headers: Record<string, string> = {};

  // ...
  headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;

  return headers;
}

/**
 * ...
 *
 * @param directives ...
 * @return ...
 */
function mapDirectiveEntries(directives: Record<string, unknown>) {
  return Object.entries(directives).map(([Key, Value]) => ({ Key, Value }));
}

/**
 * ...
 *
 * @param dataItems ...
 * @return ...
 */
function mapSourceData(dataItems: Evaluation.Submission.FormData[]) {
  const Delimiter = OBSERVATION_VALUES_DELIMITER;

  return dataItems.map((item) => {
    const SourceID = item.sourceId;
    const InterpretAs = item.interpretAs;

    // ...
    const Thumbprint = generateThumbprint(item.interpretAs, item.values);

    // ...
    const Items = item.values
      .map((v) => (v === null ? '' : v.toString()))
      .join(Delimiter);

    const ObservationValues = { Delimiter, Items };

    return {
      SourceID,
      InterpretAs,
      Thumbprint,
      ObservationValues,
    } as DataGatherer.EvaluationRequest.SourceDataItem;
  });
}

// endregion Helper Functions
