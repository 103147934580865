import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import { Overlay } from '@components/Overlay';
import { selectOccuringActions } from '@features/activity/activitySlice';
import { useAppSelector } from '@hooks';

import './BusyStateCurtain.scoped.scss';

/**
 * ...
 */
export const BusyStateCurtain: React.FC = () => {
  // ...
  const actions = useAppSelector((state) => selectOccuringActions(state));

  // ...
  const action = Object.values(actions).find(({ type }) => type === 'primary');

  // ...
  const showCurtain = !!action;

  return (
    <Overlay active={showCurtain} background="#dde1ef">
      <Container>
        <Row>
          <Col className="d-flex justify-content-center aligin-items-center">
            <Spinner animation="border" variant="secondary" />
            <div className="curtain-text h3 mb-0 ms-4">
              {action?.displayText}
            </div>
          </Col>
        </Row>
      </Container>
    </Overlay>
  );
};
