import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from '@app';
import { store } from '@store';
import { delay } from '@tools/delay';
import { isString } from '@tools/type-gaurds';
import { logger } from '@utils/logger';

import { observeElement } from './observe-element';
import { ActivationCompletedListener } from './events';
import { EventManager } from './event-manager';

/**
 * General purpose data-gatherer error type.
 */
export class DataGathererError extends Error {
  constructor(message: string) {
    super(`[data-gatherer] ${message}`);
  }
}

/**
 * Primary interface for the data-gatherer.
 */
class _DataGatherer extends EventManager {
  /** ... */
  #el: HTMLElement | null = null;
  /** ... */
  #activating = false;
  // /** ... */
  // #deactivating = false;

  /**
   * Check to see if the data-gatherer applet is mounted on the page.
   */
  get mounted() {
    return !!this.#el;
  }

  /** ... */
  get activating() {
    return this.#activating;
  }

  constructor() {
    super();

    observeElement(document.body, this.#onDocumentBodyChanged);
  }

  /**
   * Create data-gatherer applet and mount it on the current webpage.
   *
   * @param options Options bag for creating widget.
   * @param cb ...
   */
  activate(options: DataGatherer.Config): Promise<void>;
  activate(options: DataGatherer.Config, cb: ActivationCompletedListener): void;
  activate(options: DataGatherer.Config, cb?: ActivationCompletedListener) {
    // ...
    if (this.#el) {
      return logger.warn(
        'the data-gatherer applet has already been activated. Call "dataGather.deactivate" to deactivate the data-gatherer before trying to reactivate it.'
      );
    }

    if (!options || typeof options !== 'object') {
      throw new DataGathererError(
        'invalid options for "createWidget" were passed.'
      );
    }

    // Check to make sure target node is valid and availible.

    let el: HTMLElement | null = null;

    if (options.node instanceof HTMLElement) {
      el = options.node;
    } else if (isString(options.node)) {
      el = document.getElementById(options.node);
    } else {
      throw new DataGathererError(
        'invalid value for "node" in options was passed.'
      );
    }

    if (!el) {
      return logger.warn(
        'the specified node could not be found. Make sure the node exists on the page before attempting to mount the data-gatherer.'
      );
    }

    this.#el = el;

    // ...
    if (cb) return this.#createAndMountComponent(options, cb);

    // ...
    return new Promise<void>((resolve) => {
      this.#createAndMountComponent(options, resolve);
    });
  }

  /**
   * Remove the data-gatherer applet from the webpage.
   */
  deactivate() {
    // ...
    if (!this.#el) {
      logger.warn(
        'the data-gatherer applet is not currently active. Call "dataGather.activate" before attempting to deactivate.'
      );

      return Promise.resolve();
    }

    // ...
    ReactDOM.unmountComponentAtNode(this.#el);

    this.#el = null;

    // ...
    return delay();
  }

  /**
   * Create data-gatherer React applet and mount it on the DOM.
   *
   * @param options data-gatherer configuration object.
   * @param cb Callback function to be called once the applet is initialized.
   */
  #createAndMountComponent(
    options: DataGatherer.Config,
    cb: ActivationCompletedListener
  ) {
    // ...
    let appEl = (
      <Provider store={store}>
        <App
          config={options}
          onInitializationCompleted={this.#onInitializationCompleted}
          onInitializationError={this.#onInitializationError}
          onEvaluationCompleted={this.#onEvaluationCompleted}
          onEvaluationDraftExported={this.#onEvaluationDraftExported}
        />
      </Provider>
    );

    // If in production mode, user React's "strict" mode.
    if (!IS_DEV) {
      appEl = <React.StrictMode>{appEl}</React.StrictMode>;
    }

    // Render the widget.
    ReactDOM.render(appEl, this.#el);

    // ...
    const onActivationCompleted = () => {
      // ...
      this.#activating = false;

      // ...
      cb();
    };

    this.once('activationCompleted', onActivationCompleted);
  }

  // #createAndMountComponentAsync(
  //   options: Configuration,
  //   cb: ActivationCallback
  // ) {
  //   import('./app').then((app) => {
  //     console.log(app);
  //
  //     app.init(this.#el, {
  //       config: options,
  //       onInitializationCompleted: this.#onInitializationCompleted,
  //       onInitializationError: this.#onInitializationError,
  //       onEvaluationCompleted: this.#onEvaluationCompleted
  //     });
  //
  //     this.once('activationCompleted', cb);
  //   });
  // }

  /** Process `activationComplete` event listeners. */
  #onInitializationCompleted = () => {
    this.processEventListeners('activationComplete');
  };

  /** Process `activationError` event listeners. */
  #onInitializationError = (err: Error) => {
    this.processEventListeners('activationError', err);
  };

  /** Process `evaluationCompleted` event listeners. */
  #onEvaluationCompleted = (results: unknown) => {
    this.processEventListeners('evaluationCompleted', results);
  };

  /** Process `evaluationDraftExported` event listeners. */
  #onEvaluationDraftExported = (results: unknown) => {
    this.processEventListeners('evaluationDraftExported', results);
  };

  /**
   * ...
   */
  #onDocumentBodyChanged = () => {
    if (this.#el && !document.getElementById('mhs-data-gatherer')) {
      logger.warn(
        "the data-gatherer's root node is no longer detected on the DOM. The data-gatherer will automaticaly unmount."
      );

      void this.deactivate();
    }
  };
}

/** Data Gather instance. */
export const dataGatherer = new _DataGatherer();

// declare global {
//   interface Window {
//     dataGatherer: DataGatherer;
//   }
// }
//
// if (window.dataGatherer instanceof _DataGatherer) {
//   // If the data-gatherer has already been initalized in some way, and this is
//   // a production enviorment, throw an error.
//   if (!IS_DEV) {
//     throw new DataGathererError(
//       'an instance of the data-gatherer has already been initialized.'
//     );
//   }
//
//   void window.dataGatherer.deactivate();
// }
//
//
// window.dataGatherer = dataGatherer;

export default dataGatherer;
