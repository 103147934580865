import { uniqueId } from 'lodash';

import { DATA_KEYS } from '@tools/get-mock-data';

/** ... */
const options = Object.entries(DATA_KEYS).map(([key, value]) => ({
  id: uniqueId(),
  label: value.label,
  value: key,
}));

/**
 * ...
 */
class ToolSelectionManager {
  readonly options = options;

  /**
   * ...
   *
   * @param value ...
   */
  save(value: string) {
    localStorage.setItem('selectedTool', value);
  }

  /**
   * ...
   *
   * @return ...
   */
  getLast() {
    // ...
    const cachedValue = localStorage.getItem('selectedTool') as unknown;

    //
    const option = options.find((value) => value === cachedValue) ?? options[0];

    return option.value;
  }
}

/**
 * ...
 */
export const toolSelection = new ToolSelectionManager();
