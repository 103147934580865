import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * ...
 */
export interface SettingsState {
  fullscreen: boolean;
}

/**
 * ...
 */
export interface SetFullscreenModeOptions {
  enable: boolean;
}

const initialState: SettingsState = {
  fullscreen: false
};

/**
 * ...
 */
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /** ... */
    setFullscreenMode: (
      state,
      { payload }: PayloadAction<SetFullscreenModeOptions>
    ) => {
      state.fullscreen = payload.enable;
    }
  }
});

// region Actions

/**
 * Set "Fullscreen Mode" for the Data Gatherer .
 *
 * @param enable Whether or not to enable the mode.
 */
export function setFullscreenMode(enable: boolean) {
  return settingsSlice.actions.setFullscreenMode({ enable });
}

// endregion Actions
