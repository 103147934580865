import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import './CompletedView.scoped.scss';

/**
 * ...
 */
export const CompletedView: React.FC = () => {
  return (
    <div className="completed-view">
      <div className="text-center h2 mb-5">
        <FontAwesomeIcon className="me-3" icon={faCheck} />
        <span>Evaluation Completed</span>
      </div>
      <div className="text-center h6 mb-5">
        <span>
          You successfully completed this evaluation form. Your data has been
          compiled and is ready to be processed.
        </span>
      </div>
    </div>
  );
};
