/**
 * Attempt to parse a string using `JSON.parse`. In the event of an error,
 * a value of `null` will be returned.
 *
 * @param value String to parse.
 * @return The resulting value if parsed successfully, otherwise `null`.
 */
export function safeParse(value: string) {
  try {
    return JSON.parse(value) as unknown;
  } catch {
    return null;
  }
}
