import React, { useState } from 'react';
import { Button, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import './ListEditor.scss';

interface Property {
  key: string;
  label: string;
}

/**
 * ...
 */
export const ListEditor: React.FC<ListEditor.Props> = ({
  list,
  props,
  onChange,
}) => {
  // ...
  const [items, setItems] = useState([...list]);
  // ...
  const [entry, setEntry] = useState(createAddFieldEntryValue(props));

  // ...
  const canAdd = !props ? !!entry : props.every(({ key }) => !!entry[key]);

  // ...
  const addItem = (item: unknown) => {
    const updatedList = [...list, item];

    setItems(updatedList);
    setEntry(createAddFieldEntryValue(props));

    onChange(updatedList);
  };

  // ...
  const removeItem = (index: number) => {
    const updatedList = [...items];
    updatedList.splice(index, 1);

    setItems(updatedList);

    onChange(updatedList);
  };

  // ...
  const generateListItemTitle = (item: unknown) => {
    return props.map(({ key, label }) => `${label}: ${item[key]}`).join('; \n');
  };

  // ...
  const listItems = items.map((item, i) => (
    <ListGroup.Item
      className="d-flex justify-content-center align-items-strech"
      key={i}
    >
      <span className="me-2">
        <strong>{i + 1}.</strong>
      </span>
      <span className="list-item-content">
        <div>
          {!props ? (
            <span>{item}</span>
          ) : (
            <span title={generateListItemTitle(item)}>
              {props.map(({ key, label }) => (
                <span className="mx-1" key={key}>
                  {label}: {item[key]}
                </span>
              ))}
            </span>
          )}
        </div>
      </span>
      <Button variant="outline-danger" size="sm" onClick={() => removeItem(i)}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </ListGroup.Item>
  ));

  return (
    <div className="list-editor">
      {listItems.length ? (
        <ListGroup className="mb-3">{listItems}</ListGroup>
      ) : (
        <div className="h6 text-center text-muted mb-3">No items</div>
      )}
      <InputGroup>
        {createAddItemFieldInputs(entry, setEntry, props)}
        <Button
          variant="outline-primary"
          disabled={!canAdd}
          onClick={() => addItem(entry)}
        >
          Add
        </Button>
      </InputGroup>
    </div>
  );
};

export namespace ListEditor {
  /** ... */
  export interface Props {
    /** ... */
    list: unknown[];
    /** ... */
    props?: Property[];
    /** ... */
    onChange: (list: unknown[]) => void;
  }
}

// region Helper Functions

/**
 * ...
 *
 * @param props ...
 * @return ...
 */
const createAddFieldEntryValue = (props?: Property[] | null) =>
  !props
    ? ''
    : Object.fromEntries(Object.values(props).map(({ key }) => [key, '']));

/**
 * ...
 *
 * @param entry ...
 * @param setEntry ...
 * @return ...
 */
function createAddItemFieldInputs(
  entry: unknown,
  setEntry: (value: unknown) => void,
  props?: Property[] | null
) {
  if (!props) {
    return (
      <Form.Control
        type="text"
        placeholder="Value"
        value={entry}
        onChange={({ target }) => setEntry(target.value)}
      />
    );
  }

  return props.map(({ key, label }) => (
    <Form.Control
      type="text"
      key={key}
      placeholder={label}
      value={entry[key]}
      onChange={({ target }) => setEntry({ ...entry, [key]: target.value })}
    />
  ));
}

// endregion Helper Functions
