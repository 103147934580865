import { combineReducers } from '@reduxjs/toolkit';

import { activitySlice } from '@features/activity/activitySlice';
import { alertsSlice } from '@features/alerts/alertsSlice';
import { configSlice } from '@features/config/configSlice';
import { errorSlice } from '@features/error/errorSlice';
import { evaluationSlice } from '@features/evaluation/evaluationSlice';
import { modalsSlice } from '@features/modals/modalsSlice';
import { settingsSlice } from '@features/settings/settingsSlice';
import { setupSlice } from '@features/setup/setupSlice';
import { themeSlice } from '@features/theme/themeSlice';
import { sizeSlice } from '@features/size/sizeSlice';
import { testSlice } from '@features/test/testSlice';
import { viewSlice } from '@features/view/viewSlice';

/** ... */
const appReducer = combineReducers({
  activity: activitySlice.reducer,
  alerts: alertsSlice.reducer,
  config: configSlice.reducer,
  error: errorSlice.reducer,
  evaluation: evaluationSlice.reducer,
  modals: modalsSlice.reducer,
  settings: settingsSlice.reducer,
  setup: setupSlice.reducer,
  theme: themeSlice.reducer,
  size: sizeSlice.reducer,
  test: testSlice.reducer,
  view: viewSlice.reducer
});

/** ... */
export const rootReducer: typeof appReducer = (state, action) => {
  return appReducer(action.type === 'resetStore' ? undefined : state, action);
};
