import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import { logger } from 'redux-logger';

import { rootReducer } from './rootReducer';

/**
 * App store instance.
 */
export const store = configureStore({
  reducer: rootReducer,
  devTools: IS_DEV,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();

    // middleware.concat(logger)

    return middleware;
  },
});

/** ... */
export type RootState = ReturnType<typeof store.getState>;

/** ... */
export type AppDispatch = typeof store.dispatch;

/** ... */
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
