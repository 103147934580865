import React, { useRef, useState, useEffect } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

/**
 * General DateInput field select option.
 */
interface SelectOption {
  text: string;
  value: number;
}

/** ... */
const MONTH_OPTIONS: SelectOption[] = [
  { text: 'January', value: 1 },
  { text: 'February', value: 2 },
  { text: 'March', value: 3 },
  { text: 'April', value: 4 },
  { text: 'May', value: 5 },
  { text: 'June', value: 6 },
  { text: 'July', value: 7 },
  { text: 'August', value: 8 },
  { text: 'September', value: 9 },
  { text: 'October', value: 10 },
  { text: 'November', value: 11 },
  { text: 'December', value: 12 },
];

/** ... */
const DAY_OPTIONS = generateNumberOptions(31);

/**
 * ...
 */
const SelectField: React.FC<SelectField.Props> = ({
  className,
  label,
  value,
  options,
  onChange,
}) => {
  return (
    <FloatingLabel className={className} label={label}>
      <Form.Select
        placeholder={label}
        value={value ?? ''}
        isValid={!!value}
        onChange={({ currentTarget }) =>
          onChange(
            currentTarget.value === '' ? null : parseInt(currentTarget.value)
          )
        }
      >
        <option key="undefined" value={''}>
          --
        </option>

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </Form.Select>
    </FloatingLabel>
  );
};

namespace SelectField {
  /** ... */
  export interface Props {
    className?: string;
    label: string;
    value: number | null;
    options: SelectOption[];
    onChange: (value: number | null) => void;
  }
}

/**
 * ...
 */
export const DateInput: React.FC<DateInput.Props> = ({ value, onUpdated }) => {
  const prevValueRef = useRef(value);

  // ...
  const parsedDate = parseDateValuesFromString(value);

  // ...
  const [dayValue, setDayValue] = useState(parsedDate.day);
  // ...
  const [monthValue, setMonthValue] = useState(parsedDate.month);
  // ...
  const [yearValue, setYearValue] = useState(parsedDate.year);
  // ...
  const [dayOptions, setDayOptions] = useState(DAY_OPTIONS);
  // ...
  const [yearOptions] = useState(generateYearOptions());

  // ...
  const generateOutputValue = () => {
    return monthValue && yearValue && dayValue
      ? new Date(`${monthValue}-${dayValue}-${yearValue}`).toISOString()
      : '';
  };

  // ...
  const onInputUpdate = () => {
    if (monthValue) {
      setDayOptions(generateDayOptions(monthValue, yearValue));
    }

    const dateValue = generateOutputValue();

    // console.log('date', new Date(dateValue));

    if (dateValue !== prevValueRef.current) {
      prevValueRef.current = dateValue;

      onUpdated(dateValue);
    }
  };

  // ...
  useEffect(() => {
    if (prevValueRef.current === value) return;

    prevValueRef.current = value;

    const parsedDate = parseDateValuesFromString(value);

    setDayValue(parsedDate.day);
    setMonthValue(parsedDate.month);
    setYearValue(parsedDate.year);
  }, [value]);

  useEffect(onInputUpdate, [dayValue]);

  useEffect(onInputUpdate, [monthValue]);

  useEffect(onInputUpdate, [yearValue]);

  return (
    <>
      <SelectField
        className="me-2"
        label="Month"
        value={monthValue}
        options={MONTH_OPTIONS}
        onChange={setMonthValue}
      />

      <SelectField
        className="me-2"
        label="Day"
        value={dayValue}
        options={dayOptions}
        onChange={setDayValue}
      />

      <SelectField
        label="Year"
        value={yearValue}
        options={yearOptions}
        onChange={setYearValue}
      />
    </>
  );
};

export namespace DateInput {
  /** ... */
  export interface Props {
    value: string;
    onUpdated: (value: string) => void;
  }
}

// region Helper Functions

/**
 * ...
 *
 * @param year ...
 * @return ...
 */
function isALeapYear(year: number) {
  return new Date(year, 1, 29).getMonth() === 1;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function parseDateValuesFromString(value?: string | null) {
  if (!value) return { month: null, day: null, year: null };

  // ...
  const date = new Date(value);

  // ...
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return { month, day, year };
}

/**
 * ...
 *
 * @param count ...
 * @return ...
 */
function generateNumberOptions(count: number, from = 1) {
  const options: SelectOption[] = [];

  for (let n = from; n < from + count; n++) {
    options.push({ text: n.toString(), value: n });
  }

  return options;
}

/**
 * Generate a list of year values starting from the current year and stretching
 * back some ammount of time.
 *
 * @param limit The number of year values that should be generated.
 * @return An array of year values.
 */
function generateYearOptions(limit = 100) {
  // Make this year, and the 100 years before it available in the year <select>
  return generateNumberOptions(
    limit,
    1 + new Date().getFullYear() - limit
  ).reverse();
}

/**
 * ...
 *
 * @param month ...
 * @param year ...
 * @return ...
 */
function generateDayOptions(month: number, year?: number | null) {
  // Create variable to hold new number of days to inject.
  let numberOfDays: number;

  if (month === 2) {
    // If month is February, calculate whether it is a leap year or not.
    numberOfDays = !year ? 28 : isALeapYear(year) ? 29 : 28;
  } else if (
    month === 4 || // April
    month === 6 || // June
    month === 9 || // September
    month === 11 // November
  ) {
    numberOfDays = 30;
  } else {
    numberOfDays = 31;
  }

  return generateNumberOptions(numberOfDays);
}

// endregion Helper Functions
