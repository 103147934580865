import React, { useRef, useEffect } from 'react';
import { languages, highlightAllUnder } from 'prismjs';

import { isString } from '@tools/type-gaurds';

import './JsonView.scss';

languages.json = {
  comment: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
  property: { pattern: /"(?:\\.|[^\\"\r\n])*"(?=\s*:)/, greedy: !0 },
  string: { pattern: /"(?:\\.|[^\\"\r\n])*"(?!\s*:)/, greedy: !0 },
  number: /-?\d+\.?\d*(e[+-]?\d+)?/i,
  punctuation: /[{}[\],]/,
  operator: /:/,
  boolean: /\b(?:true|false)\b/,
  null: /\bnull\b/,
};

/** ... */
export const JsonView: React.FC<JsonView.Props> = ({ source }) => {
  // ...
  const preRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    if (preRef.current) highlightAllUnder(preRef.current);

    // if (preRef.current) {
    //   preRef.current.select();
    // }

    // ...
    let lastClickedNode: Node | null = null;

    // ...
    const onWindowClick = (event: MouseEvent) => {
      lastClickedNode = (event.srcElement as Node) ?? null;
    };

    // ...
    const onWindowKeydown = (event: KeyboardEvent) => {
      if (!(lastClickedNode && event.code === 'KeyA' && event.metaKey)) return;

      // ...
      event.preventDefault();

      selectNodeText(lastClickedNode);
    };

    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeydown);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeydown);
    };
  });

  const value = isString(source) ? source : JSON.stringify(source, null, 2);

  return (
    <pre className="json-view" ref={preRef}>
      <code className="language-json">{value}</code>
    </pre>
  );
};

export namespace JsonView {
  /** ... */
  export interface Props {
    /** ... */
    source: unknown;
  }
}

// region Helper Functions

/**
 * ...
 *
 * @param node ...
 */
function selectNodeText(node: Node) {
  const selection = window.getSelection?.();

  if (!selection) return;

  const range = document.createRange();
  range.selectNodeContents(node);

  selection.removeAllRanges();
  selection.addRange(range);
}

// endregion Helper Functions
