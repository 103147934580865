import { Evaluation } from './evaluationTypes';
import { find } from 'lodash';

/** ... */
const DEFAULT_ROOT_VALIDATION: Evaluation.Validations = {
  invalid: false,
  dirty: false,
  forms: {},
};

/** ... */
const DEFAULT_FORM_VALIDATION: Evaluation.Validations.Form = {
  invalid: false,
  dirty: false,
  omitRule: null,
  isWithinOmitThreshold: true,
  areFormFieldsValid: true,
  fields: {},
};

/** ... */
const DEFAULT_FIELD_VALIDATION: Evaluation.Validations.Field = {
  invalid: false,
  dirty: false,
  ommited: false,
};

/**
 * ...
 */
class EvaluationValidationHelper {
  /**
   * ...
   *
   * @param forms
   * @param fields
   * @return
   */
  create(forms: Evaluation['forms'], fields: Evaluation['fields']) {
    // ...
    const rootVals = createRootValidation();

    for (const form of Object.values(forms)) {
      // ...
      const formVals = createFormValidation();

      // ...
      formVals.omitRule = find(form.rules, {
        type: 'Omit',
      }) as DataGatherer.Form.OmitRule;

      // ...
      for (const fieldId of form.fields) {
        // ...
        const field = fields[fieldId];

        // TODO: an undefined field here is possible in the event it was
        // filtered out due to being hidden. Should probably handle this a
        // little more explicitly.
        if (!field || field.type === 'heading') continue;

        // ...
        const items = [field].concat(Object.values(field.subFields ?? []));

        // ...
        for (const item of items) {
          formVals.fields[item.id] = createFieldValidation();
        }
      }

      rootVals.forms[form.id] = formVals;
    }

    return rootVals;
  }

  /**
   * ...
   *
   * @param rootVals ...
   * @param fields ...
   * @param models ...
   * @param updatedFieldIds ...
   * @return ...
   */
  update(
    rootVals: Evaluation.Validations,
    fields: Evaluation['fields'],
    models: Evaluation['models'],
    updatedModelIds?: string[]
  ) {
    // ...
    const copyVals = createRootValidation(rootVals);
    // const copyVals = rootVals;

    // ...
    updatedModelIds = updatedModelIds ?? Object.keys(models);

    // ...
    // const formValsToCheck: Evaluation.Validations.Form[] = [];
    const formValsToCheck: string[] = [];

    // ...
    for (const modelId of updatedModelIds) {
      // ...
      const field = fields[modelId];

      // TODO: Like in the `create` function, a missing filed is possible if it
      // has been hidden. This should be implemented better as well.
      if (!field) continue;

      // If the "field" is actualy a heading type, consider it valid.
      if (field.type === 'heading') {
        throw new Error(
          '[updateValidations] the list of updated field IDs contianed an ID for a "heading".'
        );
      }

      // ...
      const { value } = models[modelId];

      // ...
      const fieldVals = createFieldValidation();

      // ...
      const v = getFieldValidators(field, copyVals);

      // ...
      fieldVals.dirty = true;
      // ...
      fieldVals.invalid = isFieldInvalid(field, value);
      // ...
      fieldVals.ommited = isFieldOmmited(
        v.form.omitRule,
        value,
        field.ignoreOmit
      );

      // ...
      if (compareFieldValidations(fieldVals, v.field)) continue;

      // ...
      v.form.fields[modelId] = fieldVals;

      // ...
      formValsToCheck.push(field.formId);
    }

    // ...
    if (!formValsToCheck.length) return copyVals;

    // ...
    for (const formId of formValsToCheck) {
      // ...
      const formValsCur = copyVals.forms[formId];

      // ...
      const formVals = createFormValidation(copyVals.forms[formId]);
      // ...
      formVals.areFormFieldsValid = true;
      // ...
      formVals.dirty = false;

      // ...
      const omittedFieldVals: Record<string, Evaluation.Validations.Field> = {};

      for (const [id, fieldVals] of Object.entries(formValsCur.fields)) {
        // ...
        if (fieldVals.invalid) formVals.areFormFieldsValid = false;
        // ...
        if (fieldVals.dirty) formVals.dirty = true;
        // ...
        if (fieldVals.ommited) omittedFieldVals[id] = fieldVals;
      }

      // ...
      formVals.isWithinOmitThreshold = isFormWithinOmitThreshold(
        formVals.omitRule,
        Object.entries(omittedFieldVals).length
      );

      // ...
      formVals.invalid =
        !formVals.areFormFieldsValid || !formVals.isWithinOmitThreshold;

      // ...
      if (compareFormValidations(formVals, formValsCur)) continue;

      // ...
      copyVals.forms[formId] = formVals;
    }

    // ...
    copyVals.invalid = false;
    copyVals.dirty = false;

    for (const formVals of Object.values(copyVals.forms)) {
      // ...
      if (formVals.invalid) copyVals.invalid = true;
      // ...
      if (formVals.dirty) copyVals.dirty = true;
    }

    return copyVals;
  }

  /**
   * ...
   *
   * @param target ...
   * @param source ...
   * @return
   */
  set(target: Evaluation.Validations, source: Evaluation.Validations) {
    target.dirty = source.dirty;
    target.invalid = source.invalid;

    for (const formId in source.forms) {
      const srcForm = source.forms[formId];
      const targForm = target.forms[formId];

      if (!compareFormValidations(targForm, srcForm)) {
        target.forms[formId] = srcForm;

        continue;
      }

      for (const fieldId in srcForm.fields) {
        const srcFields = srcForm.fields[fieldId];
        const targFields = targForm.fields[fieldId];

        if (!compareFieldValidations(targFields, srcFields)) {
          targForm.fields[fieldId] = srcFields;
        }
      }
    }

    return target;
  }
}

/**
 * ...
 */
export const validations = new EvaluationValidationHelper();

/**
 * ...
 *
 * @param field ...
 * @param value ...
 * @return ...
 */
function isFieldInvalid(field: Evaluation.Field, value: unknown) {
  // ...
  if (value === null || value === '') return field.required;
  // ...
  if (field.answers) return !find(field.answers ?? [], { value });
  // ...
  return false;
}

/**
 * ...
 *
 * @param omitRule ...
 * @param value ...
 * @return ...
 */
function isFieldOmmited(
  omitRule: DataGatherer.Form.OmitRule | null,
  value: unknown,
  ignoreOmit: boolean | null
) {
  if (ignoreOmit) return;
  return !!omitRule && value === omitRule.omitValue;
}

/**
 * ...
 *
 * @param omitRule ...
 * @param count ...
 * @return ...
 */
function isFormWithinOmitThreshold(
  omitRule: DataGatherer.Form.OmitRule | null,
  count: number
) {
  return !omitRule || count <= omitRule.amount;
}

/**
 * ...
 *
 * @param v ...
 * @return ....
 */
function copyValidations<T extends Evaluation.Validations.State>(v: T) {
  return JSON.parse(JSON.stringify(v)) as T;
}

/**
 * ...
 *
 * @param ref ...
 * @return
 */
function createRootValidation(ref = DEFAULT_ROOT_VALIDATION) {
  return copyValidations(ref);
}

/**
 * ...
 *
 * @param ref ...
 * @return
 */
function createFormValidation(ref = DEFAULT_FORM_VALIDATION) {
  return copyValidations(ref);
}

/**
 * ...
 *
 * @param ref ...
 * @return
 */
function createFieldValidation(ref = DEFAULT_FIELD_VALIDATION) {
  return copyValidations(ref);
}

/**
 * ...
 *
 * @param item
 * @param validations
 * @return
 */
function getFieldValidators(
  item: Evaluation.Field,
  validations: Evaluation.Validations
) {
  const form = validations.forms[item.formId];
  const field = form.fields[item.id];

  return { form, field };
}

/**
 * ...
 *
 * @param a ...
 * @param b ...
 * @return ...
 */
function compareFormValidations(
  a: Evaluation.Validations.Form,
  b: Evaluation.Validations.Form
) {
  return (
    a.dirty === b.dirty &&
    a.invalid === b.invalid &&
    a.areFormFieldsValid === b.areFormFieldsValid &&
    a.isWithinOmitThreshold === b.isWithinOmitThreshold
  );
}

/**
 * ...
 *
 * @param a ...
 * @param b ...
 * @return ...
 */
function compareFieldValidations(
  a: Evaluation.Validations.Field,
  b: Evaluation.Validations.Field
) {
  return (
    a.dirty === b.dirty && a.invalid === b.invalid && a.ommited === b.ommited
  );
}
