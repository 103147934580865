import React, { useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Nav, Button, Container, Col, Row } from 'react-bootstrap';

import { createAlert, AlertType } from '@features/alerts/alertsSlice';
import { setView } from '@features/view/viewSlice';
import { useAppDispatch } from '@hooks';
import { RootState } from '@store';
import { safeParse } from '@tools/safe-parse';
import { isString, isNumber, isObject } from '@tools/type-gaurds';
import { logger as _logger } from '@utils/logger';

import { EvaluationForm } from './EvaluationForm';
import { EvaluationTab } from './EvaluationTab';
import * as slice from './evaluationSlice';
import { Evaluation } from './evaluationTypes';
import { validations } from './validations';

import './EvaluationDisplay.scoped.scss';

declare global {
  interface HTMLElementEventMap {
    evaluationdraftcreated: CustomEvent<Evaluation.Output>;
  }
}

const logger = _logger.createChild('EvaluationDisplay');

/** State to props mapper. */
const mapState = ({ config, evaluation }: RootState) => {
  // This component should not be getting instantiated until AFTER an evaluation
  // has been created. If it is, throw an error here.
  if (!evaluation) {
    throw new Error(
      '[EvaluationDisplay] an evaluation has not yet been created.'
    );
  }

  return {
    evaluation,
    autoSave: config?.enableLocalCacheing ?? false,
    hiddenForms: config?.hiddenForms ?? [],
    hiddenFields: config?.hiddenFields ?? [],
    draftFeatureEnabled: !!config?.enableExportDraftFeature,
  };
};

/** Dispatch to props mapper. */
const mapDispatch = {
  createAlert,
  setView,
  setInputValue: slice.setInputValue,
  setAllInputValues: slice.setAllInputValues,
};

/** ... */
const connector = connect(mapState, mapDispatch);

/** ... */
type MappedProps = ConnectedProps<typeof connector>;

/** ... */
type Props = MappedProps;

/**
 * ...
 */
interface State {
  activeFormId: string;
  validations: Evaluation.Validations;
  autoSaveIntervalId: number | null;
  visibleForms: Evaluation['forms'];
  visibleFields: Evaluation['fields'];
  filteredForms: Evaluation.Form[];
}

/** ... */
const ExportDraftButton: React.FC = () => {
  // ...
  const dispatch = useAppDispatch();
  // ...
  const button = useRef<HTMLButtonElement>(null);

  // ...
  const exportDraft = async () => {
    const { meta, payload } = await dispatch(slice.buildEvaluationDraft());

    if (meta.requestStatus !== 'fulfilled') return;

    button.current?.dispatchEvent(
      new CustomEvent('evaluationdraftcreated', {
        detail: payload,
        bubbles: true,
      })
    );
  };

  return (
    <Button className="me-2" ref={button} onClick={exportDraft}>
      Export Draft
    </Button>
  );
};

/** ... */
const DoneButton: React.FC<DoneButton.Props> = ({ disabled }) => {
  // ...
  const dispatch = useAppDispatch();

  // ...
  const finalize = async () => {
    const { meta } = await dispatch(slice.buildEvaluationOutput());

    if (meta.requestStatus === 'fulfilled') {
      dispatch(setView('completed'));
    }
  };

  return (
    <Button disabled={disabled} onClick={finalize}>
      Done
    </Button>
  );
};

namespace DoneButton {
  /** ... */
  export interface Props {
    disabled: boolean;
  }
}

/**
 * Base component for data gatherer's evaluation system.
 */
const _EvaluationDisplay = class EvaluationDisplay extends React.Component<
  Props,
  State
> {
  private displayEl: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.displayEl = React.createRef<HTMLDivElement>();

    const visibleForms: Record<string, Evaluation.Form> = {};

    for (const [key, value] of Object.entries(this.forms)) {
      if (!props.hiddenForms.find((formId) => formId === value.id)) {
        visibleForms[key] = value;
      }
    }

    const visibleFields: Record<string, Evaluation.Field> = {};

    for (const [key, value] of Object.entries(this.fields)) {
      if (
        value.type !== 'heading' &&
        value.formId in visibleForms &&
        !props.hiddenFields.find((fieldId) => fieldId === value.id)
      ) {
        visibleFields[key] = value;
      }
    }

    // ...
    // const filteredForms = Object.values(this.forms).filter(
    //   (form) => !props.hiddenForms.find((formId) => formId === form.id)
    // );
    const filteredForms = Object.values(visibleForms);

    const activeFormId = filteredForms[0].id;

    this.state = {
      activeFormId,
      validations: validations.create(visibleForms, visibleFields),
      autoSaveIntervalId: null,
      visibleForms,
      visibleFields,
      filteredForms,
    };

    this.saveEvaluation = this.saveEvaluation.bind(this);
    this.updateValidations = this.updateValidations.bind(this);
  }

  /** ... */
  get el() {
    return this.displayEl?.current;
  }

  /** ... */
  get tool() {
    return this.props.evaluation.tool;
  }

  /** ... */
  get forms() {
    return this.props.evaluation.forms;
  }

  /** ... */
  get fields() {
    return this.props.evaluation.fields;
  }

  /** ... */
  get models() {
    return this.props.evaluation.models;
  }

  /**
   * Dynamic, unique identifier that reprents the current evaluation's type and
   * version of `Tool`.
   */
  get identifier() {
    return `${this.tool.id}-${this.tool.version}`;
  }

  /** ... */
  get localCacheLookupKey() {
    return `dataGather.evaluationData.${this.identifier}`;
  }

  /** ... */
  get activeForm() {
    return this.forms[this.state.activeFormId];
  }

  componentDidMount() {
    // ...
    this.updateValidations();

    logger.info(`auto save enabled: ${this.props.autoSave ? 'true' : 'false'}`);

    if (this.props.autoSave) {
      // ...
      const evaluationData = safeParse(
        localStorage.getItem(this.localCacheLookupKey) ?? ''
      );

      if (isEvaluationInputData(evaluationData)) {
        this.setEvaluationInputData(evaluationData);
      }

      // ...
      this.setState({
        autoSaveIntervalId: window.setInterval(this.saveEvaluation, 5000),
      });
    }

    // setInterval(() => {
    //   const val = this.state.validations;
    //   const form = val.forms['cb9f6923-ba70-4d26-896b-6ed627ee3217'];
    //   const field = form.fields['HHN0d5nu'];
    //
    //   // form.invalid = !form.invalid;
    //
    //   // val.forms['cb9f6923-ba70-4d26-896b-6ed627ee3217'] = { ...form };
    //   form.fields['HHN0d5nu'] = { ...field };
    //
    //   this.setState({ validations: val });
    // }, 1000);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // ...
    if (prevProps.evaluation.models !== this.props.evaluation.models) {
      this.onModelsUpdated(prevProps.evaluation.models);
    }

    // ...
    if (this.props.autoSave) {
      this.saveEvaluation();
    }

    if (this.el && prevState.activeFormId !== this.state.activeFormId) {
      this.el.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    // ...
    if (typeof this.state.autoSaveIntervalId === 'number') {
      clearInterval(this.state.autoSaveIntervalId);
    }
  }

  /**
   * ...
   */
  private setEvaluationInputData(data: Record<string, Evaluation.Model>) {
    try {
      this.props.setAllInputValues(data);
    } catch {
      return logger.warn(
        'could not set evaluation data from cache. The retrieved data was most likley invalid.'
      );
    }

    // ...
    this.alert('Existing evaluation data found.', 'success');
  }

  /**
   * Helper method for generating alerts.
   *
   * @param text Alert text.
   * @param type Alert type.
   */
  private alert(text: string, type: AlertType = 'info') {
    this.props.createAlert({ type, text });
  }

  /**
   * Save evaluation to local storage for future retrieval.
   */
  private saveEvaluation() {
    if (!this.models) {
      return logger.warn(
        'unable to save evaluation due to invalid model data.'
      );
    }

    // ...
    localStorage.setItem(this.localCacheLookupKey, JSON.stringify(this.models));
  }

  /**
   * ...
   */
  private onModelsUpdated(prev: Evaluation['models']) {
    // ...
    const modelIds = Object.entries(this.props.evaluation.models)
      .filter(([key, value]) => value.value !== prev[key].value)
      .map(([key]) => key);

    logger.info(`the following fields were updated: ${modelIds.join(', ')}`);

    // ...

    if (modelIds.length) {
      this.updateValidations(modelIds);
    } else {
      this.updateValidations();
    }
  }

  /**
   * ...
   *
   * @param modelIds ...
   */
  private updateValidations(modelIds?: string[]) {
    // ...
    const updatedVals = validations.update(
      this.state.validations,
      this.state.visibleFields,
      this.models,
      modelIds
    );

    for (const [formId, { omitRule, isWithinOmitThreshold }] of Object.entries(
      updatedVals.forms
    )) {
      if (
        !isWithinOmitThreshold &&
        this.state.validations.forms[formId].isWithinOmitThreshold
      ) {
        this.alert(omitRule?.ruleText ?? '', 'danger');
      }
    }

    logger.info(`validations updated:`, updatedVals);

    // Object.assign(this.state.validations, updatedValidations);

    // ...
    this.setState({
      validations: validations.set(this.state.validations, updatedVals),
    });
  }

  render() {
    const { activeFormId, validations, filteredForms } = this.state;
    const { draftFeatureEnabled } = this.props;

    // ...
    const formIndex = filteredForms.findIndex(({ id }) => id === activeFormId);
    // ...
    const isFirstForm = formIndex === 0;
    // ...
    const isLastForm = formIndex === filteredForms.length - 1;

    // ...
    const previousForm = () => {
      this.setState({ activeFormId: filteredForms[formIndex - 1].id });
    };

    // ...
    const nextForm = () => {
      this.setState({ activeFormId: filteredForms[formIndex + 1].id });
    };

    // ...
    const tabs = filteredForms.map((form) => {
      return (
        <EvaluationTab key={form.id} form={form} validations={validations} />
      );
    });

    return (
      <div className="evaluation-display">
        <div className="evaluation-display-header p-3">
          <Nav
            className="justify-content-center"
            variant="pills"
            activeKey={activeFormId ?? undefined}
            onSelect={(key) => this.setState({ activeFormId: key ?? '' })}
          >
            {tabs}
          </Nav>
        </div>
        <div className="evaluation-display-content">
          <div
            className="evaluation-display-content-inner"
            ref={this.displayEl}
          >
            <div className="evaluation-form-wrapper">
              {activeFormId && (
                <EvaluationForm
                  form={this.activeForm}
                  validations={validations}
                />
              )}
            </div>
          </div>
        </div>
        <div className="evaluation-display-footer p-3">
          <Container fluid>
            <Row className="justify-content-between align-items-center">
              {/* Left Footer Column */}
              <Col className="d-flex justify-content-start">
                {/* Back Button */}
                {!isFirstForm && <Button onClick={previousForm}>Back</Button>}
              </Col>
              {/* Right Footer Column */}
              <Col className="d-flex justify-content-end">
                {/* Next Button */}
                {!isLastForm && (
                  <Button className="me-2" onClick={nextForm}>
                    Next
                  </Button>
                )}
                {/* Save Draft Button */}
                {draftFeatureEnabled && <ExportDraftButton />}
                {/* Done Button */}
                <DoneButton disabled={validations.invalid} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
};

/** ... */
export const EvaluationDisplay = connector(_EvaluationDisplay);

// region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isEvaluationInputData(value: unknown): value is Evaluation['models'] {
  if (!isObject(value)) return false;

  return Object.entries(value).every(([key, value]) => {
    // ...
    if (!isString(key)) return false;
    // ...
    if (!isObject(value)) return false;
    // ...
    if ('value' in value === false) return false;

    return (
      isString(value.value) || isNumber(value.value) || value.value === null
    );
  });
}

// endregion Helper Functions
