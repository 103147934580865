import React from 'react';
import { hot } from 'react-hot-loader/root';

import { generateConfig } from '@dev-app/generate-dg-config';
import { ActionsPopup } from '@dev-app/components/ActionsPopup';
import { AlertPanel } from '@dev-app/components/AlertPanel';
import { ResultsSection } from '@dev-app/components/ResultsSection';
import { dataGatherer, Configuration, Output } from '@sdk';
import { delay } from '@tools/delay';

import * as tools from '../tools';

import './HostedTestPage.scoped.scss';

/** `HostedTestPage` component properties interface. */
type Props = Record<string, never>;

/** `HostedTestPage` component state interface. */
interface State {
  isCreated: boolean;
  displayResultsView: boolean;
  config: Configuration;
  payload: Output | null;
}

const _HostedTestPage = class HostedTestPage extends React.Component<
  Props,
  State
> {
  state = {
    isCreated: false,
    displayResultsView: false,
    config: generateConfig(),
    payload: null,
  };

  constructor(props: Props) {
    super(props);

    // this.onMessage = this.onMessage.bind(this);
    // this.onEvaluationComplete = this.onEvaluationComplete.bind(this);
    this.activateDataGatherer = this.activateDataGatherer.bind(this);
    this.deactivateDataGatherer = this.deactivateDataGatherer.bind(this);
  }

  componentDidMount() {
    // window.addEventListener('message', this.onMessage);

    dataGatherer.on('activationCompleted', this.onActivationCompleted);
    dataGatherer.on('activationError', this.onActivationError);
    dataGatherer.on('evaluationCompleted', this.onEvaluationComplete);
    dataGatherer.on('evaluationDraftExported', this.onEvaluationDraftExported);

    void this.activateDataGatherer();
  }

  componentDidUpdate(_: Props, prevState: State) {
    // ...
    if (prevState.config !== this.state.config) {
      void this.refreashDataGatherer();
    }
  }

  /**
   * Activate Data Gatherer and mount widget onto page.
   */
  private async activateDataGatherer() {
    // ...
    tools.saveSelection(this.state.config.toolMeasureId);

    // ...
    try {
      await dataGatherer.create(this.state.config);
    } catch (err) {
      return console.error(err);
    }

    this.setState({ isCreated: true });
  }

  /**
   * Deactivate Data Gatherer and remove from page.
   */
  private deactivateDataGatherer() {
    // ...
    dataGatherer.teardown();

    this.setState({ isCreated: false });
  }

  /**
   * ...
   */
  private async refreashDataGatherer() {
    // ...
    this.deactivateDataGatherer();

    // ...
    await delay();

    // ...
    await this.activateDataGatherer();
  }

  /**
   * ...
   */
  private resetView() {
    // ...
    this.setState({ payload: null, displayResultsView: false });

    void this.refreashDataGatherer();
  }

  /**
   * ...
   */
  private onConfigUpdated = (config: Configuration) => {
    this.setState({ config });
  };

  /**
   * ...
   */
  private onActivationCompleted = () => {
    console.log('parent-page - activation completed!');
  };

  /**
   * ...
   */
  private onActivationError = (err: Error) => {
    console.log('parent-page -- activation error!', err);
  };

  /**
   * ...
   */
  private onEvaluationComplete = (payload: Output) => {
    console.log('parent-page -- evaluation completed!', payload);

    this.setState({ payload });
  };

  /**
   * ...
   */
  private onEvaluationDraftExported = (draft: Output) => {
    console.log(
      'parent-page - evaluation draft exported!',
      JSON.stringify(draft, null, 2)
    );
  };

  render() {
    const { isCreated, displayResultsView, config, payload } = this.state;

    // ...
    const actionsPopupProps: ActionsPopup.Props = {
      config,
      isCreated,
      onActivate: this.activateDataGatherer,
      onDeactivate: this.deactivateDataGatherer,
      onConfigUpdated: this.onConfigUpdated,
    };

    // return (
    //   <div className="app-body">
    //     <div className="page-section">
    //       <div className="data-gatherer-frame-wrapper">
    //         <div id="data-gatherer"></div>
    //       </div>
    //     </div>
    //     {/** Results Section  */}
    //     <ResultsSection payload={payload} />
    //     {/** Actions Popup */}
    //     <ActionsPopup {...actionsPopupProps} />
    //   </div>
    // );

    return (
      <div className="app-body">
        <AlertPanel
          active={!!payload}
          text="Data Gatherer evaluation received."
          button1={{
            text: 'View',
            onClick: () => this.setState({ displayResultsView: true }),
          }}
          button2={{
            text: 'Reset',
            onClick: () => this.resetView(),
          }}
        />

        <div className="page-section">
          <div className="panel full-width" id="data-gatherer"></div>
        </div>

        <ResultsSection
          active={displayResultsView}
          payload={payload}
          onClose={() => this.setState({ displayResultsView: false })}
        />

        {/* ... */}
        <ActionsPopup {...actionsPopupProps} />
      </div>
    );
  }
};

/** HostedTestPage view component. */
export const HostedTestPage = hot(_HostedTestPage);
