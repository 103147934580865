/** DEV NOTES from Scales Items Spreadsheet
 * INCLUDE- 51095614-4639-41bc-bd9f-e5d6f2ac8a86 | Formless Input – Part 1 Scale Values Only
 *      'scored items'
 * INCLUDE- 4cafb061-781a-466b-ade2-c82f7b49febd | Formless Input – Parts 3 to 6
 *      'sections 3-6'
 * INCLUDE- c8cb046f-94ab-4f65-801f-0333ab41b77b | Formless Input - Part 1 Demographics & Unscored observations
 *
 */
const evaluationForm: DataGatherer.Form = {
  type: 'evaluation',
  id: '51095614-4639-41bc-bd9f-e5d6f2ac8a86',
  interpretAs: '51095614-4639-41bc-bd9f-e5d6f2ac8a86',
  sourceId: '51095614-4639-41bc-bd9f-e5d6f2ac8a86',
  name: 'Part 1',
  rules: [
    {
      type: 'Omit',
      amount: 4,
      omitValue: '-1',
      ruleText: 'You may omit a maximum of 4 items.',
    },
  ],
  fields: [
    {
      type: 'field-group',
      heading: 'Part 1.1 - Prior and Current Offenses/Dispositions',
      fields: [
        {
          id: 'YuOAVpHg',
          referenceId: '',
          label: 'Part 1.1 Comments',
          sequenceNumber: 11,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'MsCzal7i',
          referenceId: '',
          label: 'Part 1.1 Sources of Information',
          sequenceNumber: 12,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'B3b1PPF1',
          referenceId: '1.1.1',
          label: 'Three or more prior convictions',
          sequenceNumber: 1,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: 'AARGaK67',
          referenceId: '1.1.2',
          label: 'Two or more failures to comply',
          sequenceNumber: 2,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: 'OmsREzml',
          referenceId: '1.1.3',
          label: 'Prior probation',
          sequenceNumber: 3,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'CNgIKrYq',
          referenceId: '1.1.4',
          label: 'Prior custody',
          sequenceNumber: 4,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'iVy5uTdi',
          referenceId: '1.1.5',
          label: 'Three or more current convictions',
          sequenceNumber: 5,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'e',
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.2 - Family Circumstances/Parenting',
      fields: [
        {
          id: 'Ks50cQqk',
          referenceId: '',
          label: 'Part 1.2 Comments',
          sequenceNumber: 13,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '8c5eTLiX',
          referenceId: '',
          label: 'Part 1.2 Sources of Information',
          sequenceNumber: 14,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'RRt8NF5M',
          referenceId: '1.3.1',
          label: 'Inadequate supervision',
          sequenceNumber: 6,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: 'YKYq2KVu',
          referenceId: '1.3.2',
          label: 'Difficulty in controlling behavior',
          sequenceNumber: 7,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: 'FtVIETRz',
          referenceId: '1.2.3',
          label: 'Inappropriate discipline',
          sequenceNumber: 8,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'jTijMzA2',
          referenceId: '1.2.4',
          label: 'Inconsistent parenting',
          sequenceNumber: 9,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'tbziT1zX',
          referenceId: '1.2.5',
          label: 'Poor relations (father - youth)',
          sequenceNumber: 10,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'e',
        },
        {
          id: 'P7vRTVeM',
          referenceId: '1.2.6',
          label: 'Poor relations (mother - youth)',
          sequenceNumber: 11,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'f',
        },
        {
          id: '1O3ucuAu',
          referenceId: '1.3.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 12,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.3 - Education/Employment',
      fields: [
        {
          id: 'jSGIdsBD',
          referenceId: '',
          label: 'Part 1.3 Comments',
          sequenceNumber: 15,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'aFRP8dSr',
          referenceId: '',
          label: 'Part 1.3 Sources of Information',
          sequenceNumber: 16,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: '5QZGjvqs',
          referenceId: '1.3.1',
          label: 'Disruptive classroom behavior',
          sequenceNumber: 13,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: 'sLDkXAVu',
          referenceId: '1.3.2',
          label: 'Disruptive behavior on school property',
          sequenceNumber: 14,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: 'mkgDyrCY',
          referenceId: '1.3.3',
          label: 'Low achievement',
          sequenceNumber: 15,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'DYe1KB3J',
          referenceId: '1.3.4',
          label: 'Problems with peers',
          sequenceNumber: 16,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'YdPHbmCv',
          referenceId: '1.3.5',
          label: 'Problems with teachers',
          sequenceNumber: 17,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'e',
        },
        {
          id: 'Qwe9S25S',
          referenceId: '1.3.6',
          label: 'Truancy',
          sequenceNumber: 18,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'f',
        },
        {
          id: 'stN5bmHJ',
          referenceId: '1.3.7',
          label: 'Unemployed/not seeking employment',
          sequenceNumber: 19,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'g',
        },
        {
          id: 'f5dc1931',
          referenceId: '1.3.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 20,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.4 - Peer Relations',
      fields: [
        {
          id: 'Lv4SZogs',
          referenceId: '',
          label: 'Part 1.4 Comments',
          sequenceNumber: 17,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'dOnZPUuw',
          referenceId: '',
          label: 'Part 1.4 Sources of Information',
          sequenceNumber: 18,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'YlBR6YF0',
          referenceId: '1.4.1',
          label: 'Some delinquent acquaintances',
          sequenceNumber: 21,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: 'ArfdKngF',
          referenceId: '1.4.2',
          label: 'Some delinquent friends',
          sequenceNumber: 22,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: ['51095614-4639-41bc-bd9f-e5d6f2ac8a86>YlBR6YF0>1'],
                  message:
                    'If item {b} in section {1.4} is answered "{Yes}", then item {a} in section {1.4} must also be answered "{Yes}." We have already made this adjustment for you. Please see YLS/CMI 2.0 scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: 'e2XNKH48',
          referenceId: '1.4.3',
          label: 'No/few positive acquaintances',
          sequenceNumber: 23,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: ['51095614-4639-41bc-bd9f-e5d6f2ac8a86>BTLTl17b>1'],
                  message:
                    'If item {c} in section {1.4} is answered "{Yes}", then item {d} in section {1.4} must also be answered "{Yes}." We have already made this adjustment for you. Please see YLS/CMI 2.0 scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'BTLTl17b',
          referenceId: '1.4.4',
          label: 'No/few positive friends',
          sequenceNumber: 24,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'Pjcpj0Ai',
          referenceId: '1.4.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 25,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.5 - Substance Abuse',
      fields: [
        {
          id: '4DzSgDPp',
          referenceId: '',
          label: 'Part 1.5 Comments',
          sequenceNumber: 19,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'eOfOWkPx',
          referenceId: '',
          label: 'Part 1.5 Sources of Information',
          sequenceNumber: 20,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'TsrrsM3i',
          referenceId: '1.5.1',
          label: 'Occasional drug use',
          sequenceNumber: 26,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: '76E5mxDL',
          referenceId: '1.5.2',
          label: 'Chronic drug use',
          sequenceNumber: 27,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              autoAnswer: [
                {
                  answer: ['9c69da84-9168-452e-8d06-ff76966ddec5>TsrrsM3i>1'],
                  message:
                    'If item {b} in section {1.5} is answered "{Yes}", then item {a} in section {1.5} must also be answered "{Yes}." We have already made this adjustment for you. Please see YLS/CMI 2.0 scoring guide for more details.',
                  conditions: [],
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: 'kgxAWAzg',
          referenceId: '1.5.3',
          label: 'Chronic alcohol use',
          sequenceNumber: 28,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'JaPZFlR9',
          referenceId: '1.5.4',
          label: 'Substance abuse interferes with life',
          sequenceNumber: 29,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            {
              label: 'Yes',
              value: '1',
              warningCheck: [
                {
                  conditions: [
                    {
                      operator: '=',
                      variable: '51095614-4639-41bc-bd9f-e5d6f2ac8a86>JaPZFlR9',
                      comparator: '1',
                    },
                  ],
                  conditionModifier: null,
                  warning:
                    'If item {d} in section "{1.5}" is answered "{Yes}," then consider answering items {b} and/or {c} in section {1.5} as "{Yes}".',
                },
              ],
            },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'M1g5a48r',
          referenceId: '1.5.5',
          label: 'Substance use linked to offense(s)',
          sequenceNumber: 30,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'e',
        },
        {
          id: 'IFqm3Giv',
          referenceId: '1.5.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 31,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.6 - Leisure/Recreation',
      fields: [
        {
          id: 'FN5QYI6z',
          referenceId: '',
          label: 'Part 1.6 Comments',
          sequenceNumber: 21,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'ui8SMuep',
          referenceId: '',
          label: 'Part 1.6 Sources of Information',
          sequenceNumber: 22,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'kRkkiIVJ',
          referenceId: '1.6.1',
          label: 'Limited organized activities',
          sequenceNumber: 32,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: 'oXvtXFQT',
          referenceId: '1.6.2',
          label: 'Could make better use of time',
          sequenceNumber: 33,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: 'wu7aq04e',
          referenceId: '1.6.3',
          label: 'No personal interests',
          sequenceNumber: 34,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'Gw8Ikyta',
          referenceId: '1.6.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 35,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.7 - Personality/Behavior',
      fields: [
        {
          id: 'NNRFTq2y',
          referenceId: '',
          label: 'Part 1.7 Comments',
          sequenceNumber: 23,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'LT7W6owd',
          referenceId: '',
          label: 'Part 1.7 Sources of Information',
          sequenceNumber: 24,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'SSp6QmGw',
          referenceId: '1.7.1',
          label: 'Inflated self-esteem',
          sequenceNumber: 36,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: '6iAVaM0s',
          referenceId: '1.7.2',
          label: 'Physically aggressive',
          sequenceNumber: 37,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: '4IZ5s1FG',
          referenceId: '1.7.3',
          label: 'Tantrums',
          sequenceNumber: 38,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'cq28CdWs',
          referenceId: '1.7.4',
          label: 'Short attention span',
          sequenceNumber: 39,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'RkADMi1x',
          referenceId: '1.7.5',
          label: 'Poor frustration tolerance',
          sequenceNumber: 40,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'e',
        },
        {
          id: 'IRQRRBUR',
          referenceId: '1.7.6',
          label: 'Inadequate guilt feelings',
          sequenceNumber: 41,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'f',
        },
        {
          id: 'irQp7GgW',
          referenceId: '1.7.7',
          label: 'Verbally aggressive, impudent',
          sequenceNumber: 42,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'g',
        },
        {
          id: 'ynqPqDfn',
          referenceId: '1.7.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 43,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 1.8 - Attitudes/Orientations',
      fields: [
        {
          id: 'B5ZzL4F9',
          referenceId: '',
          label: 'Part 1.8 Comments',
          sequenceNumber: 25,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'PPXFxlJ8',
          referenceId: '',
          label: 'Part 1.8 Sources of Information',
          sequenceNumber: 26,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'demographicsUnscoredValues',
          hideSequenceNumber: true,
        },
        {
          id: 'VMKCw2t0',
          referenceId: '1.8.1',
          label: 'Antisocial/procriminal attitudes',
          sequenceNumber: 44,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'a',
        },
        {
          id: 'ReEPPK3T',
          referenceId: '1.8.2',
          label: 'Not seeking help',
          sequenceNumber: 45,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'b',
        },
        {
          id: '27gJwVLU',
          referenceId: '1.8.3',
          label: 'Actively rejecting help',
          sequenceNumber: 46,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'c',
        },
        {
          id: 'LQBScEP0',
          referenceId: '1.8.4',
          label: 'Defies authority',
          sequenceNumber: 47,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'd',
        },
        {
          id: 'irIrn2IH',
          referenceId: '1.8.5',
          label: 'Callous, little concern for others',
          sequenceNumber: 48,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
            { label: 'Omit', value: '-1' },
          ],
          displayNumberOverride: 'e',
        },
        {
          id: 'JfqCV9dI',
          referenceId: '1.8.1.1',
          label: 'Is this subcomponent considered to be a strength?',
          sequenceNumber: 49,
          hideSequenceNumber: true,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'evaluate',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
      ],
    },
  ],
};

/** ... */
const part2Form: DataGatherer.Form = {
  id: '4cafb061-781a-466b-ade2-c82f7b49febd',
  interpretAs: '4cafb061-781a-466b-ade2-c82f7b49febd',
  sourceId: '4cafb061-781a-466b-ade2-c82f7b49febd',
  name: 'Part 2',
  type: 'unknown',
  fields: [
    {
      type: 'field-group',
      heading: 'Part 2: Family/Parent',
      fields: [
        {
          id: 'Gp9o0BqP',
          referenceId: '',
          label: 'Comments',
          sequenceNumber: 12,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'part2',
          displayNumberOverride: '12',
          hideSequenceNumber: true,
        },
        {
          id: 'OaVoH4mN',
          referenceId: '3.1.1',
          label: 'Chronic History of Offenses',
          sequenceNumber: 1,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '1',
        },
        {
          id: '8DkxcfQH',
          referenceId: '3.1.2',
          label: 'Emotional Distress/Psychiatric',
          sequenceNumber: 2,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '2',
        },
        {
          id: 'HhtpLfWh',
          referenceId: '3.1.3',
          label: 'Drug/Alcohol Abuse (Family/Parents)',
          sequenceNumber: 3,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '3',
        },
        {
          id: 'dYdPNZQ5',
          referenceId: '3.1.4',
          label: 'Marital Conflict',
          sequenceNumber: 4,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '4',
        },
        {
          id: 'QKwV5VaL',
          referenceId: '3.1.5',
          label: 'Financial/Accommodation Problems',
          sequenceNumber: 5,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '5',
        },
        {
          id: 'CaGW0n3H',
          referenceId: '3.1.6',
          label: 'Uncooperative Parent(s)',
          sequenceNumber: 6,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '6',
        },
        {
          id: 'Fz0Q6GCA',
          referenceId: '3.1.7',
          label: 'Cultural/Ethnic Issues',
          sequenceNumber: 7,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '7',
        },
        {
          id: 'AEW09rtH',
          referenceId: '3.1.8',
          label: 'Abusive Father',
          sequenceNumber: 8,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '8',
        },
        {
          id: 'klD6ilxL',
          referenceId: '3.1.9',
          label: 'Abusive Mother',
          sequenceNumber: 9,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '9',
        },
        {
          id: 'qdHeAFSO',
          referenceId: '3.1.10',
          label: 'Significant Family Trauma (Specify)',
          sequenceNumber: 10,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'part2',
          displayNumberOverride: '10',
        },
        {
          id: 'RZQCnHYg',
          referenceId: '3.1.11',
          label: 'Other (Specify)',
          sequenceNumber: 11,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'part2',
          displayNumberOverride: '11',
        },
      ],
    },
    {
      type: 'field-group',
      heading: 'Part 2: Youth',
      fields: [
        {
          id: 'Nld6UUsd',
          referenceId: '',
          label: 'Comments',
          sequenceNumber: 58,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'part2',
          hideSequenceNumber: true,
        },
        {
          id: 'aeHNUj57',
          referenceId: '3.2.1',
          label: 'Adverse Living Conditions',
          sequenceNumber: 13,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '1',
        },
        {
          id: 'COzW5pkI',
          referenceId: '3.2.2',
          label: 'Anxious',
          sequenceNumber: 14,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '2',
        },
        {
          id: 'aVjAsLqp',
          referenceId: '3.2.3',
          label: 'Communication Problems',
          sequenceNumber: 15,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '3',
        },
        {
          id: 'cwNOq9mH',
          referenceId: '3.2.4',
          label: 'Cruelty to Animals',
          sequenceNumber: 16,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '4',
        },
        {
          id: 'cfpq4xKe',
          referenceId: '3.2.5',
          label: 'Cultural/Ethnic Issues',
          sequenceNumber: 17,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '5',
        },
        {
          id: 'QrA9Re5J',
          referenceId: '3.2.6',
          label: 'Depressed',
          sequenceNumber: 18,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '6',
        },
        {
          id: 'ubjDFKSK',
          referenceId: '3.2.7',
          label: 'Diagnosis of Conduct Disorder/Oppositional Defiant Disorder',
          sequenceNumber: 19,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '7',
        },
        {
          id: '4zlQb5Gu',
          referenceId: '3.2.8',
          label: 'Diagnosis of Psychosis',
          sequenceNumber: 20,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '8',
        },
        {
          id: 'BRv7dPDu',
          referenceId: '3.2.9',
          label: 'Engages in Denial',
          sequenceNumber: 21,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '9',
        },
        {
          id: 'XxMpa26K',
          referenceId: '3.2.10',
          label: 'Fetal Alcohol Spectrum Disorder (FASD)',
          sequenceNumber: 22,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '10',
        },
        {
          id: 'UtBuP5qa',
          referenceId: '3.2.11',
          label: 'Financial/Accommodation Problems',
          sequenceNumber: 23,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '11',
        },
        {
          id: '1t5BvdUt',
          referenceId: '3.2.12',
          label: 'Gang Involvement',
          sequenceNumber: 24,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '12',
        },
        {
          id: 'YJDu8oXe',
          referenceId: '3.2.13',
          label: 'Gender Issues',
          sequenceNumber: 25,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '13',
        },
        {
          id: 'rnHK93yn',
          referenceId: '3.2.14',
          label: 'Health Problems',
          sequenceNumber: 26,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '14',
        },
        {
          id: 'HJ0HSCt5',
          referenceId: '3.2.15',
          label: 'History of Assault on Authority Figures',
          sequenceNumber: 27,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '15',
        },
        {
          id: 'px9WTVku',
          referenceId: '3.2.16',
          label: 'History of Bullying',
          sequenceNumber: 28,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '16',
        },
        {
          id: 'n4amZFI4',
          referenceId: '3.2.17',
          label: 'History of Escape',
          sequenceNumber: 29,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '17',
        },
        {
          id: 'zeNuiBc7',
          referenceId: '3.2.18',
          label: 'History of Fire Setting',
          sequenceNumber: 30,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '18',
        },
        {
          id: 'ia1DxQPC',
          referenceId: '3.2.19',
          label: 'History of Running Away',
          sequenceNumber: 31,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '19',
        },
        {
          id: 'cpuHdAU9',
          referenceId: '3.2.20',
          label: 'History of Sexual/Physical Assault',
          sequenceNumber: 32,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '20',
        },
        {
          id: 'O2Tk7m7p',
          referenceId: '3.2.21',
          label: 'History of Weapons Use',
          sequenceNumber: 33,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '21',
        },
        {
          id: 'D8sBcM7Y',
          referenceId: '3.2.22',
          label: 'Inappropriate Sexual Activity',
          sequenceNumber: 34,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '22',
        },
        {
          id: 'vso7oKNB',
          referenceId: '3.2.23',
          label: 'Learning Disability',
          sequenceNumber: 35,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '23',
        },
        {
          id: 'K83RiaVz',
          referenceId: '3.2.24',
          label: 'Low Intelligence/Developmental Delay',
          sequenceNumber: 36,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '24',
        },
        {
          id: '7Gq3jRlx',
          referenceId: '3.2.25',
          label: 'Low Self-Esteem',
          sequenceNumber: 37,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '25',
        },
        {
          id: 'ivNCenw7',
          referenceId: '3.2.26',
          label: 'Manipulative',
          sequenceNumber: 38,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '26',
        },
        {
          id: '1IOrZvI6',
          referenceId: '3.2.27',
          label: 'Parenting Issues',
          sequenceNumber: 39,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '27',
        },
        {
          id: 'rW0vpyzC',
          referenceId: '3.2.28',
          label: 'Peers Outside Age Range',
          sequenceNumber: 40,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '28',
        },
        {
          id: 't69O8OQB',
          referenceId: '3.2.29',
          label: 'Physical Disability',
          sequenceNumber: 41,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '29',
        },
        {
          id: '8sXXG1ih',
          referenceId: '3.2.30',
          label: 'Poor Problem-Solving Skills',
          sequenceNumber: 42,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '30',
        },
        {
          id: '77Spyofv',
          referenceId: '3.2.31',
          label: 'Poor Social Skills',
          sequenceNumber: 43,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '31',
        },
        {
          id: 'cp8H5Zhu',
          referenceId: '3.2.32',
          label: 'Pregnancy Issues',
          sequenceNumber: 44,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '32',
        },
        {
          id: 'R9YZcPyG',
          referenceId: '3.2.33',
          label: 'Protection Issues',
          sequenceNumber: 45,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '33',
        },
        {
          id: 'DAItblv3',
          referenceId: '3.2.34',
          label: 'Racist/Sexist Attitudes',
          sequenceNumber: 46,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '34',
        },
        {
          id: 'ye1euJaA',
          referenceId: '3.2.35',
          label: 'Self-Management Skills',
          sequenceNumber: 47,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '35',
        },
        {
          id: 'io2GJWZf',
          referenceId: '3.2.36',
          label: 'Shy/Withdrawn',
          sequenceNumber: 48,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '36',
        },
        {
          id: 'E4ArmrTI',
          referenceId: '3.2.37',
          label: 'Suicidal Ideation/Attempts or Self-Injury',
          sequenceNumber: 49,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '37',
        },
        {
          id: '4vX1VqB6',
          referenceId: '3.2.38',
          label: 'Third Party Threat',
          sequenceNumber: 50,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '38',
        },
        {
          id: '1SUWYrhU',
          referenceId: '3.2.39',
          label: 'Underachievement',
          sequenceNumber: 51,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '39',
        },
        {
          id: '9X9SvDBB',
          referenceId: '3.2.40',
          label: 'Victim of Bullying',
          sequenceNumber: 52,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '40',
        },
        {
          id: 'cZOUB9pK',
          referenceId: '3.2.41',
          label: 'Victim of Neglect',
          sequenceNumber: 53,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '41',
        },
        {
          id: 'Sb70gQcN',
          referenceId: '3.2.42',
          label: 'Victim of Physical/Sexual Abuse',
          sequenceNumber: 54,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '42',
        },
        {
          id: 'kKubefkM',
          referenceId: '3.2.43',
          label: 'Victim of Domestic Violence',
          sequenceNumber: 55,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'dropdown',
          payloadType: 'part2',
          answers: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
          displayNumberOverride: '43',
        },
        {
          id: 'wDxu492K',
          referenceId: '3.2.44',
          label: 'Other Mental Health Issues (specify)',
          sequenceNumber: 56,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'part2',
          displayNumberOverride: '44',
        },
        {
          id: 'zjP556zO',
          referenceId: '3.2.45',
          label: 'Other (specify)',
          sequenceNumber: 57,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'part2',
          displayNumberOverride: '45',
        },
      ],
    },
  ],
};

/** ... */
const part1DemographicsAndUnscoredObservations: DataGatherer.Form = {
  id: 'c8cb046f-94ab-4f65-801f-0333ab41b77b',
  interpretAs: 'c8cb046f-94ab-4f65-801f-0333ab41b77b',
  sourceId: 'c8cb046f-94ab-4f65-801f-0333ab41b77b',
  name: 'General Information',
  type: 'unknown',
  fields: [
    {
      id: 'AbJR6woT',
      referenceId: '',
      label: 'Name of Assessed / Offender Name',
      sequenceNumber: 1,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'bvCugg3z',
      referenceId: '',
      label: 'Gender',
      sequenceNumber: 2,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'demographicsUnscoredValues',
      answers: [
        { label: 'Male', value: '0' },
        { label: 'Female', value: '1' },
      ],
      hideSequenceNumber: true,
    },
    {
      id: 'IQA9K4tD',
      referenceId: '',
      label: 'Age',
      sequenceNumber: 3,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'number',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: '98HSSItf',
      referenceId: '',
      label: 'Date of Birth',
      sequenceNumber: 4,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'date',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: '888kmW0c',
      referenceId: '',
      label: 'Race / Ethnicity',
      sequenceNumber: 9,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'rOhf0Qg1',
      referenceId: '',
      label: 'Identifying Number / Offender ID Number',
      sequenceNumber: 5,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'tuqSRS2q',
      referenceId: '',
      label: "Interviewer / Assessor's Name",
      sequenceNumber: 6,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'TYy67bFH',
      referenceId: '',
      label: 'Date of Test Administration / Interview Date',
      sequenceNumber: 7,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'date',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'IzKEjwWD',
      referenceId: '',
      label: 'Setting',
      sequenceNumber: 8,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'demographicsUnscoredValues',
      answers: [
        { label: 'Community', value: '0' },
        { label: 'Custodial', value: '1' },
      ],
      hideSequenceNumber: true,
    },
    {
      id: 'Ne1KmW2t',
      referenceId: '',
      label: 'Current Location',
      sequenceNumber: 10,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
    {
      id: 'm4VT6f9U',
      referenceId: 'off-type',
      label: 'Offender Type',
      sequenceNumber: 10,
      required: false,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'demographicsUnscoredValues',
      hideSequenceNumber: true,
    },
  ],
};

/** ... */
const references: DataGatherer.Tool.Reference[] = [
  {
    id: 'off-type',
    title: 'Reference Text',
    content:
      'Examples include:\n\n- Violent Offender\n- Sex Offender\n- Mentally Disordered Offender\n- Dangerous Offender\n- High-Profile Offender\n- Suicidal Offender\n- Petty Offender\n- Certified to Adult Court/Status\n- Psychopathic Offender\n- Drug/Alcohol Offender\n- Domestic Violence Offender',
  },
  {
    id: '1.1.1',
    title: 'Reference Text',
    content:
      "Select 'Yes' for this item if the youth has had three or more convictions prior to the current convictions.\n\n- This refers to set(s) of convictions at different points in time. If several offenses occurred at the same time, record them but only count the offenses as one set because this item is concerned with chronic offenders (i.e., individuals with sets of convictions occurring at different points in time).\n\n- Do not count diversion and/or continuance for dismissal\n\n- Do not count petty traffic offenses (speeding, crossing a center line, etc.)",
  },
  {
    id: '1.1.2',
    title: 'Reference Text',
    content:
      'These include failures to appear in court, probation/parole violation, escape from custody, failure to comply with alternative measures, or being unlawfully at large.',
  },
  {
    id: '1.1.3',
    title: 'Reference Text',
    content: "Select 'Yes' if the youth has ever been on probation.",
  },
  {
    id: '1.1.4',
    title: 'Reference Text',
    content:
      "Select 'Yes' if the youth has ever spent time in a custody facility. Custody does not include pretrial detention or detention for status offenses.",
  },
  {
    id: '1.1.5',
    title: 'Reference Text',
    content:
      'The youth has received three or more convictions for the current disposition(s).\n\n-If several offenses occurred at the same time, record them, but count the offenses as one set because this item is concerned with chronic offenders (i.e., individuals with sets of convictions occurring at different points in time).',
  },
  {
    id: '1.3.1',
    title: 'Reference Text',
    content:
      'The youth has received three or more convictions for the current disposition(s).\n\n-If several offenses occurred at the same time, record them, but count the offenses as one set because this item is concerned with chronic offenders (i.e., individuals with sets of convictions occurring at different points in time).',
  },
  {
    id: '1.3.2',
    title: 'Reference Text',
    content:
      "Parents have problems exercising control over the youth's behavior (e.g., youth disobeys parental instructions and is out of control). Also select 'Yes' if youth is living independently and his/her behavior is not controlled.",
  },
  {
    id: '1.2.3',
    title: 'Reference Text',
    content:
      "There is an excessive use of corporal punishment, frequent use of yelling or threats, overly strict rules, or otherwise poor disciplinary practices on the part of the parent(s). Also select 'Yes' if parenting is overly permissive, with no effort at providing direction.",
  },
  {
    id: '1.2.4',
    title: 'Reference Text',
    content:
      "Parents are inconsistent in applying rules or using punishment/rewards; periods of harsh discipline may alternate with periods of neglect or extreme permissiveness. Also select 'Yes' if the parents cannot form (or articulate) clear rules regarding homework, curfews, friends, etc.",
  },
  {
    id: '1.2.5',
    title: 'Reference Text',
    content:
      "There is a particularly poor relationship (e.g., hostile, alienated, uncaring) between the youth and his/her father or stepfather. Youth does not have to be living with his/her father or stepfather to rate this item. In cases where there is a natural father and stepfather, evaluate the most prominent relationship over the past year. If the father or stepfather is deceased or otherwise absent, select 'Yes' for this item if the poor relationship continues to be an issue.",
  },
  {
    id: '1.2.6',
    title: 'Reference Text',
    content:
      'There is a particularly poor relationship (e.g., hostile, alienated, uncaring) between the youth and his/her mother or stepmother. Youth does not have to be living with his/her mother or stepmother to rate this item. In cases where there is a natural mother and stepmother, evaluate the most prominent relationship over the past year. If the mother or stepmother is deceased or otherwise absent, select Yes for this item if the poor relationship continues to be an issue.',
  },
  {
    id: '1.3.1.1',
    title: 'Reference Text',
    content:
      "Warm, caring relationships that are prosocial in orientation can offset interactions with delinquent peers and provide support in case management plans targeting other criminogenic needs. For example, family members may be influential in assisting the youth with intervention and counselling programs for educational or employment needs, alcohol/drug abuse, etc. Stable family relationships can be an area in the youth's life that may be relatively stress-free and comforting and facilitate the transition and maintenance to a prosocial lifestyle.",
  },
  {
    id: '1.3.1',
    title: 'Reference Text',
    content:
      'Youth is engaged in acting-out, attention seeking, defiance, or other disruptive behaviors within the classroom or other areas within the school building (e.g., gymnasium, hallways, etc.); youth is considered to be a problem by teachers and other school staff within the school building.',
  },
  {
    id: '1.3.2',
    title: 'Reference Text',
    content:
      'The youth is initiating aggressive or violent actions or is otherwise engaging in misconduct on school property outside classroom setting; may include criminal activities such as theft, vandalism, or drug/alcohol use.',
  },
  {
    id: '1.3.3',
    title: 'Reference Text',
    content:
      "The youth is currently failing a subject or there are other indications of achievement problems. If the youth is performing at his/her expected level, but is getting low grades, 'No' should be selected for this item.",
  },
  {
    id: '1.3.4',
    title: 'Reference Text',
    content:
      'There is evidence that the youth is disliked, isolated, withdrawn, or there is evidence of poor relations with peers within the school setting.',
  },
  {
    id: '1.3.5',
    title: 'Reference Text',
    content:
      'There is evidence of significant and continuing problems between the youth and his/her teacher (or other school official); youth hates teachers or is hostile toward them.',
  },
  {
    id: '1.3.6',
    title: 'Reference Text',
    content:
      'Youth is currently missing school days or skipping classes without legitimate excuses.',
  },
  {
    id: '1.3.7',
    title: 'Reference Text',
    content:
      'Mark this item if, without good reason, (e.g., full-time student), the youth has no job and is not engaged in employment-related activities (e.g., actively seeking work, engaged in employment training, or work schemes).',
  },
  {
    id: '1.3.1.1',
    title: 'Reference Text',
    content:
      'Involvement in educational and employment activities can be a particularly important area of strength because of the large time commitment required and the varied sources of interpersonal rewards. For example, a youth may have enormous respect for an employer/teacher and likes work/school so much that he/she works overtime/does extra homework in addition to the time already spent at work/school. This involvement and commitment to prosocial activities serves to distance the youth from situations that may be criminogenic.',
  },
  {
    id: '1.4.1',
    title: 'Reference Text',
    content:
      "Some of the youth's acquaintances/casual friends are known offenders or exhibit antisocial attitudes.\n\n- For custodial youth, select 'Yes' for this item.",
  },
  {
    id: '1.4.2',
    title: 'Reference Text',
    content:
      "Some of the youth's close friends are known offenders or exhibit antisocial attitudes.\n\n- If this item is rated 'Yes', then item 1.4a must also be rated 'Yes'.",
  },
  {
    id: '1.4.3',
    title: 'Reference Text',
    content:
      "Youth has no or very few acquaintances/casual friends who represent positive role models (e.g., doing well in school; not associated with crime, alcohol, or drugs).\n\n- If this item is rated 'Yes', then item 1.4d must also be rated 'Yes'.",
  },
  {
    id: '1.4.4',
    title: 'Reference Text',
    content:
      'Youth has no or very few close friends who represent positive role models (e.g., doing well in school; not associated with crime, alcohol, or drugs).',
  },
  {
    id: '1.4.1.1',
    title: 'Reference Text',
    content:
      "This subcomponent should not be considered a strength if item 1.4c (No/few positive acquaintances) or 1.4d (No/few positive friends) are rated 'Yes'. In addition, prosocial friends are very influential and represent positive role models for the youth.",
  },
  {
    id: '1.5.1',
    title: 'Reference Text',
    content:
      "There is evidence that the youth is an occasional user of an illicit drug.\n\n- At this level, there is no evidence of current problems. The youth is a controlled and infrequent recreational user. Do not select 'Yes' for this item if the youth has stopped drug use for more than a year.",
  },
  {
    id: '1.5.2',
    title: 'Reference Text',
    content:
      "There is evidence that the youth is a regular user of an illicit drug.\n\n- If this item is rated 'Yes', then item 1.5a must also be rated 'Yes'.",
  },
  {
    id: '1.5.3',
    title: 'Reference Text',
    content: 'The youth regularly consumes alcoholic beverages.',
  },
  {
    id: '1.5.4',
    title: 'Reference Text',
    content:
      "Drug and/or alcohol use affects the youth's physical or social functioning and/or is associated with antisocial activity. May involve interference with schoolwork or job, tension with parents, loss of friends, accidents, etc.\n\n- If this item is rated 'Yes', at least one of items 1.5b or 1.5c must be rated 'Yes'.",
  },
  {
    id: '1.5.5',
    title: 'Reference Text',
    content:
      "There is reason to believe that the youth's criminal activity is related to drug or alcohol use. For this item, the substance abuse itself is not considered an offense. Select 'Yes' for this item only if the substance use directly contributes to another criminal act or probation violation.",
  },
  {
    id: '1.5.1.1',
    title: 'Reference Text',
    content:
      'An example of a strength is a recovering substance abuser who has not drank or used illicit drugs for a long period of time, and is actively involved in attending support groups and warning others about the negative effects of substance abuse. Such firmly held anti-drinking or anti-drug beliefs might distance the youth from others who abuse drugs or alcohol and sensitize him/her to situations that may promote relapse. Further examples include a teetotaler (i.e., a total abstainer) or someone who is active in an anti-alcohol or anti-drug campaign.',
  },
  {
    id: '1.6.1',
    title: 'Reference Text',
    content:
      'There is no evidence that the youth participates in sports, clubs, or other types of organized positive activities.',
  },
  {
    id: '1.6.2',
    title: 'Reference Text',
    content:
      "The youth spends too much time in passive or unconstructive activities (e.g., watching TV or videos, playing video games, partying, hanging around). Activities interfere with the youth's ability to socialize with prosocial peers, or have a negative impact on other areas such as education or employment.",
  },
  {
    id: '1.6.3',
    title: 'Reference Text',
    content:
      "The youth appears to have no personal interests of a positive nature (e.g., sports, reading, hobbies). Select 'No' for this item if the youth participates in his/her interests.",
  },
  {
    id: '1.6.1.1',
    title: 'Reference Text',
    content:
      'For example, a longstanding commitment to a sport team may counteract the temptation to use drugs in order to maintain health and continue participation in the sport. The youth may hold a position of responsibility or positive influence in a school organization or committee where continued involvement would require avoiding antisocial peers and activities. Involvement in a religious organization could strengthen prosocial values and interactions with other prosocial individuals. Most religious organizations promote prosocial values of honesty, altruism, and charity; all of which are incongruent with antisocial values and beliefs. Thus, involvement in such organizations strengthens prosocial values and promotes active resistance against the influence of criminal attitudes, values, and beliefs.',
  },
  {
    id: '1.7.1',
    title: 'Reference Text',
    content:
      'Youth thinks he/she is superior to others; brags constantly; feeling of self-worth seems to exceed accomplishments.',
  },
  {
    id: '1.7.2',
    title: 'Reference Text',
    content:
      'Youth initiates acts of physical aggression against others, starts fights, or has engaged in violent actions. Youth believes physical aggression is an appropriate way of expressing oneself and dealing with others. Physical aggression is directed toward humans or animals.',
  },
  {
    id: '1.7.3',
    title: 'Reference Text',
    content:
      'Youth displays acts of temper; loses control when frustrated or angry. Tantrums are directed toward objects.',
  },
  {
    id: '1.7.4',
    title: 'Reference Text',
    content:
      'Youth has difficulty attending to the task at hand; difficulty completing tasks; is hyperactive.',
  },
  {
    id: '1.7.5',
    title: 'Reference Text',
    content:
      'Youth deals with frustration poorly; loses patience easily; tends to act impulsively.',
  },
  {
    id: '1.7.6',
    title: 'Reference Text',
    content:
      "Youth feels no remorse when his/her behavior has caused harm to another; does not accept responsibility for actions, or offers excuses. (This item refers to the youth's feelings about his/her actions and should not be confused with item 1.8e. Callous, little concern for others).",
  },
  {
    id: '1.7.7',
    title: 'Reference Text',
    content:
      'Youth is often verbally abusive in dealing with others; uses language in a hostile or threatening manner.',
  },
  {
    id: '1.7.1.1',
    title: 'Reference Text',
    content:
      "The youth possesses a large number of personality and behavioral characteristics that are generally incompatible with antisocial behavior, such as humility, patience, attentiveness, and accountability, and to an exceptional degree. The youth is polite and respectful to peers and adults, solving disputes with discussion or avoiding confrontation altogether, and encourages others to act in a similar fashion. Note that criteria for noting this item as a Strength are different from those required for item 8 (Attitudes/Orientation), which are focused more towards the youth's attitudes towards crime, authority, and the feelings or welfare of others.",
  },
  {
    id: '1.8.1',
    title: 'Reference Text',
    content:
      "The youth's attitudes are supportive of a criminal or anti-conventional lifestyle; consider attitudes, values, beliefs and rationalizations concerning the crime and victim; youth does not think social rules and laws apply to her/him.",
  },
  {
    id: '1.8.2',
    title: 'Reference Text',
    content:
      'Youth is not seeking help, is reluctant to seek needed interventions, or does not recognize the need for help.',
  },
  {
    id: '1.8.3',
    title: 'Reference Text',
    content:
      'Youth is actively resisting the interventions of helping persons or agencies.',
  },
  {
    id: '1.8.4',
    title: 'Reference Text',
    content:
      'Youth refuses to follow directions from parents, teachers, or other authority figures; youth is hostile to the criminal justice system.',
  },
  {
    id: '1.8.5',
    title: 'Reference Text',
    content:
      'Youth shows little concern for the feelings or welfare of others; appears incapable of empathy.',
  },
  {
    id: '1.8.1.1',
    title: 'Reference Text',
    content:
      'Prosocial values and compliance with supervision can be a particularly strong countervailing influence to other criminogenic factors. To be viewed as a Strength, none of the items in this subcomponent should be marked. An example would be a youth in custodial care with a well-established prosocial attitude/orientation. Even surrounded by negative role models (i.e., other delinquent youth) and opportunities to express antisocial views without reprisal from other delinquent youth, his/her prosocial attitude/orientation could be sufficiently strong enough to resist influence from antisocial others.',
  },
  {
    id: '3.1.1',
    title: 'Reference Text',
    content:
      "Members of the youth's immediate family (parents or siblings) are engaged or have previously engaged in criminal acts. Include any convictions (adults), dispositions (youth), or formal charges (both).",
  },
  {
    id: '3.1.2',
    title: 'Reference Text',
    content:
      "One or both of the youth's parents have a current psychiatric disability or a recent history (past year) of such problems.",
  },
  {
    id: '3.1.3',
    title: 'Reference Text',
    content:
      "One or both of the youth's parents have current substance abuse problems or a recent history (past year) of such problems. Examples include, but are not limited to, alcohol/drug use to intoxication or the majority of social activities center around alcohol/drug use.",
  },
  {
    id: '3.1.4',
    title: 'Reference Text',
    content:
      "The youth's parents are currently experiencing marital conflict or have recently (past year) experienced marital conflict. Examples include, but are not limited to, frequent arguments (e.g., regarding money, child rearing or custody, ex-partners, etc.), infidelity, contemplating/accepting separation/divorce, or physical, psychological, and/or sexual abuse.",
  },
  {
    id: '3.1.5',
    title: 'Reference Text',
    content:
      "The youth's family is currently facing a financial and/or housing problem. Examples of financial problems include, but are not limited to, unmanageable debts (e.g., mortgage, student loan) and/or inadequate or inconsistent financial income or employment. Accommodation problems include the youth's family's expressed dissatisfaction with their living situation and/or accommodation conditions that may promote association with criminal others.",
  },
  {
    id: '3.1.6',
    title: 'Reference Text',
    content:
      "The youth's parents are unwilling to co-operate in efforts to address the youth's problems. Do not include parents who are unable to cooperate (e.g., financially or otherwise).",
  },
  {
    id: '3.1.7',
    title: 'Reference Text',
    content:
      "The youth's family is facing difficulties or conflicts relating to cultural, ethnic, or religious adjustment. Examples include, but are not limited to, immigration issues, language barriers, or being the victims of racially motivated abuse.",
  },
  {
    id: '3.1.8',
    title: 'Reference Text',
    content:
      "The youth's father has engaged in physical, emotional, or sexual abuse of a family member.",
  },
  {
    id: '3.1.9',
    title: 'Reference Text',
    content:
      "The youth's mother has engaged in physical, emotional, or sexual abuse of a family member.",
  },
  {
    id: '3.1.10',
    title: 'Reference Text',
    content:
      "If there has been a death or illness, a family break-up, or a similar type of crisis in the youth's family. Do not include any events already noted in the previous items of this section.",
  },
  {
    id: '3.1.11',
    title: 'Reference Text',
    content:
      "If there are any other needs for the youth's family/parents not already listed in this section.",
  },
  {
    id: '3.2.1',
    title: 'Reference Text',
    content:
      'Select Yes if the youth is living on the street or is otherwise inappropriately housed.',
  },
  {
    id: '3.2.2',
    title: 'Reference Text',
    content:
      'The youth appears anxious in group settings and/or when alone. Include any official diagnosis for an anxiety disorder. Case management plans may include programs with little confrontation and individual counselling, as opposed to group treatment.',
  },
  {
    id: '3.2.3',
    title: 'Reference Text',
    content:
      'The youth has trouble communicating with others. Problems include, but are not limited to, speech disorders, disorders of written expression, or hearing impairment. Communication problems may interfere with daily activities and/or peer socialization. Problems may be a source of personal frustration, embarrassment, or other negative feelings. Communication problems influence choice of programs in case management plans (e.g., incorporating sign language into programs). Do not include language problems (see Cultural/Ethnic Issues).',
  },
  {
    id: '3.2.4',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has been found or suspected to have a history of cruelty to animals. Examples include, but are not limited to, neglect, physical torture, or killing of household pets or wild animals, and involvement in animal fighting competitions (e.g., dog fighting). Animal cruelty by youth is often a predictor of future violence against people, and often co-occurs in households with domestic violence and child abuse issues. Witnessing animal cruelty perpetrated by family members may also be noteworthy. Case management plans may include specific programs designed to cease animal cruelty and/or psychological counselling.',
  },
  {
    id: '3.2.5',
    title: 'Reference Text',
    content:
      'The youth is facing difficulties or conflicts relating to cultural, ethnic, or religious adjustment. Examples include, but are not limited to, immigration issues, language barriers, or being the victim of racially motivated abuse. Case management plans may include programs (e.g., language classes) designed to resolve cultural/ethnic issues.',
  },
  {
    id: '3.2.6',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has had an official diagnosis of any depressive disorder or shows signs of depression (e.g., sadness, decreased interest or pleasure in daily activities, unusual changes in appetite, weight, or sleeping patterns, fatigue, etc.). Feelings of depression may be so serious that participation in rewarding anticriminal activities is limited. Case management plans may include psychological counselling, medication, or other psychiatric involvement.',
  },
  {
    id: '3.2.7',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has received an official diagnosis of conduct disorder (CD) and/or oppositional defiant disorder (ODD). Diagnosis of any other disruptive behavior disorder may also be considered. Case management plans should include psychological counselling and programs designed to reduce aggression.',
  },
  {
    id: '3.2.8',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has been diagnosed as psychotic (e.g., schizophrenia). The diagnosis may not be criminogenic but has implications for case management (e.g., medication, psychiatric involvement, administrative segregation).',
  },
  {
    id: '3.2.9',
    title: 'Reference Text',
    content:
      'The youth seems unable/unwilling to admit guilt or unable/unwilling to admit to problems. Minimization of problems or circumstances may require preparatory programs.',
  },
  {
    id: '3.2.10',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has been officially diagnosed with fetal alcohol syndrome (FAS) or fetal alcohol effects (FAE). Presence of FAS/FAE may interfere with prosocial interactions and independent functioning. Emotional and cognitive deficits may require specialized programs and long-term management.',
  },
  {
    id: '3.2.11',
    title: 'Reference Text',
    content:
      'The youth is currently facing a financial and/or housing problem. Examples of financial problems include, but are not limited to, unmanageable debts and/or inadequate or inconsistent financial income or employment. Accommodation problems include personal dissatisfaction with living situation and/or accommodation conditions that may promote association with criminal others. Case management plans should consider programs that provide safe and affordable housing and/or employment programs.',
  },
  {
    id: '3.2.12',
    title: 'Reference Text',
    content:
      'Select Yes if the youth is a member of, or is otherwise closely affiliated with, a gang/criminal organization. Types of gangs include, but are not limited to, street gangs, motorcycle gangs, or organized crime groups. Gang involvement may be identified by certain gang signs, tattoos, attire (e.g., gang colors), graffiti, or known gang meeting locations.',
  },
  {
    id: '3.2.13',
    title: 'Reference Text',
    content:
      'Awareness of and sensitivity to gender issues including female health, child abuse, and cross-gender victimization. Do not include pregnancy or motherhood concerns (see Pregnancy; Parenting Issues). Gender-sensitive treatment and programs are recommended.',
  },
  {
    id: '3.2.14',
    title: 'Reference Text',
    content:
      'Select Yes if the youth is currently suffering from a medical problem that interferes with daily living. Do not include physical disabilities (see Physical Disability). Health problems may have implications for case management plans (e.g., requires access to medical resources).',
  },
  {
    id: '3.2.15',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has a history of violent assaults on teachers, parents, corrections personnel, or other authority figures.',
  },
  {
    id: '3.2.16',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has a history of bullying. Examples of bullying include, but are not limited to, verbal harassment, physical assaults, intimidation, exclusion, and cyber-bullying (e.g., harassment or ridiculing others through the internet, such as email or social networking sites). Bullies typically target the same victim or group of victims; in general, they target those who they perceive as a rival or in some way inferior to them (e.g., physically smaller, less socially popular). Case management plans should include programs designed to reduce bullying and aggression.',
  },
  {
    id: '3.2.17',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has a history of escape(s) or attempted escape(s) from custody facilities. Do not include running away from home (see History of Running Away).',
  },
  {
    id: '3.2.18',
    title: 'Reference Text',
    content: 'The youth has a history of arson or arson attempts.',
  },
  {
    id: '3.2.19',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has a history of running away from home or other supervised residence. Do not include escapes from institutional settings (see History of Escape).',
  },
  {
    id: '3.2.20',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has a history of directing sexual or physical assaults against others. Do not include violent assaults on authority figures (see History of Assault on Authority Figures).',
  },
  {
    id: '3.2.21',
    title: 'Reference Text',
    content:
      'The youth has a history of using a weapon of any type. Weapon use may be in the context of injuring or intimidating another person, the commission of another crime (e.g., armed robbery), or other situations.',
  },
  {
    id: '3.2.22',
    title: 'Reference Text',
    content:
      'Select Yes if the youth engages in illegal or otherwise inappropriate sexual activities (e.g., prostitution or exhibitionism).',
  },
  {
    id: '3.2.23',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has been officially diagnosed with a learning disability, which impairs his/her ability to function academically. Examples include, but are not limited to, disorders of written expression, reading, or mathematics. Disability may have implications for case management plans (e.g., placement in educational programs).',
  },
  {
    id: '3.2.24',
    title: 'Reference Text',
    content:
      'The youth exhibits or has been diagnosed with obvious and disabling intellectual deficits. Examples include, but are not limited to, mental retardation (i.e., IQ of 70 or below) or pervasive developmental disorder (e.g., autism spectrum disorder). Borderline intellectual functioning (IQ between 71 and 84) may also be considered. Below average verbal intelligence may preclude high-level verbal programs. Case management plans should consider psychological treatment or involvement with specific community-based support groups, such as those for the intellectually challenged.',
  },
  {
    id: '3.2.25',
    title: 'Reference Text',
    content:
      'The youth has little feeling of self-worth or has a poor self-concept. Select Yes if personal distress levels are so high that participation in rewarding anticriminal activities is limited.',
  },
  {
    id: '3.2.26',
    title: 'Reference Text',
    content:
      "Select Yes if the youth has a tendency to manipulate adults or other youth for personal gain. For example, the youth may coerce or convince others into performing certain criminal acts for them, or may simply admit to the enjoyment of 'using' or 'cheating' others.",
  },
  {
    id: '3.2.27',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has any children. Case management plans should include programs designed to assist young parents (e.g., medical, financial, child welfare).',
  },
  {
    id: '3.2.28',
    title: 'Reference Text',
    content:
      'Select Yes if the youth spends a lot of time with significantly younger/older persons, and these relationships contribute to risk and/or weaken ties to prosocial others.',
  },
  {
    id: '3.2.29',
    title: 'Reference Text',
    content:
      "The youth suffers from a disabling physical condition. Examples include any condition that affects the child's mobility (e.g., the child requires a wheelchair or other assistance). Disability may have implications for case management and/or accommodation in institutional or other supervised settings.",
  },
  {
    id: '3.2.30',
    title: 'Reference Text',
    content:
      'The youth has difficulty in resolving personal/social problems or does not cope well in interpersonal situations. For case management plans, consider individual counselling and programs with little confrontation, rather than group treatment.',
  },
  {
    id: '3.2.31',
    title: 'Reference Text',
    content:
      'The youth appears to function poorly in social situations or lacks normal social skills. Do not include issues with self-management skills (see Self-Management Skills). Case management plans should consider programs designed to improve social skills.',
  },
  {
    id: '3.2.32',
    title: 'Reference Text',
    content:
      'Select Yes if the youth is pregnant. Case management plans should include programs designed to assist teen pregnancies (e.g., medical, financial, child welfare).',
  },
  {
    id: '3.2.33',
    title: 'Reference Text',
    content:
      'Select Yes if child welfare or other protection agencies are involved with the youth.',
  },
  {
    id: '3.2.34',
    title: 'Reference Text',
    content:
      "Select Yes if the youth expresses antisocial attitudes about women or members of ethnic or religious groups. Racist/sexist expressions need not be at a level that directly promotes criminal activity; however, at a minimum, they weaken ties to prosocial others. Case management plans should consider programs that promote sensitivity to cultural and/or women's issues.",
  },
  {
    id: '3.2.35',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has difficulties with self-management that impact daily life. Examples include, but are not limited to, scheduling, completion of schoolwork, and task management. Deficits in self-management are unrelated to any other disability, deficit, or clinical diagnosis. Case management plans may require programs with high amounts of direct and personal supervision.',
  },
  {
    id: '3.2.36',
    title: 'Reference Text',
    content:
      'The youth has no significant relationships with others or does not appear motivated to form relationships. Shyness may interfere with participation in rewarding anticriminal activities. For case management plans, consider individual counselling rather than group treatment.',
  },
  {
    id: '3.2.37',
    title: 'Reference Text',
    content:
      "Check if the youth has a history of suicide attempts, suicidal thoughts, or deliberate self-injury (e.g., cutting, burning), suggesting that the input of a mental health professional is needed. Professionals may be sought to assist in case management planning. Case management may include psychiatric involvement and/or placing youth in administrative segregation (e.g., 'suicide watch').",
  },
  {
    id: '3.2.38',
    title: 'Reference Text',
    content:
      'The youth is at risk because of the influence of a third party or is threatened by a third party. For example, the activities of an associate—friend or foe—may have negative implications for case management through threat of violence from a third party. A third party, particularly an older person, may be perceived as having disproportionate control over the youth (e.g., pimp, cult leader, or gang leader).',
  },
  {
    id: '3.2.39',
    title: 'Reference Text',
    content:
      'The youth is performing below his/her capacity in school. Poor performance is primarily due to poor attitude toward school or lack of motivation, but not due to intellectual, learning, or other disability.',
  },
  {
    id: '3.2.40',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has been a victim of bullying. Victims of bullying often experience serious psychological and emotional distress. Effects often range from isolation and lowered self-esteem to rare extreme outcomes including aggression, depression, or suicide. Case management plans may include psychological counselling.',
  },
  {
    id: '3.2.41',
    title: 'Reference Text',
    content:
      'The youth is currently experiencing or has previously experienced neglect. Case management plans may include victim-oriented services.',
  },
  {
    id: '3.2.42',
    title: 'Reference Text',
    content:
      'Select Yes if the youth is currently experiencing or has previously experienced physical or sexual abuse. Case management plans may include victim-oriented services.',
  },
  {
    id: '3.2.43',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has witnessed violence among family members, but was not directly the victim of abuse. Domestic violence witnessed by the youth may be a source of emotional distress. Domestic violence may also necessitate involvement of child welfare agencies.',
  },
  {
    id: '3.2.44',
    title: 'Reference Text',
    content:
      'Select Yes if the youth has any other past/current mental health issues that have not already been specified. Examples include, but are not limited to, any other clinical diagnoses, psychiatric hospitalization, or medication for a psychiatric condition.',
  },
  {
    id: '3.2.45',
    title: 'Reference Text',
    content:
      "Specify any other special considerations or responsivity factors not already included in the YLS/CMI 2.0 that may influence the youth's case management plan.",
  },
];

/** ... */
export const data: DataGatherer.Tool = {
  id: 'YLS/CMI 2.0',
  measureId: 'bc224d76-46ed-4a81-a918-b57261c6023e',
  evaluatorId: '2d07184d-eed9-4ac4-87d1-662098c54627',
  version: '0.0.1',
  name: 'YLS/CMI 2.0',
  references,
  forms: [part1DemographicsAndUnscoredObservations, evaluationForm, part2Form],
};
