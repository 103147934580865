import React from 'react';
import { Modal } from 'react-bootstrap';

// import { openModal } from '@features/size/sizeSlice';
import { useAppSelector } from '@hooks';

import './ReferenceTextModal.scoped.scss';

/**
 * ...
 */
export interface ReferenceTextModalProps {
  text: string;
}

/**
 * ...
 */
export const ReferenceTextModal: React.FC<ReferenceTextModalProps> = (
  props
) => {
  // ...
  const height = useAppSelector(({ size }) => size.height);

  // ...
  const lines = props.text.split('\n');
  // ...
  const maxHeight = `calc(${height}px - (2 * 1.75rem))`;

  return (
    <>
      <Modal.Body style={{ maxHeight }}>
        {lines.map((line, i) => (
          <p key={i}>{line}</p>
        ))}
      </Modal.Body>
    </>
  );
};
