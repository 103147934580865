import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import './Overlay.scoped.scss';

/**
 * ...
 */
interface OverlayProps {
  active?: boolean;
  color?: string | null;
  blur?: string | number | null;
  background?: string | null;
  fullscreen?: boolean | null;
}

/**
 * ...
 */
export const Overlay: React.FC<OverlayProps> = (props) => {
  const [styles] = useState(getStyles(props));

  return (
    <CSSTransition appear in={props.active ?? true} timeout={300} unmountOnExit>
      <div className="overlay" style={styles}>
        {props.children}
      </div>
    </CSSTransition>
  );
};

// region Helper Functions

/**
 * ...
 *
 * @param props ...
 * @return ...
 */
function getStyles(props: OverlayProps) {
  const styles: Partial<React.CSSProperties> = {};

  if (props.color) {
    styles.color = props.color;
  }

  if (props.blur) {
    styles.backdropFilter = `blur(${props.blur}px)`;
  }

  if (!props.background) {
    styles.background = '#232323e0';
  } else if (props.background !== 'none') {
    styles.background = props.background;
  }


  if (props.fullscreen) {
    styles.position = 'fixed';
  }

  return styles;
}

// endregion Helper Functions
