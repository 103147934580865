import React from 'react';
import { hot } from 'react-hot-loader/root';
import { dataGatherer } from '@mhs/data-gatherer';

import { generateConfig } from '@dev-app/generate-dg-config';
import { ActionsPopup } from '@dev-app/components/ActionsPopup';
import { AlertPanel } from '@dev-app/components/AlertPanel';
import { ResultsSection } from '@dev-app/components/ResultsSection';

import * as tools from '../tools';

import './TestPage.scoped.scss';

/** `TestPage` component properties interface. */
type Props = Record<string, never>;

/** `TestPage` component state interface. */
interface State {
  isCreated: boolean;
  displayResultsView: boolean;
  config: DataGatherer.Config;
  payload: DataGatherer.Output | null;
}

const _TestPage = class TestPage extends React.Component<Props, State> {
  state = {
    isCreated: false,
    displayResultsView: false,
    config: generateConfig(),
    payload: null,
  };

  constructor(props: Props) {
    super(props);

    this.activateDataGatherer = this.activateDataGatherer.bind(this);
    this.deactivateDataGatherer = this.deactivateDataGatherer.bind(this);
    this.onEvaluationComplete = this.onEvaluationComplete.bind(this);
  }

  componentDidMount() {
    dataGatherer.on('activationCompleted', this.onActivationCompleted);
    dataGatherer.on('activationError', this.onActivationError);
    dataGatherer.on('evaluationCompleted', this.onEvaluationComplete);
    dataGatherer.on('evaluationDraftExported', this.onEvaluationDraftExported);

    void this.activateDataGatherer();
  }

  componentDidUpdate(_: Props, prevState: State) {
    // ...
    if (prevState.config !== this.state.config) {
      void this.refreashDataGatherer();
    }
  }

  /**
   * Activate Data Gatherer and mount widget onto page.
   */
  private async activateDataGatherer() {
    let error: unknown = null;

    console.time('dataGatherer - activation');

    tools.saveSelection(this.state.config.toolMeasureId);

    try {
      await dataGatherer.activate(this.state.config);
    } catch (err) {
      error = err;
    }

    console.timeEnd('dataGatherer - activation');

    if (error) return console.error(error);

    this.setState({ isCreated: true });
  }

  /**
   * Deactivate Data Gatherer and remove from page.
   */
  private async deactivateDataGatherer() {
    // ...
    await dataGatherer.deactivate();

    this.setState({ isCreated: false });
  }

  /**
   * ...
   */
  private async refreashDataGatherer() {
    // ...
    await this.deactivateDataGatherer();
    // ...
    await this.activateDataGatherer();
  }

  /**
   * ...
   */
  private resetView() {
    // ...
    this.setState({ payload: null, displayResultsView: false });

    void this.refreashDataGatherer();
  }

  /**
   * ...
   */
  private onConfigUpdated = (config: DataGatherer.Config) => {
    this.setState({ config });
  };

  /**
   * ...
   */
  private onActivationCompleted = () => {
    console.log('TEST PAGE -- activation completed!');
  };

  /**
   * ...
   */
  private onActivationError = (err: Error) => {
    console.log('TEST PAGE -- activation error!', err);
  };

  /**
   * ...
   */
  private onEvaluationComplete = (payload: DataGatherer.Output) => {
    console.log('TEST PAGE -- evaluation completed!', payload);

    this.setState({ payload });
  };

  /**
   * ...
   */
  private onEvaluationDraftExported = (draft: DataGatherer.Output) => {
    console.log(
      'TEST PAGE -- evaluation draft exported!',
      JSON.stringify(draft, null, 2)
    );
  };

  render() {
    const { isCreated, displayResultsView, config, payload } = this.state;

    // ...
    const actionsPopupProps: ActionsPopup.Props = {
      config,
      isCreated,
      onActivate: this.activateDataGatherer,
      onDeactivate: this.deactivateDataGatherer,
      onConfigUpdated: this.onConfigUpdated,
    };

    return (
      <div className="app-body">
        <AlertPanel
          active={!!payload}
          text="Data Gatherer evaluation received."
          button1={{
            text: 'View',
            onClick: () => this.setState({ displayResultsView: true }),
          }}
          button2={{
            text: 'Reset',
            onClick: () => this.resetView(),
          }}
        />

        <div className="page-section">
          <div className="panel full-width" id="data-gatherer"></div>
        </div>

        <ResultsSection
          active={displayResultsView}
          payload={payload}
          onClose={() => this.setState({ displayResultsView: false })}
        />

        {/* ... */}
        <ActionsPopup {...actionsPopupProps} />
      </div>
    );
  }
};

/** TestPage view component. */
export const TestPage = hot(_TestPage);
