/** ... */
const formA: DataGatherer.Form = {
  id: 'a',
  interpretAs: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  sourceId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  name: 'Form A',
  type: 'evaluation',
  rules: [
    {
      type: 'Omit',
      amount: 5,
      omitValue: '-1',
      ruleText: 'You may omit a maximum of 5 items.',
    },
  ],
  fields: [
    {
      id: '1',
      referenceId: '',
      label: 'A text question.',
      sequenceNumber: 1,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'evaluate',
    },
    {
      id: '2',
      referenceId: '',
      label: 'A text question..',
      sequenceNumber: 2,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'text',
      payloadType: 'evaluate',
      subFields: [
        {
          id: '3',
          referenceId: '',
          label: 'Number of prior convictions',
          sequenceNumber: 4,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
        },
        {
          id: '4',
          referenceId: '',
          label: 'Number of prior convictions',
          sequenceNumber: 5,
          required: true,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
        },
        {
          id: '5',
          referenceId: '',
          label: 'Number of prior convictions',
          sequenceNumber: 6,
          required: false,
          model: { type: '', payloadLocation: '' },
          type: 'text',
          payloadType: 'unscoredValues',
        },
      ],
    },
    {
      id: '6',
      referenceId: '',
      label: 'A dropdown question.',
      sequenceNumber: 3,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'evaluate',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
  ],
};

/** ... */
const formB: DataGatherer.Form = {
  id: 'b',
  interpretAs: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  sourceId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  name: 'Form B',
  type: 'unknown',
  fields: [
    {
      id: '7',
      referenceId: '',
      label: 'Any prior adult convictions?',
      sequenceNumber: 1,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'unscoredValues',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
    {
      id: '8',
      referenceId: '',
      label: 'Two or more prior adult convictions?',
      sequenceNumber: 2,
      required: true,
      model: { type: '', payloadLocation: '' },
      type: 'dropdown',
      payloadType: 'unscoredValues',
      answers: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
        { label: 'Omit', value: '-1' },
      ],
    },
  ],
};

/** ... */
export const data: DataGatherer.Tool = {
  id: 'test-tool',
  measureId: '00000000-0000-0000-0000-000000000001',
  evaluatorId: '123',
  version: '0.0.1',
  name: 'Test Tool',
  forms: [formA, formB],
  references: [{ id: '1.1', title: 'My Reference Title', content: '...' }],
};
