import { isString, isNumber, isBoolean, isErrorLike } from '@tools/type-gaurds';

/**
 * Ensures the output will always be an `Error`. If an error is passed in, it
 * will simply be returned. Otherwise, it will attempt to create an error using
 * the provided value.
 *
 * @param value Value to process.
 * @return An error.
 */
export function ensureError(value: unknown) {
  // ...
  if (value instanceof Error) {
    return value;
  }

  // ...
  if (isString(value) || isNumber(value) || isBoolean(value)) {
    return new Error(value.toString());
  }

  // ...
  if (isErrorLike(value)) {
    return new Error(value.message);
  }

  // ...
  return new Error('No message.');
}
