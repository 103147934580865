import React from 'react';

import './MhsLogo.scoped.scss';

/**
 * ...
 */
interface MhsLogoProps {
  color?: string;
  showGraphic?: boolean;
  showSubtext?: boolean;
}

/** ... */
const LogoText = () => (
  <>
    <path
      id="mhs-logo"
      d="M56.8235,52.9435a2.402,2.402,0,0,1-2.4384-2.4385V18.7494a2.3352,2.3352,0,0,1,.7032-1.7581,2.3991,2.3991,0,0,1,1.7352-.68,2.3642,2.3642,0,0,1,2.1583,1.3136L73.6612,47.0332H72.16L86.468,17.6245a2.3586,2.3586,0,0,1,2.1583-1.3136,2.3987,2.3987,0,0,1,1.7352.68,2.3352,2.3352,0,0,1,.7032,1.7581V50.505a2.319,2.319,0,0,1-.7274,1.7352,2.3829,2.3829,0,0,1-1.711.7033,2.4065,2.4065,0,0,1-2.4412-2.4385V25.5044l.94-.2344L74.836,50.3164a2.4275,2.4275,0,0,1-2.1124,1.2206,2.3456,2.3456,0,0,1-2.25-1.455L58.3244,25.7389l.9376-.2345V50.505a2.402,2.402,0,0,1-2.4385,2.4385Z"
    />
    <path
      id="mhs-logo-3"
      data-name="mhs-logo"
      d="M152.9323,53.4123a17.4346,17.4346,0,0,1-5.7231-.9377,14.8084,14.8084,0,0,1-4.7368-2.6271,10.3207,10.3207,0,0,1-2.9558-3.8935,1.8527,1.8527,0,0,1,.0242-1.8281,2.5768,2.5768,0,0,1,3.33-.8219,2.8721,2.8721,0,0,1,1.2421,1.3378,6.0905,6.0905,0,0,0,1.8538,2.157,10.6179,10.6179,0,0,0,3.12,1.5951,12.0591,12.0591,0,0,0,3.845.6089,11.8759,11.8759,0,0,0,4.1521-.7032,7.4229,7.4229,0,0,0,3.0717-2.04,4.8389,4.8389,0,0,0,1.1721-3.3075,6.1876,6.1876,0,0,0-1.9454-4.4553q-1.948-1.97-6.4047-2.44a16.0466,16.0466,0,0,1-9.1451-3.4233,8.9759,8.9759,0,0,1-3.33-7.0838,8.1077,8.1077,0,0,1,1.7353-5.2528,10.7719,10.7719,0,0,1,4.7126-3.2832,18.6532,18.6532,0,0,1,6.6391-1.1263,14.2889,14.2889,0,0,1,4.9956.7976,11.8431,11.8431,0,0,1,3.7291,2.2054,14.4824,14.4824,0,0,1,2.7672,3.33,2.9317,2.9317,0,0,1,.5389,1.9454,1.9233,1.9233,0,0,1-.9161,1.4308,2.1852,2.1852,0,0,1-1.9212.2344,2.6421,2.6421,0,0,1-1.5493-1.2192,8.3935,8.3935,0,0,0-1.8295-2.2984,8.1508,8.1508,0,0,0-2.5328-1.4779,9.8674,9.8674,0,0,0-3.4247-.5389,11.2673,11.2673,0,0,0-5.8146,1.2906,4.4086,4.4086,0,0,0-2.3469,4.1037,5.4148,5.4148,0,0,0,.7275,2.6972,5.84,5.84,0,0,0,2.6756,2.2283,17.4384,17.4384,0,0,0,5.6045,1.3122,14.7714,14.7714,0,0,1,8.7,3.4017,9.629,9.629,0,0,1,3.12,7.4812,9.3818,9.3818,0,0,1-1.102,4.6439,10.1666,10.1666,0,0,1-2.9559,3.3061,13.1526,13.1526,0,0,1-4.2222,1.9939A18.2487,18.2487,0,0,1,152.9323,53.4123Z"
    />
    <path
      id="mhs-logo-2"
      data-name="mhs-logo"
      d="M102.8854,52.9435a2.402,2.402,0,0,1-2.4385-2.4385V18.7494a2.3352,2.3352,0,0,1,.7032-1.7581,2.4275,2.4275,0,0,1,4.1737,1.7581V32.2109H127.09V18.7494a2.3349,2.3349,0,0,1,.7033-1.7581,2.3943,2.3943,0,0,1,1.7352-.68,2.3013,2.3013,0,0,1,1.7352.68,2.4292,2.4292,0,0,1,.6575,1.7581V50.505a2.4541,2.4541,0,0,1-.6575,1.7352,2.2637,2.2637,0,0,1-1.7352.7033A2.4022,2.4022,0,0,1,127.09,50.505V36.5261H105.3238V50.505a2.4029,2.4029,0,0,1-.679,1.7352A2.3418,2.3418,0,0,1,102.8854,52.9435Z"
    />
  </>
);

/** ... */
const LogoGraphic = () => (
  <>
    <path
      id="mhs-logo-4"
      data-name="mhs-logo"
      d="M29.2969,14.2707h6.2293a1.0949,1.0949,0,0,1,1.0948,1.0948V49.9768a3.4355,3.4355,0,0,1-3.4355,3.4355H29.2969Z"
    />
    <path
      id="mhs-logo-5"
      data-name="mhs-logo"
      d="M21.9727,4.59h6.23a1.0938,1.0938,0,0,1,1.0938,1.0937V53.4123H21.9727Z"
    />
    <path
      id="mhs-logo-6"
      data-name="mhs-logo"
      d="M8.42,7.6129h6.2283V53.4123H7.3244V8.7088A1.0959,1.0959,0,0,1,8.42,7.6129Z"
    />
    <path
      id="mhs-logo-7"
      data-name="mhs-logo"
      d="M7.32,53.4079H3.44A3.4313,3.4313,0,0,1,0,49.978v-20.64a.3374.3374,0,0,1,.34-.34H3.66Z"
    />
    <path
      id="mhs-logo-8"
      data-name="mhs-logo"
      d="M15.757,0h5.0972a1.1185,1.1185,0,0,1,1.1185,1.1185V53.4123H14.6485V1.1085A1.1085,1.1085,0,0,1,15.757,0Z"
    />
    <polygon
      id="mhs-logo-9"
      data-name="mhs-logo"
      points="7.32 28.998 7.32 53.408 3.66 28.998 7.32 28.998"
    />
  </>
);

/** ... */
const LogoSubtext = () => (
  <>
    <path
      id="mhs-logo-10"
      data-name="mhs-logo"
      d="M54.92,72.0451a.4851.4851,0,0,1-.5038-.5038V62.449a.4873.4873,0,0,1,.5038-.5052h2.5864a3.3607,3.3607,0,0,1,1.6952.421,3.001,3.001,0,0,1,1.1508,1.1577,3.4341,3.4341,0,0,1,.414,1.7065,3.3488,3.3488,0,0,1-.414,1.6756,3.0216,3.0216,0,0,1-1.1508,1.1508,3.36,3.36,0,0,1-1.6952.42H55.4235v3.0663a.4893.4893,0,0,1-.1418.3621A.4951.4951,0,0,1,54.92,72.0451Zm.5038-4.54h2.0826a2.363,2.363,0,0,0,1.1774-.2905,2.0727,2.0727,0,0,0,.8083-.8084,2.3617,2.3617,0,0,0,.2905-1.1774,2.4175,2.4175,0,0,0-.2905-1.2026,2.1281,2.1281,0,0,0-.8083-.8154,2.3219,2.3219,0,0,0-1.1774-.2961H55.4235Z"
    />
    <path
      id="mhs-logo-11"
      data-name="mhs-logo"
      d="M66.3528,72.2514a4.6441,4.6441,0,0,1-2.09-.4519,3.4689,3.4689,0,0,1-1.4216-1.2546,3.34,3.34,0,0,1-.5108-1.837V62.449a.4873.4873,0,0,1,.5038-.5052.4911.4911,0,0,1,.3621.1431.4853.4853,0,0,1,.1417.3621v6.2589a2.4032,2.4032,0,0,0,.3817,1.3459,2.5712,2.5712,0,0,0,1.068.9107,3.5541,3.5541,0,0,0,1.5647.33,3.4411,3.4411,0,0,0,1.5381-.33,2.5962,2.5962,0,0,0,1.0553-.9107,2.4023,2.4023,0,0,0,.38-1.3459V62.449a.5172.5172,0,0,1,.8743-.3621.5027.5027,0,0,1,.1347.3621v6.2589a3.34,3.34,0,0,1-.5108,1.837A3.4952,3.4952,0,0,1,68.4157,71.8,4.5247,4.5247,0,0,1,66.3528,72.2514Z"
    />
    <path
      id="mhs-logo-12"
      data-name="mhs-logo"
      d="M72.8731,72.0451a.6068.6068,0,0,1-.4069-.1361.4533.4533,0,0,1-.1614-.3677V62.449a.4873.4873,0,0,1,.5038-.5052h3.1435a2.9762,2.9762,0,0,1,1.4019.33,2.617,2.617,0,0,1,.9964.8995,2.3737,2.3737,0,0,1,.3691,1.3192,2.1249,2.1249,0,0,1-.3761,1.2419,2.4076,2.4076,0,0,1-.9823.8266,2.6569,2.6569,0,0,1,1.3963.8729,2.4626,2.4626,0,0,1,.53,1.6237,2.9755,2.9755,0,0,1-.4,1.5521,2.8334,2.8334,0,0,1-1.1058,1.0539,3.3371,3.3371,0,0,1-1.6111.3817Zm.4393-5.8842h2.64a1.879,1.879,0,0,0,1.28-.4407,1.5378,1.5378,0,0,0,.5038-1.2279,1.4127,1.4127,0,0,0-.5038-1.1774,1.9981,1.9981,0,0,0-1.28-.4h-2.64Zm0,4.9145H76.171a2.3924,2.3924,0,0,0,1.1-.2456,1.8366,1.8366,0,0,0,.7493-.6989,2.0469,2.0469,0,0,0,.2723-1.0735,1.8712,1.8712,0,0,0-.2723-1.0146,1.8358,1.8358,0,0,0-.7493-.6737,2.4461,2.4461,0,0,0-1.1-.2385H73.3124Z"
    />
    <path
      id="mhs-logo-13"
      data-name="mhs-logo"
      d="M81.3537,72.0451a.4976.4976,0,0,1-.3551-.1417.4794.4794,0,0,1-.1487-.3621V62.449a.5038.5038,0,0,1,1.0076,0v8.639h5.0577a.5038.5038,0,0,1,.355.1362.4683.4683,0,0,1,0,.6848.5042.5042,0,0,1-.355.1361Z"
    />
    <path
      id="mhs-logo-14"
      data-name="mhs-logo"
      d="M89.5409,72.0451a.4852.4852,0,0,1-.5039-.5038V62.449a.4874.4874,0,0,1,.5039-.5052.4907.4907,0,0,1,.362.1431.4854.4854,0,0,1,.1418.3621v9.0923a.49.49,0,0,1-.1418.3621A.4951.4951,0,0,1,89.5409,72.0451Z"
    />
    <path
      id="mhs-logo-15"
      data-name="mhs-logo"
      d="M96.5058,72.1742a4.803,4.803,0,0,1-1.9591-.4013,5.0043,5.0043,0,0,1-1.604-1.1185,5.3466,5.3466,0,0,1-1.0862-5.6639,5.0742,5.0742,0,0,1,1.0736-1.649,5.1656,5.1656,0,0,1,1.604-1.12,4.7916,4.7916,0,0,1,1.9717-.4069,5.0316,5.0316,0,0,1,1.6953.2722,4.8459,4.8459,0,0,1,1.4735.8659.33.33,0,0,1,.1361.2133.3886.3886,0,0,1-.0323.233.3286.3286,0,0,1-.1557.1613.2307.2307,0,0,1-.1291.1558.4286.4286,0,0,1-.2386.0393.5071.5071,0,0,1-.2653-.1165,3.4513,3.4513,0,0,0-1.0988-.6539,4.1134,4.1134,0,0,0-1.3851-.2133,3.7035,3.7035,0,0,0-1.57.3368,4.1794,4.1794,0,0,0-1.2869.9178,4.3028,4.3028,0,0,0-.8729,1.3458,4.1565,4.1565,0,0,0-.3172,1.6166,4.2273,4.2273,0,0,0,.3172,1.642,4.2816,4.2816,0,0,0,.8729,1.3444,4.0749,4.0749,0,0,0,1.2869.9122,3.7642,3.7642,0,0,0,1.57.33,3.8594,3.8594,0,0,0,1.3458-.2329,4.4671,4.4671,0,0,0,1.2027-.6722.4113.4113,0,0,1,.33-.1039.5454.5454,0,0,1,.3045.1558.4559.4559,0,0,1,.1291.3368.5276.5276,0,0,1-.0322.1867.6377.6377,0,0,1-.1109.1754,3.8186,3.8186,0,0,1-1.4735.8588A5.6936,5.6936,0,0,1,96.5058,72.1742Z"
    />
    <path
      id="mhs-logo-16"
      data-name="mhs-logo"
      d="M108.83,72.1742a4.769,4.769,0,0,1-1.5325-.2385,3.71,3.71,0,0,1-1.2223-.6793,3.0145,3.0145,0,0,1-.7887-1.0216.3893.3893,0,0,1-.0056-.3817.5116.5116,0,0,1,.33-.24.47.47,0,0,1,.355.0393.6006.6006,0,0,1,.2653.2723,2.0651,2.0651,0,0,0,.57.6722,2.7472,2.7472,0,0,0,.8912.4589,3.8017,3.8017,0,0,0,1.1381.1613,3.0887,3.0887,0,0,0,1.2167-.2259,1.903,1.903,0,0,0,.8336-.66,1.7928,1.7928,0,0,0,.3045-1.0539,1.8331,1.8331,0,0,0-.5824-1.3584,3.1084,3.1084,0,0,0-1.8229-.7242,4.2454,4.2454,0,0,1-2.3675-.9374,2.3886,2.3886,0,0,1-.8532-1.8567,2.2889,2.2889,0,0,1,.42-1.371,2.7207,2.7207,0,0,1,1.1577-.8982,4.2455,4.2455,0,0,1,1.6939-.3171,3.5565,3.5565,0,0,1,1.2939.2259,3.5114,3.5114,0,0,1,1.0146.595,3.4528,3.4528,0,0,1,.7059.8084.526.526,0,0,1,.0842.4013.4529.4529,0,0,1-.2274.2975.5393.5393,0,0,1-.6974-.22,2.947,2.947,0,0,0-.4856-.5627,2.188,2.188,0,0,0-.7045-.42,3.0215,3.0215,0,0,0-.9964-.1684,2.8133,2.8133,0,0,0-1.6419.4336,1.441,1.441,0,0,0-.6217,1.26,1.49,1.49,0,0,0,.233.8083,1.84,1.84,0,0,0,.77.6343,4.8421,4.8421,0,0,0,1.4413.3944,4.26,4.26,0,0,1,2.4.9894,2.6252,2.6252,0,0,1,.7817,1.9731,2.9317,2.9317,0,0,1-.2512,1.24,2.5112,2.5112,0,0,1-.7115.9122,3.2622,3.2622,0,0,1-1.0736.5628A4.3473,4.3473,0,0,1,108.83,72.1742Z"
    />
    <path
      id="mhs-logo-17"
      data-name="mhs-logo"
      d="M113.732,72.0451a.4456.4456,0,0,1-.4266-.2456.5265.5265,0,0,1-.0253-.4785l3.8017-9.0278a.5008.5008,0,0,1,.4912-.3494.4906.4906,0,0,1,.4785.3494l3.8157,9.04a.5018.5018,0,0,1-.4518.7115.5124.5124,0,0,1-.2793-.0772.5569.5569,0,0,1-.1993-.2329l-3.5575-8.6264h.4014l-3.5828,8.6264a.4838.4838,0,0,1-.188.2329A.5046.5046,0,0,1,113.732,72.0451Zm.8546-2.4053.3874-.8673h5.2513l.3873.8673Z"
    />
    <path
      id="mhs-logo-18"
      data-name="mhs-logo"
      d="M123.4165,72.0451a.5077.5077,0,0,1-.5052-.5038V62.449a.4895.4895,0,0,1,.5052-.5052h6.0653a.5087.5087,0,0,1,.3621.1361.4684.4684,0,0,1,.1417.3565.4544.4544,0,0,1-.1417.3354.4911.4911,0,0,1-.3621.1431H123.92v3.5687h4.0487a.4948.4948,0,0,1,.362.1418.5014.5014,0,0,1,0,.6988.49.49,0,0,1-.362.1432H123.92v4.0739a.5.5,0,0,1-.1417.3551A.48.48,0,0,1,123.4165,72.0451Z"
    />
    <path
      id="mhs-logo-19"
      data-name="mhs-logo"
      d="M131.7469,72.0451a.4851.4851,0,0,1-.5038-.5038V62.449a.4873.4873,0,0,1,.5038-.5052h6.0653a.5138.5138,0,0,1,.3564.1361.4635.4635,0,0,1,.1488.3565.4507.4507,0,0,1-.1488.3354.4959.4959,0,0,1-.3564.1431h-5.5615v3.5687h4.0487a.4948.4948,0,0,1,.362.1418.5014.5014,0,0,1,0,.6988.49.49,0,0,1-.362.1432h-4.0487v3.608h5.5615a.5.5,0,0,1,.3564.1417.4764.4764,0,0,1,0,.6919.5075.5075,0,0,1-.3564.1361Z"
    />
    <path
      id="mhs-logo-20"
      data-name="mhs-logo"
      d="M139.558,62.863a.4557.4557,0,0,1-.3368-.13.4392.4392,0,0,1-.1292-.3228.4431.4431,0,0,1,.466-.4659H146.71a.4429.4429,0,0,1,.4659.4659.4391.4391,0,0,1-.1291.3228.4515.4515,0,0,1-.3368.13Zm3.57,9.1821a.5077.5077,0,0,1-.5052-.5038V61.9438h1.009v9.5975a.5.5,0,0,1-.1417.3551A.48.48,0,0,1,143.1281,72.0451Z"
    />
    <path
      id="mhs-logo-21"
      data-name="mhs-logo"
      d="M152.0028,72.0451a.5079.5079,0,0,1-.5038-.5038V68.308l-3.5182-5.5755a.4979.4979,0,0,1-.0968-.3747.5033.5033,0,0,1,.1614-.2975.465.465,0,0,1,.3228-.1165.4555.4555,0,0,1,.2526.0645.6337.6337,0,0,1,.174.1811l3.4929,5.626h-.5431l3.4789-5.5994a.6084.6084,0,0,1,.2007-.2077.523.523,0,0,1,.2653-.0645.4128.4128,0,0,1,.3031.1235.4873.4873,0,0,1,.1417.3031.5348.5348,0,0,1-.0968.3621l-3.5309,5.5755v3.2333a.5077.5077,0,0,1-.5038.5038Z"
    />
  </>
);

/**
 * ...
 */
export const MhsLogo: React.FC<MhsLogoProps> = (props) => {
  // ...
  const classNames: string[] = ['mhs-logo'];

  // ..
  const color = props.color ?? undefined;
  // ...
  const fill = color ? 'currentColor' : undefined;

  if (color) {
    classNames.push('style-solid');
  }

  // ...
  const text = (
    <g className="logo-text" style={{ fill }}>
      <LogoText />
    </g>
  );

  // ...
  const graphic =
    props.showGraphic === false ? null : (
      <g className="logo-graphic">
        <LogoGraphic />
      </g>
    );

  // ...
  const subtext = !props.showSubtext ? null : (
    <g className="logo-subtext" style={{ fill }}>
      <LogoSubtext />
    </g>
  );

  return (
    <div className={classNames.join(' ')} style={{ color }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.1136 72.2514">
        {/* Logo Text Component */}
        {text}
        {/* Logo Graphic Component */}
        {graphic}
        {/* Logo Subtext Component */}
        {subtext}
      </svg>
    </div>
  );
};
