import React from 'react';
import { Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExpandAlt,
  faCompressAlt,
} from '@fortawesome/pro-regular-svg-icons';

import { setFullscreenMode } from '@features/settings/settingsSlice';
import { useAppSelector, useAppDispatch } from '@hooks';

/**
 * ...
 */
export const FullscreenModeButton: React.FC = () => {
  // ...
  const isFullscreen = useAppSelector(({ settings }) => settings.fullscreen);
  // ...
  const dispatch = useAppDispatch();

  const icon = isFullscreen ? faCompressAlt : faExpandAlt;

  const onClick = () => dispatch(setFullscreenMode(!isFullscreen));

  return (
    <Button className="dg-icon-btn" onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};