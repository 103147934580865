import React, { useState, useRef } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisHAlt } from '@fortawesome/pro-regular-svg-icons';
import { dataGatherer, Configuration } from '@mhs/data-gatherer';

import { ConfigEditorModal } from '@dev-app/components/ConfigEditorModal';

import './ActionsPopup.scoped.scss';

type Component = React.FC<ActionsPopup.Props>;

/**
 * ...
 */
export const ActionsPopup: Component = (props) => {
  // ...
  const modalRef = useRef<ConfigEditorModal.Ref>();
  // ...
  const [isActive, setIsActive] = useState(false);

  // ...
  const stateButton = props.isCreated ? (
    <ListGroup.Item variant="danger" onClick={props.onDeactivate}>
      Deactivate
    </ListGroup.Item>
  ) : (
    <ListGroup.Item
      variant="success"
      disabled={dataGatherer.activating}
      onClick={props.onActivate}
    >
      Activate
    </ListGroup.Item>
  );

  const summonModal = () => {
    modalRef.current?.show();
  };

  const popupList = !isActive ? null : (
    <ListGroup className="list-group-popup">
      {stateButton}
      <ListGroup.Item action onClick={summonModal}>
        Edit Configuration
      </ListGroup.Item>
    </ListGroup>
  );

  return (
    <>
      <div className="actions-popup">
        <Button
          variant="primary"
          className="btn-popup"
          title="Data Gatherer Actions"
          onClick={() => setIsActive(!isActive)}
        >
          <FontAwesomeIcon icon={faEllipsisHAlt} />
        </Button>
        {/** ... */}
        {popupList}
      </div>
      {/** ... */}
      <ConfigEditorModal
        ref={modalRef}
        config={props.config}
        onConfigUpdated={props.onConfigUpdated}
      />
    </>
  );
};

export namespace ActionsPopup {
  /** ... */
  export interface Props {
    /** ... */
    config: Configuration;
    /** ... */
    isCreated: boolean;
    /** ... */
    onActivate: () => void;
    /** ... */
    onDeactivate: () => void;
    /** ... */
    onConfigUpdated: (config: Configuration) => void;
  }
}
