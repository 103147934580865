import { uniqueId } from 'lodash';

import { DATA_KEYS } from '@tools/get-mock-data';

/** ... */
export const options = Object.entries(DATA_KEYS).map(([key, value]) => ({
  id: uniqueId(),
  label: value.label,
  value: key,
}));

/**
 * ...
 *
 * @param value ...
 */
export function saveSelection(value: string) {
  localStorage.setItem('selectedTool', value);
}

/**
 * ...
 *
 * @return ...
 */
export function getLastSelection() {
  // ...
  const cachedValue = localStorage.getItem('selectedTool') as unknown;

  // ...
  const match = options.find(({ value }) => value === cachedValue);

  return (match ?? options[0]).value;
}
