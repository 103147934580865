import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';

import { selectClientDetails } from '@features/config/configSlice';
import { useAppSelector } from '@hooks';

import './ClientInfoButton.scoped.scss';

/**
 * ...
 */
export const ClientInfoButton: React.FC = () => {
  const details = useAppSelector(selectClientDetails);

  // ...
  const fullName = [details?.fName ?? '', details?.lName ?? '']
    .filter((o) => o)
    .join(' ');
  // ...
  const dob = details?.dob ?? '';
  // ...
  const ethnicity = details?.ethnicity ?? '';

  if (!fullName && !dob && !ethnicity) return null;

  return (
    <Dropdown className="user-info-btn" align="end">
      <Dropdown.Toggle
        className="d-inline-flex align-items-center dg-icon-btn"
        style={{ color: 'inherit' }}
      >
        {fullName && <span className="client-name-label">{fullName}</span>}
        <FontAwesomeIcon icon={faUserCircle} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.ItemText>
          <span>Client Information</span>
        </Dropdown.ItemText>
        <Dropdown.Divider />
        <Dropdown.ItemText>
          <span className="client-detail client-name">
            Name:
            <span className="detail-value">{fullName || '--'}</span>
          </span>
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <span className="client-detail">
            Date of Birth:
            <span className="detail-value">{dob || '--'}</span>
          </span>
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <span className="client-detail">
            Ethnicity:
            <span className="detail-value">{ethnicity || '--'}</span>
          </span>
        </Dropdown.ItemText>
      </Dropdown.Menu>
    </Dropdown>
  );
};
