import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * ...
 */
export interface SizeState {
  width: number;
  height: number;
}

const initialState: SizeState = { width: 0,
  height: 0
};

/**
 * ...
 */
export const sizeSlice = createSlice({
  name: 'size',
  initialState,
  reducers: {
    /** ... */
    set: (_, { payload }: PayloadAction<SizeState>) => {
      return payload;
    }
  }
});

// region Actions

/**
 * Set a width and height that represents the size of the Data Gatherer's
 * HTML element.
 *
 * @param width Width of the element in pixels.
 * @param height Height of the element in pixels.
 */
export function setSize(width: number, height: number) {
  return sizeSlice.actions.set({ width, height });
}

// endregion Actions
