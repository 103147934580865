/**
 * ...
 */
interface ErrorLike {
  name: string;
  message: string;
  stack?: string;
}

/**
 * Determine if a value is a string.
 *
 * @param value The value to check.
 * @return `true` if the value is an string, otherwise `false`.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * Determine if a value is a number.
 *
 * @param value The value to check.
 * @return `true` if the value is an number, otherwise `false`.
 */
export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

/**
 * Determine if a value is a boolean.
 *
 * @param value The value to check.
 * @return `true` if the value is an boolean, otherwise `false`.
 */
export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

/**
 * Determine if a value is a function.
 *
 * @param value The value to check.
 * @return `true` if the value is an function, otherwise `false`.
 */
export function isFunction(value: unknown): value is GenericFunction {
  return typeof value === 'function';
}

/**
 * Determine if a value is a standard object.
 *
 * @param value The value to check.
 * @return `true` if the value is an object, otherwise `false`.
 */
export function isObject(value: unknown): value is GenericObject {
  return value !== null && typeof value === 'object';
}

/**
 * Determine if a value is an array.
 *
 * @param value The value to check.
 * @return `true` if the value is an object, otherwise `false`.
 */
export function isArray(value: unknown): value is Array<unknown> {
  return Array.isArray(value);
}

/**
 * Determine if a value is `undefined`.
 *
 * @param value The value to check.
 * @return `true` if the value is `undefined`, otherwise `false`.
 */
export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

/**
 * Determine if a value is `null`.
 *
 * @param value The value to check.
 * @return `true` if the value is `null`, otherwise `false`.
 */
export function isNull(value: unknown): value is null {
  return value === null;
}

/**
 * Determine if a value is nullish.
 *
 * @param value The value to check.
 * @return `true` if the value is nullish, otherwise `false`.
 */
export function isNullish(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}

/**
 * Determine if a value is an `Error`.
 *
 * @param value The value to check.
 * @return `true` if the value is `Error`, otherwise `false`.
 */
export function isError(value: unknown): value is Error {
  return value instanceof Error;
}

/**
 * Determine if a value is an object that contains properties shared by `Error`.
 *
 * @param value The value to check.
 * @return `true` if the value is error-like, otherwise `false`.
 */
export function isErrorLike(value: unknown): value is ErrorLike {
  if (!isObject(value)) return false;

  return typeof value.name === 'string' && typeof value.message === 'string';
}
