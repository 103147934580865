import { devSubscriptionKey } from '@config/dev';
// import SampleLogo from '@assets/little-arms-studios-logo.svg';

import * as tools from './tools';

/** ... */
const generateClientDetails = () => ({
  id: '12345',
  fName: 'John',
  lName: 'Smith',
  dob: '1/1/2000',
  ethnicity: 'Hispanic',
});

/** ... */
const generateStyleOptions = () => ({
  primaryColor: 'linear-gradient(-45deg, #1ac797, #84c52d)',
  secondaryColor: 'blue',
});

/**
 * ...
 *
 * @param values ...
 * @return ...
 */
export function generateConfig(values?: Partial<DataGatherer.Config>) {
  // return bugTestConfig;

  // ...
  const janusCredentials = {
    subscriptionKey: devSubscriptionKey,
  };

  // ...
  const clientDetails = generateClientDetails();

  // ...
  const styleOptions = generateStyleOptions();

  // ...
  const formData: DataGatherer.Form.DataItem[] = [
    {
      formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
      fieldId: 'HHN0d5nu',
      value: 'Jane Doe',
    },
  ];

  // ...
  const customFormLabels: DataGatherer.Form.CustomLabel[] = [
    {
      formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
      label: "Dane's Test Label",
    },
  ];

  const config: DataGatherer.Config = {
    toolMeasureId: tools.getLastSelection(),
    node: 'data-gatherer',
    janusCredentials,
    // clientDetails,
    // styleOptions,
    // logoUrl: SampleLogo,
    // enableLocalCacheing: false,
    // // appearance: 'dark',
    // formData,
    // hiddenForms: ['f52e5164-7ac9-4d69-8f2c-41b6e162891e'],
    // // hiddenFields: ['5F0lnNHq'],
    // customFormLabels,

    enableExportDraftFeature: true,
  };

  return Object.assign(config, values ?? {}) as DataGatherer.Config;
}

/**
 * ...
 */
const bugTestConfig: DataGatherer.Config = {
  enableExportDraftFeature: true,
  toolMeasureId: 'ca161b12-e6a0-4086-882d-a7f1dce51aa5',
  toolName: 'lsi-r',
  node: 'data-gatherer',
  janusCredentials: {
    subscriptionKey: '139a3b584ab94e2b998c32fcc9ea8eab',
  },
  // styleOptions: {
  //   primaryColor: '#ffffff',
  //   secondaryColor: '#21FF29',
  //   primaryTextColor: '#161460',
  // },
  // formData: [
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'l9sw4igy',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'qbSDyABn',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '1RSOzktg',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'zNvqaNtQ',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '7tf16Fc9',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'EztQ6Gta',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '12QBb9kc',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '9C3puLFh',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'DAUiZ7mJ',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '5TxaVmCp',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'gLwj8oFx',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '66rGmHSb',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'mPDQGVB3',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'JcjKRwKn',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'zzsRbHPo',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'NaZf7SLQ',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'zgEhAA5I',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'th7fCMY2',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'bL36s22f',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'CMihq8o1',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'JsguIuLs',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'wgR2W9uH',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '4bOlQJDD',
  //     value: '2',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'u0xV4o36',
  //     value: '2',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'bz2Sn9r0',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'C72XHETt',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'EbPBcZDA',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '1lkiVfSi',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'dz6X4bk5',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '22z3vqwN',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'QfOJnVym',
  //     value: '2',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'BnCMKAIy',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'hfaL4msR',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'B3lFbTxn',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '3rKD7DLl',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'M4kwllzd',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '6Y8K5jca',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'FRz3mLrs',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'AMqE0VKV',
  //     value: '3',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'Adf0f2S4',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'zcAnwGMf',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'ALDOdzrn',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '17ppEOJ2',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'V7WA30op',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '9OGrmIbM',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'YyqLwTWf',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '5JzTIynC',
  //     value: '-1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'faHimskU',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'os1rTP0Q',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '5mfz4Wuf',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'cWkukeev',
  //     value: '1',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'dxlslR61',
  //     value: '3',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: 'lXTg7cvm',
  //     value: '0',
  //   },
  //   {
  //     formId: 'cb9f6923-ba70-4d26-896b-6ed627ee3217',
  //     fieldId: '6DTYDoBH',
  //     value: '0',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'HHN0d5nu',
  //     value: ', Final ',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'RDCLhqqN',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'yCrQpDIB',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'tVQIjRrD',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'yJzSe2SF',
  //     value: '183',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'thb5KV0f',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'Q9PBcyUT',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'OHBqEt1J',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'fYFmiQ4T',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'M3gMphRw',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'NRNczH0y',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'a8myPtAv',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '4J0bjSEy',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'wIMzo7LN',
  //     value: '02/07/2022',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'rQXuuBcP',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'emCo0bPh',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'Pt2QeKI5',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'EaWQy6xu',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'SSHGwtIN',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '5F0lnNHq',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'B3cMrF6x',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'GgKul4Cj',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '7s7wyakw',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'uqjRfCyO',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'zXOohakN',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '4IIq4b9y',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'YI0ps7YU',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '5Uiy1jdU',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'Q02fgkGI',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'HAI1GOAX',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'mcFrvDWu',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'L7Zwz2fg',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'OwsROqnM',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'jhJaG6RL',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '5wvHNAU2',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'kfBfENK5',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'WQvMCnoJ',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'EoGMv6zP',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: '9IeKPmxV',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'dI0kKlMO',
  //   },
  //   {
  //     formId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
  //     fieldId: 'a5dAG1PI',
  //   },
  // ],
  // hiddenFields: [
  //   'SSHGwtIN',
  //   '5F0lnNHq',
  //   'B3cMrF6x',
  //   'GgKul4Cj',
  //   '7s7wyakw',
  //   'uqjRfCyO',
  //   'zXOohakN',
  //   '4IIq4b9y',
  //   'YI0ps7YU',
  //   '5Uiy1jdU',
  //   'Q02fgkGI',
  //   'HAI1GOAX',
  //   'mcFrvDWu',
  //   'L7Zwz2fg',
  //   '5wvHNAU2',
  //   'kfBfENK5',
  //   'EoGMv6zP',
  //   '9IeKPmxV',
  //   'dI0kKlMO',
  //   'a5dAG1PI',
  // ],
  // hiddenForms: ['f52e5164-7ac9-4d69-8f2c-41b6e162891e'],
  // hideHeader: true,
};
