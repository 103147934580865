import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as errorSlice from '@features/error/errorSlice';
import * as evaluationSlice from '@features/evaluation/evaluationSlice';
import { Evaluation } from '@features/evaluation/evaluationTypes';
import * as themeSlice from '@features/theme/themeSlice';
import * as viewSlice from '@features/view/viewSlice';
import { RootState } from '@store';
import { ensureError } from '@tools/ensure-error';
import { isString } from '@tools/type-gaurds';
import { createLogger } from '@utils/logger';

const logger = createLogger('setup-slice');

/** ... */
export interface SetupState {
  running: boolean;
  complete: boolean;
}

/**
 * ...
 */
class InvalidPropertyTypeError extends Error {
  constructor(property: string, neededType: string) {
    super(
      `Invalid configuration property. Property "${property}" must be of type "${neededType}".`
    );
  }
}

/**
 * ...
 */
export const runSetup = createAsyncThunk<boolean, void, { state: RootState }>(
  'setup/run',
  async (_, { getState, dispatch }) => {
    // ...
    dispatch(errorSlice.clearError());

    const { config } = getState();

    logger.info('run - config', config);

    let error: Error | null = null;

    try {
      if (!config) {
        throw new Error('config has not been set yet.');
      }

      // ...
      if (!isString(config.toolMeasureId)) {
        throw new InvalidPropertyTypeError('toolMeasureId', 'string');
      }

      // ...
      if (!isString(config.janusCredentials?.subscriptionKey)) {
        throw new InvalidPropertyTypeError(
          'janusCredentials.subscriptionKey',
          'string'
        );
      }

      // ...
      if ('apiVersion' in config && !isString(config.apiVersion)) {
        throw new InvalidPropertyTypeError('apiVersion', 'string');
      }

      // ...
      if (config.styleOptions) {
        dispatch(
          themeSlice.setColors(validateStyleOptions(config.styleOptions))
        );
      }

      // ...
      if (config.logoUrl) {
        dispatch(themeSlice.setLogo({ logoUrl: config.logoUrl }));
      }

      // Initialize evaluation.
      //
      // TODO: Add better evaluation validation -- ensure required fields
      // are valid.
      await dispatch(evaluationSlice.setupEvaluation());

      // ...
      const { evaluation } = getState();

      if (!evaluation) {
        throw new Error(`Unable to retrieve evaluation data.`);
      }

      // ...
      if (config.hiddenFields) {
        validateHiddenFields(evaluation.fields, config.hiddenFields);
      }

      // ...
      if (config.hiddenForms) {
        validateHiddenForms(
          evaluation.forms,
          evaluation.fields,
          config.hiddenForms
        );
      }

      // ...
      if (config.formData) {
        dispatch(
          evaluationSlice.setInputValues(
            processFormData(evaluation.fields, config.formData)
          )
        );
      }
    } catch (err) {
      error = ensureError(err);
    }

    if (error) {
      dispatch(errorSlice.setError('Something went wrong.', error.message));
    }

    dispatch(viewSlice.setView(error ? 'error' : 'evaluation'));

    return error ? false : true;
  }
);

/**
 * ...
 */
export const setupSlice = createSlice({
  name: 'setup',
  initialState: { running: false, complete: false } as SetupState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(runSetup.fulfilled, (state, { payload }) => {
      state.complete = payload;
    });
  },
});

// region Helper Functions

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
function validateStyleOptions(options: DataGatherer.Config.StyleOptions) {
  // ...
  if ('primaryColor' in options && !isString(options.primaryColor)) {
    throw new Error(
      'invalid configuration property. Property "styleOptions.primaryColor" must be a string.'
    );
  }

  // ...
  if ('secondaryColor' in options && !isString(options.secondaryColor)) {
    throw new Error(
      'invalid configuration property. Property "styleOptions.secondaryColor" must be a string.'
    );
  }

  // ...
  if ('primaryTextColor' in options && !isString(options.primaryTextColor)) {
    throw new Error(
      'invalid configuration property. Property "styleOptions.primaryTextColor" must be a string.'
    );
  }

  return options;
}

/**
 * ...
 *
 * @param forms ...
 * @param fields ...
 * @param formIds ...
 * @return ...
 */
function validateHiddenForms(
  forms: Evaluation['forms'],
  fields: Evaluation['fields'],
  formIds: string[]
) {
  for (const formId of formIds) {
    // ...
    const form = forms[formId];

    if (!form) {
      throw new Error(
        `invalid hidden form entry. Form with ID "${formId}" could not be found.`
      );
    }

    // ...
    const isHidable = form.fields.every((fieldId) => {
      const field = fields[fieldId];

      return field.type === 'heading' ? true : !field.required;
    });

    // ...
    if (isHidable) continue;

    throw new Error(
      `invalid hidden form entry. Form with ID "${formId}" contains at least one field that is required.`
    );
  }
}

/**
 * ...
 *
 * @param fields ...
 * @param fieldIds ...
 * @return ...
 */
function validateHiddenFields(
  fields: Evaluation['fields'],
  fieldIds: string[]
) {
  for (const fieldId of fieldIds) {
    // ...
    const field = fields[fieldId];

    if (!field) {
      throw new Error(
        `invalid hidden field entry. Field with ID "${fieldId}" could not be found.`
      );
    }

    if (field.type === 'heading') {
      throw new Error(
        `invalid hidden field entry. Field ID cannot correspond to a field of type "header".`
      );
    }

    if (field.required) {
      throw new Error(
        `invalid hidden field entry. Field ID cannot correspond to a field that is required.`
      );
    }
  }
}

/**
 * ...
 *
 * @param fields ...
 * @param formData ...
 * @return ...
 */
function processFormData(
  fields: Evaluation['fields'],
  formData: DataGatherer.Form.DataItem[]
) {
  // ...
  if (!Array.isArray(formData)) {
    logger.warn('invalid value for "formData".');

    return [];
  }

  // ...
  const validItems: evaluationSlice.InputValueItem[] = [];
  // ...
  const invalidItemIds: string[] = [];

  // ...
  for (const item of formData) {
    if (item.fieldId in fields) {
      validItems.push({ ...item, value: item.value ?? null });
    } else {
      invalidItemIds.push(item.fieldId);
    }
  }

  // ...
  if (invalidItemIds.length) {
    logger.warn(
      `invalid "formData" value(s). The following form data item IDs where invalid:\n\n${invalidItemIds
        .map((id) => `• ${id}`)
        .join('\n')}`
    );
  }

  return validItems;
}

// endregion Helper Functions
