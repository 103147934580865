import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store';

/** ... */
export type ActionName =
  | 'initializing'
  | 'submitting'
  | 'processing'
  | 'testingPrimary'
  | 'testingBackground';
/** ... */
export type ActionType = 'primary' | 'background';

/**
 * ...
 */
export interface ActionInfo {
  type: ActionType;
  displayText: string | null;
}

/**
 * ...
 */
export type ActivityState = Record<ActionName, boolean>;

/**
 * ...
 */
export interface SetActionStateOptions {
  action: keyof ActivityState;
  occuring: boolean;
}

/**
 * ...
 */
const ACTIONS: Record<ActionName, ActionInfo> = {
  initializing: { type: 'primary', displayText: 'Initializing' },
  submitting: { type: 'primary', displayText: 'Submitting' },
  processing: { type: 'primary', displayText: 'Processing' },
  testingPrimary: { type: 'primary', displayText: 'Testing' },
  testingBackground: { type: 'background', displayText: 'Testing' }
};

const initialState: ActivityState = {
  initializing: false,
  submitting: false,
  processing: false,
  testingPrimary: false,
  testingBackground: false
};

/**
 * ...
 */
export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    /** ... */
    setActionState: (
      state,
      { payload }: PayloadAction<SetActionStateOptions>
    ) => {
      state[payload.action] = payload.occuring;
    }
  }
});

// region Actions

/**
 * ...
 *
 * @param action ...
 * @param occuring ...
 */
export function setActionState(action: ActionName, occuring: boolean) {
  return activitySlice.actions.setActionState({ action, occuring });
}

// endregion Actions

// region Selectors

/** ... */
export const selectActivity = (state: RootState) => state.activity;

/**
 * ...
 */
export const selectOccuringActions = createSelector(
  selectActivity,
  (activity) => {
    const actions: Partial<typeof ACTIONS> = {};

    if (activity.testingPrimary) {
      actions.testingPrimary = ACTIONS.testingPrimary;
    }

    if (activity.testingBackground) {
      actions.testingBackground = ACTIONS.testingBackground;
    }

    if (activity.initializing) {
      actions.initializing = ACTIONS.initializing;
    }

    if (activity.processing) {
      actions.processing = ACTIONS.processing;
    }

    if (activity.submitting) {
      actions.submitting = ACTIONS.submitting;
    }

    return actions;
  }
);

// endregion Selectors
