import * as events from '@src/events';
import { ensureError } from '@tools/ensure-error';

/** ... */
interface ListenerDescriptor {
  type: 'on' | 'once';
  fn: (...args: unknown[]) => void;
}

/**
 * ...
 */
export class EventManager {
  #listeners = createListenersStore();

  /**
   * Register an event listener that will be called every time the event is
   * emmited.
   */
  on(
    event: 'activationCompleted',
    cb: events.ActivationCompletedListener
  ): void;
  on(event: 'activationError', cb: events.ActivationErrorListener): void;
  on(
    event: 'evaluationCompleted',
    cb: events.EvaluationCompletedListener
  ): void;
  on(
    event: 'evaluationDraftExported',
    cb: events.EvaluationDraftExportedListener
  ): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on(event: string, cb: (...args: any[]) => void) {
    // Create "on" listener descriptor.
    const descriptor: ListenerDescriptor = { type: 'on', fn: cb };

    if (event === 'activationCompleted') {
      this.#listeners.activationComplete.push(descriptor);
    } else if (event === 'activationError') {
      this.#listeners.activationError.push(descriptor);
    } else if (event === 'evaluationCompleted') {
      this.#listeners.evaluationCompleted.push(descriptor);
    } else if (event === 'evaluationDraftExported') {
      this.#listeners.evaluationDraftExported.push(descriptor);
    } else {
      throw new Error(`The event "${event}" is not valid.`);
    }
  }

  /**
   * Register an event listener that will be called the next time the event is
   * emitted and then removed.
   */
  once(
    event: 'activationCompleted',
    cb: events.ActivationCompletedListener
  ): void;
  once(event: 'activationError', cb: events.ActivationErrorListener): void;
  once(
    event: 'evaluationCompleted',
    cb: events.EvaluationCompletedListener
  ): void;
  once(
    event: 'evaluationDraftExported',
    cb: events.EvaluationDraftExportedListener
  ): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  once(event: string, cb: (...args: any[]) => void) {
    // Create "once" listener descriptor.
    const descriptor: ListenerDescriptor = { type: 'once', fn: cb };

    if (event === 'activationCompleted') {
      this.#listeners.activationComplete.push(descriptor);
    } else if (event === 'activationError') {
      this.#listeners.activationError.push(descriptor);
    } else if (event === 'evaluationCompleted') {
      this.#listeners.evaluationCompleted.push(descriptor);
    } else if (event === 'evaluationDraftExported') {
      this.#listeners.evaluationDraftExported.push(descriptor);
    } else {
      throw new Error(`The event "${event}" is not valid.`);
    }
  }

  /**
   * Remove all listeners currently registered.
   */
  removeAllListeners() {
    this.#listeners = createListenersStore();
  }

  /**
   * Process listeners belonging to a specified group -- envoke each callback
   * with supplied arguments and remove listeners that have expired.
   *
   * @param group Key corresponding to the targeted listeners in `#listeners`.
   */
  protected processEventListeners(
    group: keyof ListenerDescriptors,
    ...args: unknown[]
  ) {
    // Get reference to current set of listeners for the specified group.
    const listeners = this.#listeners[group];
    // Prepare new set for listeners not being removed to be added to.
    const filteredListeners: ListenerDescriptor[] = [];

    for (const listener of listeners) {
      let error: Error | null = null;

      // Wrap execution of each listener in a try/cacth so that an error does
      // not hault the process.
      try {
        listener.fn(...args);
      } catch (err) {
        error = ensureError(err);
      }

      if (error) {
        // eslint-disable-next-line no-console
        console.error(
          `a listener failed with the following error: ${error.message}`
        );
      }

      // If the listener is a "once" type, remove it.
      if (listener.type === 'once') continue;

      filteredListeners.push(listener);
    }

    // Replace current listeners with filtred ones.
    this.#listeners[group] = filteredListeners;
  }
}

// region Helper Functions

/**
 * ...
 *
 * @return ...
 */
function createListenersStore() {
  const activationComplete: ListenerDescriptor[] = [];
  const activationError: ListenerDescriptor[] = [];
  const evaluationCompleted: ListenerDescriptor[] = [];
  const evaluationDraftExported: ListenerDescriptor[] = [];

  return {
    activationComplete,
    activationError,
    evaluationCompleted,
    evaluationDraftExported,
  };
}

/** ... */
type ListenerDescriptors = ReturnType<typeof createListenersStore>;

// endregion Helper Functions
