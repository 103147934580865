import { uniqueId } from 'lodash';

/** ... */
const forms: DataGatherer.Form[] = [
  {
    id: 'a',
    name: 'Form A',
    type: 'evaluation',
    interpretAs: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
    sourceId: 'f52e5164-7ac9-4d69-8f2c-41b6e162891e',
    fields: [
      {
        type: 'field-group',
        heading: 'Test',
        fields: [
          {
            id: 'qYAvDM3u',
            referenceId: '1',
            label: 'Name of Assessed',
            sequenceNumber: 1,
            required: false,
            model: { type: '', payloadLocation: '' },
            type: 'text',
            payloadType: 'evaluate',
          },
          {
            id: 'AAygEecg',
            referenceId: '2',
            label: "Interviewer's Name",
            sequenceNumber: 2,
            required: false,
            model: { type: '', payloadLocation: '' },
            type: 'text',
            payloadType: 'evaluate',
          },
          {
            id: 'aVPjJn36',
            referenceId: '3',
            label: 'Date of Birth',
            sequenceNumber: 3,
            required: false,
            model: { type: '', payloadLocation: '' },
            type: 'date',
            payloadType: 'evaluate',
          },
          {
            id: 'aFAJYRHj',
            referenceId: '4',
            label: 'Gender',
            sequenceNumber: 4,
            required: false,
            model: { type: '', payloadLocation: '' },
            type: 'text',
            payloadType: 'evaluate',
          },
        ],
      },
    ],
  },
];

/** ... */
export const data: DataGatherer.Tool = {
  id: 'simple-tool',
  measureId: '00000000-0000-0000-0000-000000000000',
  evaluatorId: 'f493205d-f422-453f-81f9-6ba225334bd3',
  version: '0.0.1',
  name: 'Simple Tool',
  forms,
  references: [{ id: '1.1', title: 'My Reference Title', content: '...' }],
};
