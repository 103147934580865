import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { closeAlert, AlertInstance } from './alertsSlice';

import './AlertWrapper.scoped.scss';

/**
 * ...
 */
interface AlertWrapperProps extends AlertInstance {
  readonly closeAlert: typeof closeAlert;
}

/**
 * ...
 */
interface AlertWrapperState {
  timeoutId: number | null;
}

/**
 * ...
 */
class AlertWrapper extends React.Component<
  AlertWrapperProps,
  AlertWrapperState
> {
  constructor(props: AlertWrapperProps) {
    super(props);

    this.state = {
      timeoutId: null,
    };

    this.scheduleAutoRemoval = this.scheduleAutoRemoval.bind(this);
    this.cancelAutoRemoval = this.cancelAutoRemoval.bind(this);
    this.onCloseHandler = this.onCloseHandler.bind(this);
  }

  componentDidMount() {
    this.scheduleAutoRemoval();
  }

  /**
   * ...
   */
  private scheduleAutoRemoval() {
    if (typeof this.state.timeoutId === 'number') return;

    this.setState({ timeoutId: setTimeout(this.onCloseHandler, 10000) });
  }

  /**
   * ...
   */
  private cancelAutoRemoval() {
    if (typeof this.state.timeoutId !== 'number') return;

    clearTimeout(this.state.timeoutId);

    this.setState({ timeoutId: null });
  }

  /**
   * ...
   */
  private onCloseHandler() {
    this.props.closeAlert(this.props.id);
  }

  render() {
    const { id, type, text } = this.props;

    return (
      <Alert
        key={id}
        variant={type}
        onMouseOver={this.cancelAutoRemoval}
        onMouseLeave={this.scheduleAutoRemoval}
        onClose={this.onCloseHandler}
        dismissible
      >
        <p>{text}</p>
      </Alert>
    );
  }
}

/** ... */
const connectedComponent = connect(null, { closeAlert })(AlertWrapper);

export { connectedComponent as AlertWrapper };
