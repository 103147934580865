import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * ...
 */
interface DataGathererErrorInfo {
  title: string;
  message: string;
}

/**
 * ...
 */
export type ErrorState = DataGathererErrorInfo | null;

/**
 * ...
 */
export const errorSlice = createSlice({
  name: 'error',
  initialState: null as ErrorState,
  reducers: {
    /** ... */
    set: (_, { payload }: PayloadAction<DataGathererErrorInfo>) => {
      return payload;
    },
    /** ... */
    clear: () => {
      return null;
    }
  }
});

// region Actions

/**
 * ...
 *
 * @param title ...
 * @param message ...
 */
export function setError(title: string, message: string) {
  return errorSlice.actions.set({ title, message });
}

/**
 * ...
 */
export function clearError() {
  return errorSlice.actions.clear();
}

// endregion Actions
