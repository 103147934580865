import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useAppSelector } from '@hooks';

import { AlertWrapper } from './AlertWrapper';

import './AlertsRoot.scoped.scss';

/**
 * ...
 */
export const AlertsRoot: React.FC = () => {
  // ...
  const alerts = useAppSelector((state) => state.alerts);

  if (!alerts.length) return null;

  return (
    <div className="alerts-root">
      <TransitionGroup appear>
        {alerts.map((props) => (
          <CSSTransition key={props.id} timeout={150}>
            <AlertWrapper {...props} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
