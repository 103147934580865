import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { useAppSelector, useAppDispatch } from '@hooks';

import { closeModal, ModalInstance } from './modalsSlice';
import { ReferenceTextModal } from './ReferenceTextModal';
import { TestModal } from './TestModal';

import './ModalRoot.scoped.scss';

/**
 * ...
 */
const ModalDialog: React.FC<ModalInstance> = (modal) => {
  // ...
  const dispatch = useAppDispatch();

  return (
    <CSSTransition appear in={true} timeout={300}>
      <Modal.Dialog key={modal.id}>
        <Button
          className="close-modal-btn"
          onClick={() => dispatch(closeModal(modal.id))}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        {modal.type === 'ReferenceTextModal' ? (
          <ReferenceTextModal {...modal.props} />
        ) : (
          <TestModal />
        )}
      </Modal.Dialog>
    </CSSTransition>
  );
};

/**
 * ...
 */
export const ModalRoot: React.FC = () => {
  // ...
  const modal = useAppSelector((state) => state.modals[0]);

  return (
    <CSSTransition appear in={!!modal} timeout={300} unmountOnExit>
      <div className="modal-root">{modal && <ModalDialog {...modal} />}</div>
    </CSSTransition>
  );
};
