import { find, sortBy } from 'lodash';

import { Evaluation } from '@features/evaluation/evaluationTypes';
import { generateThumbprint } from '@utils/generate-thumbprint';

/**
 * `buildPayload` options.
 */
export interface BuildEvaluationRequestPayloadOptions {
  measureId: Evaluation.Submission['measureId'];
  evaluatorId: Evaluation.Submission['evaluatorId'];
  retentionPolicy: Evaluation.Submission['retentionPolicy'];
  directives: Evaluation.Submission['directives'];
  runAs?: Evaluation.Submission['runAs'];
  forms: Evaluation['forms'];
  fields: Evaluation['fields'];
  models: Evaluation['models'];
}

/**
 * Convert a object of key/value pairs to a `DataGatherer.EvaluationRequest.Directives`
 *
 * @param directives ...
 * @return ...
 */
function mapDirectiveEntries(directives: Record<string, unknown>) {
  return Object.entries(directives).map(([Key, Value]) => ({ Key, Value }));
}

/**
 * ...
 *
 * @param dataItems ...
 * @return ...
 */
function mapSourceData(
  forms: Evaluation['forms'],
  fields: Evaluation.Field[],
  models: Evaluation['models']
) {
  const Delimiter = '|';

  return Object.values(forms)
    .filter(({ type }) => type === 'evaluation')
    .map((form) => {
      const SourceID = form.sourceId;
      const InterpretAs = form.interpretAs;

      // ...
      const values = form.fields
        .filter((fieldId) => !!find(fields, { id: fieldId }))
        .map((fieldId) => models[fieldId]?.value ?? '');

      // ...
      const Thumbprint = generateThumbprint(form.interpretAs, values);

      // ...
      const Items = values.map((value) => value.toString()).join(Delimiter);

      // ...
      const ObservationValues = { Delimiter, Items };

      return {
        SourceID,
        InterpretAs,
        Thumbprint,
        ObservationValues,
      } as DataGatherer.EvaluationRequest.SourceDataItem;
    });
}

/**
 * ...
 */
interface FieldGroups {
  evaluationFields: Evaluation.Field[];
  [key: string]: Evaluation.Field[];
}

/**
 * ...
 *
 * @param fields ...
 * @return ...
 */
function sortFields(fields: Evaluation['fields']) {
  const fieldGroups: FieldGroups = { evaluationFields: [] };

  for (const field of Object.values(fields)) {
    // ...
    if (field.type === 'heading') continue;

    // ...
    const key = field.payloadType;

    // ...
    if (key === 'evaluate') {
      fieldGroups.evaluationFields.push(field);

      continue;
    }

    // ...
    fieldGroups[key] = fieldGroups[key] ?? [];
    fieldGroups[key].push(field);
  }

  return fieldGroups;
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export function buildOutput(options: BuildEvaluationRequestPayloadOptions) {
  // ...
  const { evaluationFields, ...additionalFieldGroups } = sortFields(
    options.fields
  );

  // ...
  const SourceData = mapSourceData(
    options.forms,
    evaluationFields,
    options.models
  );

  // ...
  const KeyValuePairsArray = mapDirectiveEntries(options.directives);

  // ...
  const RunAs = {
    Identifier: options.runAs?.identifier ?? '',
    Name: options.runAs?.name ?? '',
    JobCode: options.runAs?.jobCode ?? '',
    ComputeProvider: options.runAs?.computeProvider ?? '',
    StorageProvider: options.runAs?.storageProvider ?? '',
  };

  // ...
  const evaluatePayload: DataGatherer.EvaluationRequest = {
    MeasureID: options.measureId,
    EvaluatorID: options.evaluatorId,
    RetentionPolicy: options.retentionPolicy,
    RunAs,
    Directives: { KeyValuePairsArray },
    SourceData,
  };

  const output: DataGatherer.Output = { evaluatePayload };

  // Include additional paylaods if any are present.

  if (Object.keys(additionalFieldGroups).length) {
    const additionalPayloads: DataGatherer.Output.GenericPayload[] = [];

    for (const groupName in additionalFieldGroups) {
      const group = additionalFieldGroups[groupName];

      // ...
      const items = group.map(({ id, sequenceNumber }) => ({
        id,
        sequenceNumber,
        value: options.models[id]?.value ?? null,
      }));

      additionalPayloads.push({
        key: groupName,
        items: sortBy(items, ['sequenceNumber']),
      });
    }

    output.additionalPayloads = additionalPayloads;
  }

  return output;
}
