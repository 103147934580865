import blake2b from '@tools/blake2b';

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function stringToBytes(value: string) {
  return Uint8Array.from(Array.from(value), (x) => x.charCodeAt(0));
}

/**
 * ...
 *
 * @param key ...
 * @param values ...
 * @return ...
 */
export function generateThumbprint(
  key: string,
  values: Array<string | number | boolean | null>
) {
  // ...
  const hash = blake2b(16, stringToBytes(key));

  // ...
  for (const value of values) {
    hash.update(stringToBytes((value ?? '').toString()));
  }

  // ...
  return hash.digest('hex');
}
