import React, { useMemo } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfo,
  faCheck,
  faExclamation,
} from '@fortawesome/pro-solid-svg-icons';

import { openModal } from '@features/modals/modalsSlice';
import { useAppSelector, useAppDispatch } from '@hooks';

import { EvaluationInput } from './EvaluationInput';
import * as slice from './evaluationSlice';
import { Evaluation } from './evaluationTypes';

import './EvaluationQuestion.scoped.scss';

/**
 * ...
 *
 * @param fields ...
 * @param validations ...
 * @return ...
 */
function renderSubFields(
  fields: Record<string, Evaluation.Field>,
  formVals: Evaluation.Validations.Form
) {
  return Object.values(fields).map((field) => (
    <EvaluationQuestion
      key={field.id}
      field={field}
      formVals={formVals}
      fieldVals={formVals.fields[field.id]}
      isSubField
    />
  ));
}

/**
 * ...
 */
export const _EvaluationQuestion: React.FC<EvaluationQuestion.Props> = ({
  field,
  formVals,
  fieldVals,
  isSubField,
}) => {
  // ...
  const dispatch = useAppDispatch();
  // ...
  const selectModel = useMemo(() => slice.makeSelectModel(), []);
  const model = useAppSelector((state) => selectModel(state, field.id));
  // ...
  const selectReference = useMemo(() => slice.makeSelectReference(), []);
  const reference = useAppSelector((state) =>
    selectReference(state, field.referenceId)
  );

  // ...
  const value = model.value ?? (field.type === 'number' ? undefined : '');
  // ...
  const validity = !fieldVals.dirty
    ? 'nuetral'
    : fieldVals.invalid ||
      (!formVals.isWithinOmitThreshold && fieldVals.ommited)
    ? 'invalid'
    : model.value
    ? 'valid'
    : 'nuetral';

  // Set the value of the field to that input by the user.
  const updateAnswer = (value: string | number | null) => {
    dispatch(slice.updateField(field.id, value));
  };

  // ...
  const statusMarker =
    validity === 'valid' ? (
      <span className="dg-field-validity-indicator dg-field-valid">
        <FontAwesomeIcon icon={faCheck} />
      </span>
    ) : validity === 'invalid' ? (
      <span className="dg-field-validity-indicator dg-field-invalid">
        <FontAwesomeIcon icon={faExclamation} />
      </span>
    ) : null;

  // ...
  const input =
    field.type === 'dropdown' ? (
      <EvaluationInput.Check
        value={value}
        answers={field.answers}
        onUpdated={updateAnswer}
      />
    ) : field.type === 'date' ? (
      <EvaluationInput.Date value={value} onUpdated={updateAnswer} />
    ) : (
      <EvaluationInput.Text
        value={value}
        isValid={validity === 'valid'}
        isInvalid={validity === 'invalid'}
        onUpdated={updateAnswer}
      />
    );

  // ...
  const subFields = !field.subFields
    ? null
    : renderSubFields(field.subFields, formVals);

  // ...
  const referenceButton = !reference ? null : (
    <ShowReferenceButton reference={reference} />
  );

  // ...
  let question = (
    <>
      <div className="dg-field">
        <div className="dg-field-content">
          <Form.Label className="w-100 m-0">
            <div className="dg-field-label-inner">
              {/* Validity Status Marker */}
              {statusMarker}
              {/* Sequence Number */}
              {!field.hideSequenceNumber && (
                <span className="dg-field-number">
                  {field.displayNumberOverride
                    ? field.displayNumberOverride
                    : field.sequenceNumber}
                  .
                </span>
              )}
              {/* Label Text */}
              <span className="dg-field-label-text">{field.label}</span>
              {/* Required Indicator */}
              {field.required && (
                <span className="dg-field-required-indicator">*</span>
              )}
            </div>
          </Form.Label>
          {/* ... */}
          <InputGroup>{input}</InputGroup>
        </div>
        <div className="dg-field-gutter-right">{referenceButton}</div>
      </div>
      {/* ... */}
      {subFields && (
        <div className="dg-field-sub-fields-group">{subFields}</div>
      )}
    </>
  );

  // ...
  if (!isSubField) {
    question = <FieldGroup index={field.sequenceNumber}>{question}</FieldGroup>;
  }

  return question;
};

/**
 * ...
 */
export const EvaluationQuestion = React.memo(_EvaluationQuestion);

namespace EvaluationQuestion {
  /** ... */
  export interface Props {
    field: Evaluation.Field;
    formVals: Evaluation.Validations.Form;
    fieldVals: Evaluation.Validations.Field;
    isSubField?: boolean;
  }
}

/**
 * ...
 */
const FieldGroup: React.FC<FieldGroup.Props> = ({ children }) => {
  return <div className="dg-field-group">{children}</div>;
};

namespace FieldGroup {
  /** ... */
  export interface Props {
    index: number;
  }
}

/**
 * ...
 */
const ShowReferenceButton: React.FC<ShowReferenceButton.Props> = ({
  reference,
}) => {
  // ...
  const dispatch = useAppDispatch();

  // ...
  const openReferenceModal = () => {
    dispatch(
      openModal({
        type: 'ReferenceTextModal',
        props: { text: reference.content },
      })
    );
  };

  return (
    <Button
      className="dg-field-show-reference-btn"
      variant="primary"
      size="sm"
      onClick={openReferenceModal}
    >
      <FontAwesomeIcon icon={faInfo} />
    </Button>
  );
};

namespace ShowReferenceButton {
  /** ... */
  export interface Props {
    reference: Evaluation.Reference;
  }
}
