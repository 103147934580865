declare global {
  interface Window {
    /** ... */
    WebKitMutationObserver?: typeof MutationObserver;
  }
}

/** ... */
const _MutationObserver =
  window.MutationObserver ?? window.WebKitMutationObserver;

/**
 * ...
 *
 * @param el ...
 * @param callback ...
 * @return ...
 */
export function observeElement(el: HTMLElement, callback: MutationCallback) {
  // ...
  if (el.nodeType !== 1) return null;

  // ...
  if (_MutationObserver) {
    // define a new observer
    const mutationObserver = new _MutationObserver(callback);

    // have the observer observe foo for changes in children
    mutationObserver.observe(el, { childList: true, subtree: true });

    return mutationObserver;
  }

  // browser support fallback
  el.addEventListener('DOMNodeInserted', callback, false);
  el.addEventListener('DOMNodeRemoved', callback, false);

  return null;
}
