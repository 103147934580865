import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

/** ... */
export type AlertType = 'info' | 'success' | 'danger';

/**
 * ...
 */
export interface AlertInstance {
  id: string;
  type: AlertType;
  text: string;
}

/**
 * ...
 */
export type AlertsState = AlertInstance[];

/**
 * ...
 */
interface CreateAlertOptions {
  type: AlertType;
  text: string;
}

/**
 * ...
 */
interface CloseAlertOptions {
  alertId: string;
}

/**
 * ...
 */
export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: [] as AlertsState,
  reducers: {
    /** ... */
    createAlert: (state, { payload }: PayloadAction<CreateAlertOptions>) => {
      return [...state, { id: uniqueId(), ...payload }];
    },
    /** ... */
    closeAlert: (state, { payload }: PayloadAction<CloseAlertOptions>) => {
      return state.filter(({ id }) => id !== payload.alertId);
    },
    /** ... */
    clearAlerts: () => {
      return [];
    }
  }
});

// region Actions

/**
 * Create an alert.
 *
 * @param options Alert options.
 */
export function createAlert(options: CreateAlertOptions) {
  return alertsSlice.actions.createAlert(options);
}

/**
 * Close an alert.
 *
 * @param alertId ID of the alert to close.
 */
export function closeAlert(alertId: string) {
  return alertsSlice.actions.closeAlert({ alertId });
}

/**
 * Clear all alerts.
 */
export function clearAlerts() {
  return alertsSlice.actions.clearAlerts();
}

// endregion Actions
