import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

import { RootState } from '@store';

import { ReferenceTextModalProps } from './ReferenceTextModal';

/** ... */
export type ModalType = 'ReferenceTextModal' | 'TestModal';

/** ... */
export type ModalInstance = { id: string } & (
  | ReferenceTextModalOptions
  | TestModalOptions
);

/**
 * ...
 */
export type ModalsState = ModalInstance[];

/**
 * ...
 */
interface ReferenceTextModalOptions {
  type: 'ReferenceTextModal';
  props: ReferenceTextModalProps;
}

/**
 * ...
 */
interface TestModalOptions {
  type: 'TestModal';
}

/**
 * ...
 */
type OpenModalPayload = ReferenceTextModalOptions | TestModalOptions;

/**
 * ...
 */
interface CloseModalPayload {
  modalId: string;
}

/**
 * ...
 */
export const modalsSlice = createSlice({
  name: 'modals',
  initialState: [] as ModalsState,
  reducers: {
    /** ... */
    openModal: (state, { payload }: PayloadAction<OpenModalPayload>) => {
      // ...
      const modalId = uniqueId();

      if (
        payload.type === 'ReferenceTextModal' ||
        payload.type === 'TestModal'
      ) {
        return [...state, { id: modalId, ...payload }];
      }

      return state;
    },
    /** ... */
    closeModal: (state, { payload }: PayloadAction<CloseModalPayload>) => {
      return state.filter(({ id }) => id !== payload.modalId);
    },
    /** ... */
    closeAllModals: () => {
      return [];
    }
  }
});

// region Actions

/**
 * Open a modal.
 *
 * @param options Modal options.
 */
export function openModal(options: OpenModalPayload) {
  return modalsSlice.actions.openModal(options);
}

/**
 * Close/Cancel a specified modal.
 *
 * @param modalId ID of the modal you want to close/cancel.
 */
export function closeModal(modalId: string) {
  return modalsSlice.actions.closeModal({ modalId });
}

/**
 * Close the current modal and cancel any waiting to be displayed.
 */
export function closeAllModals() {
  return modalsSlice.actions.closeAllModals();
}

// endregion Actions

// region Selectors

/** ... */
const selectModals = (state: RootState) => [...state.modals];

/**
 * ...
 *
 * @param ...
 * @return ...
 */
export const selectCurrentModal = createSelector(
  [selectModals],
  (modals: ModalInstance[]) => modals[0]
);

/**
 * ...
 *
 * @param ...
 * @return ...
 */
export const selectModalById = createSelector(
  [selectModals, (_: ModalsState, modalId: string) => modalId],
  (modals: ModalInstance[], modalId: string) =>
    modals.find((modal) => modal.id === modalId)
);

// endregion Selectors
