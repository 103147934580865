import React from 'react';

import './Title.scoped.scss';

/**
 * ...
 */
interface Props {
  toolName?: string | null;
}

/**
 * ...
 */
export const Title: React.FC<Props> = ({ toolName }) => {
  // ...
  const text = toolName ?? '•••';

  return (
    <h2 className="dg-header-title h5 m-0">
      <span>Data Gatherer</span>
      <span></span>
      <span>{text}</span>
    </h2>
  );
};
